<!-- <div id="video"> -->
  <!-- <div class="video-container"> -->
    
  <!-- <iframe width="560" *ngIf="page_ready" height="315" class="video"
   src="https://www.youtube.com/embed/8SaH9tmIf_E" title="YouTube video player" 
   frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
   allowfullscreen></iframe> -->
  <!-- </div> -->
        <!-- <video poster="assets/img/banner/banner_home2.jpg" preload="none" width="100%"  controls autoplay>
          <source id="vid"  type="video/mp4">
           Your browser does not support the video tag.
        </video> -->
          <!--		<div class="playpause"></div>-->
        <!-- </div> -->
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <img [src]="banners[0]"/>
          </ng-template>  
          <ng-template carouselSlide> 
            <img [src]="banners[1]"/>
          </ng-template>  
          <ng-template carouselSlide>
            <img [src]="banners[2]"/>
          </ng-template>  
          <ng-template carouselSlide>
            <img [src]="banners[3]"/>
          </ng-template>  
        </owl-carousel-o>
      
        <section id="bg_design" class="container-fluid slide2 pt-5 pb-5">
          <div class="bg_number">
          <div class="container">
            <div class="row">
              <div class="col-md-12 p-0">
                <h1 class="text-center blue"><span class="dark_grey">{{"Our"|translate}}</span> {{"Legacy"|translate}}</h1>
                <div class="divder"> <img loading="lazy" src="assets/img/icon/line.svg"> </div>
                <div class="col-md-10 col-12 m-auto">
                  <!-- watch slide start-->
                   <div class="text-center blue">
                    <div>
                      {{"legacy1"|translate}}
                <br>        
                {{"legacy_content"|translate}}
                <br> <br>
                </div>
                 <a href="https://vishwarang.com/about_us" class=" btn_sm_1">{{"READ_MORE"|translate}} </a> 
              </div>
                </div>
              </div>
            </div>
          </div>
            </div>
        </section>
    <section id="bg_design" class="container-fluid slide1 comman_slide">
        <div class="container">
                <div class="row">
                        <div class="col-md-12">
                                <h1 class="text-center blue"><span class="dark_grey">{{"FEATURED"|translate}}</span> {{"ARTISTS_SPEAKERS"|translate}}</h1>
                                <div class="divder"> <img loading="lazy" src="assets/img/icon/line.svg"> </div>
                                <owl-carousel class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                            </owl-carousel>  
                                <div class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                                        <!-- item group1 start -->
                                        <div class="container" *ngFor="let one of featured_artists">
                                        <div class="row justify-content-center">
                                                <!-- item start -->
                                                <div *ngFor="let artist of one" class="bg-white lg_br padding_1 pb-4 box_shadow margin_1 featured_item">
                                                    <figure><img loading="lazy" [src]="getArtistImg(artist)" class="img_br"></figure>
                                                    <h5> {{artist.name}}</h5>
                                                    <p>{{(this.helper.getStringLength(artist.short_description)>80)? (artist.short_description | slice:0:80)+'..':(artist.short_description)}} </p>
                                                    <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1">{{"Explore"|translate}} </a>
                                                    </div>
                                                <!-- item end -->
                                        </div></div>
                                        <!-- item group2 end -->
                                </div>
                        </div>
                        <div class="col-md-12 text-center mt-4"> <a  [routerLink]="['/artists']" class="btn_lg_2">{{"VIEW_ALL"|translate}} </a> </div>
                </div>
        </div>
    </section>
<!-- slide1 End -->
<!-- slide2 start -->
    <section id="bg_design_2" class="container-fluid slide2 white_slide">
        <div class="container">
                <div class="row"> 
                        <div class="col-md-12">
                                <h1 class="text-center white">{{"FEATURED_CONTENT"|translate}}</h1>
                                <div class="divder"> <img loading="lazy" src="assets/img/icon/line_fc.svg"> </div>
                                <ul class="nav nav-tabs mb-4" id="comman_tab" role="tablist">
                                        <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{'WATCH'|translate}}</a> </li>
                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" href="#listen" role="tab" aria-controls="listen" aria-selected="false">{{'LISTEN'|translate}}</a> </li>
                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#read" role="tab" aria-controls="read" aria-selected="false">{{'READ'|translate}}</a> </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                        <!-- watch slide start-->
                                        <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                            <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery', 'slidng']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_watch">
                                                    <div class="container" *ngFor="let one of featured_watch"> 
                                                            <div class="row justify-content-center">
                                                                    <!-- item start -->
                                                                    <div *ngFor="let item of one"  id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                            <figure><img loading="lazy" [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" [src]="watchService.getImg(item)" class="img_br">
                                                                                    <div class="play"> <img loading="lazy" src="assets/img/icon/play.svg"> </div>
                                                                            </figure>
                                                                            <h5 [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center">{{(this.helper.getStringLength(item.title)>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                            <p [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                            <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                    <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'PLAY'| translate}}</a>
                                                                                <div class="rating social" *ngIf="isLoggedIn">
                                                                                                <label (click)="switchWatchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                </div>
                                                                </div>

                                                                    </div>
                                                                    <!-- item end -->
                                                            </div>
                                                    </div>
                                                </owl-carousel>
                                                <div class="col-md-12 text-center mt-4"> <a [routerLink]="['watch']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                        </div>
                                        <!-- watch slide end -->
                                        <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">
                                                                                                      
                                            <owl-carousel #owlListen [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_listen">
                                                    <div class="container" *ngFor="let one of featured_listen">
                                                                    <div class="row justify-content-center">
                                                                                    <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                      <figure  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" ><img loading="lazy" [src]="listenService.getImg(item)" class="img_br">
                                                                                                      <div class="play"> <img loading="lazy" src="assets/img/icon/listen2.svg"> </div>
                                                                                      </figure>
                                                                                      <h5  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>  
                                                                                      <p  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{item.information|slice: 0: 80}}</p>
                                                                                      <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                      <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{'LISTEN'| translate}}</a>
                                                                                                      <div class="rating social" *ngIf="isLoggedIn">
                                                                                                        <label (click)="switchListenFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                        </div>
                                                                                      </div>
                                                                      </div>
                                                      <!-- item end -->
                                                                    </div>
                                                    </div>
                                                    </owl-carousel>

                                                    <div class="col-md-12 text-center mt-4"> <a [routerLink]="['listen']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                        </div>
                                        <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">

                                            <owl-carousel #owlRead [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_read">
                                                    <div class="container" *ngFor="let one of featured_read">
                                                                    <div class="row justify-content-center">
                                                                                    <!-- item start -->
                                                                                    <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                    <figure [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }"> <img loading="lazy" [src]="readService.getImg(item)" class="img_br"> </figure>
                                                                                                    <h5 [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                    <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                            <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'READ'| translate}}</a>
                                                                                                            <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                    <label (click)="switchReadFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                    </div>
                                                                                                    </div>
                                                                                    </div>
                                                                                    <!-- item end -->
                                                                    </div>
                                                    </div>
                                            </owl-carousel>
                                            <div class="col-md-12 text-center mt-4"> <a [routerLink]="['read']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
    </section>
<!-- slide2 End -->
<!-- The path to art begins here. Start -->
<!-- <section id="artist_bg">
    <div class="container-fluid design_corner">
            <div class="row flex-nowrap">
                    <div class="col-md-6 p-0 "> <img loading="lazy" src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
                    <div class="col-md-6 p-0 "> <img loading="lazy" src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
            </div>
    </div>
    <div class="container">
            <div class="row text_text flex-nowrap">
                    <div class="col-md-5 col-3 text-center">
                    </div>
                    <div class="col-md-7 col-9 text-center">
                            <h1 class="white">The path to art begins here.</h1>
                            <h4 class="white">Share the details of your favourite new artist below </h4>
                            <a href="#" class="btn_lg_5"> UPLOAD</a>
                    </div>
            </div>
    </div>
    <div class="container-fluid design_corner">
            <div class="row flex-nowrap">
                    <div class="col-md-6 p-0 "> <img loading="lazy" src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
                    <div class="col-md-6 p-0 "> <img loading="lazy" src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
            </div>
    </div>
</section> -->
<!-- The path to art begins here. End -->
<!-- slide3 start -->
<section id="bg_design" class="container-fluid slide3">
    <div class="container">
            <div class="row">
                    <div class="col-md-12">
                            <h1 class="text-center blue">{{"CATEGORIES"|translate}}</h1>
                            <div class="divder"> <img loading="lazy" src="assets/img/icon/line_categories.svg"> </div>
                    </div>
            </div>
            <div class="row text-center pb-2">
                    <!-- item start -->
                    <div class="col-md-2"  *ngFor="let one of categories">
                            <div class="white_box sm_br box_shadow">
                                    <figure><img loading="lazy" [src]="getCatImg(one)" class="sm_br"></figure>
                                    <h5>{{one.name}}</h5>
                                    <p>{{one.description}}</p>
                                    <a  [routerLink]="['/category']" [queryParams]="{ q: one.id ,name: one.name}" class=" btn_sm_2">Explore</a>
                                </div>
                    </div>
                    <!-- item end -->
            </div>
    </div>
</section>
<!-- slide3 End -->

<!-- slide5 start -->
<section id="bg_design_3" class="container-fluid slide5 white_slide">
        <div class="container" id="playlist">
          <div class="row">
            <div class="col-md-12 p-0">
              <h1 class="text-center white">{{"RecommendedPlaylist"|translate}}</h1>
              <div class="divder"> <img loading="lazy" src="assets/img/icon/line_fc.svg"> </div>
              <div class="tab-content" id="myTabContent">
                <!-- watch slide start-->
                <div class="tab-pane fade show active" id="watch_1" role="tabpanel" aria-labelledby="watch-tab">
                  <div class="playlist owl-carousel owl-theme owl-loaded owl-drag text-center">
                    <!-- group2 start -->
                    <div class="container">
                      <div class="row justify-content-center">
                        <!-- item start -->
                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item" *ngFor="let item of playlist">
                          <figure> <img loading="lazy" [src]="playlist_img_url+'/'+item.thumbnail" class="img_br">
                            <div class="text_0">
                             
                              <h4>{{item.title}} </h4>
                              <p>{{"Playlist"|translate}}</p>
                            </div>
                            <div class="play_0 show_hand" [routerLink]="['/playlist']" [queryParams]="{ qid: item.id }" > <img loading="lazy" src="assets/img/icon/play_0.png"> </div>
                          </figure>
                        </div>
                        <!-- item end -->
                      </div>
                    </div>
                    <!-- group2 end -->
                  </div>
                </div>
                <!-- watch slide end -->
              
              </div>
            </div>
          </div>
        </div>
</section>
      <!-- slide5 End -->

      <!-- slide8 start -->
      <app-fan-messages></app-fan-messages>
      <!-- slide8 End -->

      <!-- Attend start -->
<section id="bg_design" *ngIf="helper.getObjectLength(events)" class="container-fluid slide7 white_slide event_slide">
  <div class="container">
          <div class="row">
                  <div class="col-md-12">
                          <h1 class="text-center blue">{{"Event"|translate}}</h1>
                          <div class="divder">   <img loading="lazy" src="assets/img/icon/line_relive.svg"></div>
                  </div>
                  <div class="col-md-12">
                                   <!-- 1 item start -->
                                  <!-- item start --> 
                                  <div class="events_0 max_width">
                                          <div class="white_box_2" *ngFor="let item of events">
                                                  <div class="white_img"><img loading="lazy" [src]="return_thumb(item)" class="lg_br"></div>
                                                  <div class="content_1">
                                                          <h6>{{item.name}}</h6> 
                                                          <h5>{{item.description| slice:0:106}}</h5>
                                                          <div class="row flex-nowrap lt_0">
                                                                  <div class="col-md-6  col-8 pr-1 d-flex align-items-start"> <img loading="lazy" src="assets/img/icon/loction.jpg">
                                                                          <p>{{item.location}}</p>
                                                                  </div>
                                                                  <div class="col-md-6 pr-0 col-4 d-flex align-items-start"> <img loading="lazy" src="assets/img/icon/calendar2.png">
                                                                          <h4>{{item.date_line}} <br> {{item.time_line}}</h4>
                                                                  </div>
                                                          </div>

                                                          <div class="row flex-nowrap pl-3 lt_0">
             <div>
              <a href="#" class="btn_event mt-2 ml-2 font-weight-light" (click)="popUp(item)"  data-toggle="modal" data-target="#exampleModal">{{"KnowMore"|translate}} </a>
             
             

                                                          <ul>
                                                              <li class="dropdown" style="list-style: none;">
                                                                    <button class="dropdown  btn_event mt-2 ml-2 font-weight-light " data-toggle="dropdown">{{'AddtoCalendar'|translate}}<b class="caret"></b></button>
                                                                    <ul class="dropdown-menu add_c">
                                                                      <li><a [href]="google_calender" target="_blank">Google</a></li>
                                                                      <li class="divider"></li>
                                                                      <li><a [href]="i_calender" target="_blank">iCalendar</a></li>
                                                                      <li class="divider"></li>
                                                                      <li><a [href]="yahoo_calender" target="_blank">Yahoo</a></li>
                                                                    
                                                                    </ul>
                                                              </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                  </div>
                                          </div>
                                          <!-- item end -->
                                       
                                  </div>
                                  <!-- item end -->
                                      </div>
                                      <div class="col-md-12 text-center mt-4">
                                        <a class="btn_lg_3 blue_btn" routerLink="/attend">View More </a> 
                                      </div> 
          </div>
  </div>

</section>

<div class="modal fade attend_0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title blue" id="exampleModalLabel"> 
                    <img loading="lazy" [src]="return_cover()">
              </h5>
          </div>
          <div class="modal-body text-center pt-4 mb-3">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            <h1 class="blue">{{popup_item.name}}</h1>
            <div class="divder mb-3"> <img loading="lazy" src="assets/img/icon/line_relive.svg"></div>
            <div class="col-md-8 m-auto blue "> {{popup_item.description}} </div>
          </div>
          <div class="col-md-7 col-10 mt-4 m-auto blue border_blue sm_br padding_2">
            <div class="col-md-12 d-flex mb-3 p-0">
              <div class="col-md-2 col-1 p-0"> <img loading="lazy" src="assets/img/attend/genre.png"></div>
              <div class="col-md-10">{{popup_item.category.name}} </div>
            </div>
            <div class="col-md-12 d-flex mb-3 p-0">
              <div class="col-md-2 col-1 p-0"> <img loading="lazy" src="assets/img/attend/calendar.png"></div>
              <div class="col-md-10">{{popup_item.date_line}} | {{popup_item.time_line}} </div>
            </div>
            <div class="col-md-12 d-flex  p-0">
              <div class="col-md-2 col-1 p-0"> <img loading="lazy" src="assets/img/attend/map.png"></div>
              <div class="col-md-10">{{popup_item.location}} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--attend  End -->


<!-- slide4 start -->
<section id="bg_design_3" class="container-fluid slide4">
    <div class="container">
            <div class="row">
                    <div class="col-md-12 text-center">
                            <h1 class="text-center white">{{"PARTICIPATING_CONTRIES"|translate}}</h1>
                            <div class="divder"> <img loading="lazy" src="assets/img/icon/line_countries.svg"> </div>
                            <p class="white"> {{"PARTICIPATING_CONTRIES_BELOW_LINE"|translate}} </p>
                    </div>
            </div>
            <div class="row justify-content-center">
                    <div class="col-md-auto box1" *ngFor="let c of country"> 
                      <img loading="lazy"  *ngIf="c.director != null" [routerLink]="['/team/']" [queryParams]="{ q: c.name,k: c.id }" class="show_hand" [src]="getFlag(c.flag)">
                      <p  *ngIf="c.director != null" [routerLink]="['/team/']" [queryParams]="{ q: c.name,k: c.id }"  class="show_hand "> {{c.name}} </p>
          
                      <img loading="lazy" *ngIf="c.director == null" class="show_hand" [src]="getFlag(c.flag)">
                      <p *ngIf="c.director == null" class="show_hand"> {{c.name}} </p>
                    </div>
            </div>
    </div>
</section>

<section id="ntpe" class="tagore_painting_exhb">
  <div class="updatebg"></div>
  <div class="page_heading">
      <div class="container">
          <div class="row align-items-center">
              <div class="col-auto ml-auto left_bg"></div>
              <div class="col-auto"><h2 > <a href="paintings" target="_blank" class="text-white">National Tagore Painting Exhibition</a></h2></div>
              <div class="col-auto mr-auto right_bg"></div>
          </div>
      </div>
  </div>
<div class="container mt-4">
      <div class="row">
          <div class="col-md-10 text-center desc mx-auto">
              <div class="col-md-10 mx-auto text-center">
                 <p class="desc text-white font_b6 font-600 "><a href="/assets/tagore-national-art-exhibition-2023.pdf" target="_blank" class="text-white" style="text-decoration:underline;"><span>Name of selected artists in the Tagore National Exhibition of Paintings 2023</span></a></p>
       
                  <p class="text-white font_b3  pv-4">
                     [For any further clarification one may contact Sri. Ashok Bhawmick (bhowmick.ashok@gmail.com)]
          &nbsp; 
                  </p>
                 
              </div>
          </div>
      </div>
  </div>
</section>


<section id="bg_design" class="container-fluid bg-white partners">
        <div class="container">
          <!-- Partners start -->
          <div class="row">
            <div class="col-md-12">
              <h1 class="text-center blue">{{"Partners"|translate}}</h1>
              <div class="divder"> <img loading="lazy" src="assets/img/icon/line_relive.svg"> </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10 d-flex">
              <div class="col-md-6" id="partner_1"> <img loading="lazy" src="assets/img/logos/raman4.jpg" class="border p-2"> </div>
              <div class="col-md-6" id="partner_2"> <img loading="lazy" src="assets/img/logos/raman5.jpg" class="border p-2"> </div>
            </div>
            
            <div class="col-md-6 d-flex  justify-content-center">
              <div class="col-md-12 text-center"  id="partner_5"> <img loading="lazy" src="assets/img/logos/raman.jpg" class="border p-2"> </div>
            </div>
            <div class="col-md-12 mb-5 text-center mt-4"> <a href="#" class="btn_lg_2">{{"VIEW_ALL"|translate}} </a> </div>
          </div>
          <!-- Partners end -->
          <!-- Sponsors start -->
          <div class="row mt-5">
            <div class="col-md-12">
              <h1 class="text-center blue">{{"Sponsors"|translate}}</h1>
              <div class="divder"> <img loading="lazy" src="assets/img/icon/line_categories.svg"> </div>
            </div>
          </div>
          <div class="row justify-content-center" id="logo1">
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s1.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s2.png"></div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s3.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s4.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s5.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s6.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s7.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/s8.png"> </div>
            </div>
           <div class="col-md-12 mb-5 text-center mt-4"> 
             <a  href="/about_us#Sponsors" class="btn_lg_2">{{"VIEW_ALL"|translate}} </a>
             </div>
          </div>
          <!-- Sponsors end -->
          <!-- Associated start --> 
          <div class="row mt-5">
            <div class="col-md-12">
              <h1 class="text-center blue">{{'Associated_Organizations'|translate}}</h1> 
              <div class="divder"> <img loading="lazy" src="assets/img/icon/line_categories.svg"> </div>
            </div>
          </div>
          <div class="row justify-content-center" id="logo2">
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/1.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/2.png"></div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/3.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/4.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/5.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/6.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/7.png"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="img_box"> <img loading="lazy" src="assets/img/logos/8.png"> </div>
            </div>
            <!-- [routerLink] = "['/about_us']" fragment="Associa" -->
            <div class="col-md-12 mb-5 text-center mt-4"> <a 
              href="/about_us#Associa"
               class="btn_lg_2">{{"VIEW_ALL"|translate}} </a> </div>
          </div>
          <!-- Associated end -->
        </div>
      </section>
      <!-- slide10 end -->