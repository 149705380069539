import { Component, Directive, ElementRef } from '@angular/core';
import { Gtag } from 'angular-gtag';
import { SigninComponent } from './components/signin/signin.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Vishwarang';
  constructor(private login:SigninComponent,gtag: Gtag) { 

  }

  ngOnInit(): void {
  
   }

   onActivate(event:any) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    // ...
}



  onOpen(event:any){
    alert(2);
  }
}
