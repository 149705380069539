import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-painting-category',
  templateUrl: './painting-category.component.html',
  styleUrls: ['./painting-category.component.css']
})
export class PaintingCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
