import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { TokenService } from 'src/app/shared/token.service';
import { InitAppService } from '../../services/init-app.service';
import { ApiService } from '../../shared/api.service';

@Component({
  selector: 'app-listen-info',
  templateUrl: './listen-info.component.html',
  styleUrls: ['./listen-info.component.css']
})
export class ListenInfoComponent implements OnInit {
  @ViewChild('audioOption') audioPlayerRef: ElementRef | undefined;

  listen:string="";
  listen_data:any = [];
  
  related_listen:any = [];
  comment:string = "";
  user_id = localStorage.getItem('user_id');
  comments:any = [];
  logged_in = false;
  comment_posted = false;
  audioList:any=[];
  img_url:string;
  file:string;
  current_url:string = "";

  constructor(private router:Router, public helper:HelperService, private tokenService:TokenService,private activatedRoute:ActivatedRoute,private sanitizer:DomSanitizer,private apiService:ApiService,private initApp:InitAppService) { 
  //  this.listen_data.content_link = 'http://127.0.0.1:8000/storage/music/611df2e558ee2.mp3';
    this.activatedRoute.queryParams.subscribe(params => {
  
      this.listen = params['q'];
      }); 
      
      this.current_url = router.url;
      this.file = this.apiService.storage+'/music/';
      this.img_url = this.apiService.storage+'/project/images/listen/';
    }

    ngOnInit(): void {
      if(this.tokenService.isLoggedIn()) {
        this.logged_in = true;
       }
       

       var t = this;
       setTimeout(function(){
         t.apiService.postData('/listen/push/trending/'+t.listen,[]).subscribe(
           result => {
           },
           error => {
           },
           () => {
           }
         )
         
       },8000);
       this.initApp.addThis();
    }
  
    getImg(){
      try{
        return this.img_url + this.listen_data.thumb.project_id+'/'+this.listen_data.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }

    getThumbImg(listen:any){
      try{
        return this.img_url + listen.thumb.project_id+'/'+listen.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }
  
    
    
  getlisten(){
    this.apiService.getData('/listen?artist='+this.listen_data.created_by+'&not_in='+this.listen_data.id).subscribe(
      result => {
        
        
        this.related_listen = this.apiService.chunk(result.data.data,4);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

    linkURL(){
      this.listen_data.content_link =  this.sanitizer.bypassSecurityTrustResourceUrl(this.file+this.listen_data.content_link);
    }
 
    ngAfterViewInit() {
      this.loadInfo();
      this.initApp.initApp();
    }


    postComment(){
      this.comment_posted = true;
      let data = {'key':this.listen,'comment':this.comment};
      this.apiService.postData('/comment/listen',data).subscribe((res:any)=>{
        this.comment = '';
        this.comment_posted = true;
        this.loadInfo();
      },
      (error:any)=>{
      });
      
  }
  
  deleteComment(id:string){
  this.apiService.postData('/comment_delete',{id:id}).subscribe((res:any)=>{
    this.loadInfo();
  },
  (error:any)=>{
  });
  
  }

  loadInfo(){
    this.apiService.getData('/listen/info/'+this.listen).subscribe(
      result => { 
        if(result.data === null){
          this.apiService.navigate('/listen');            
        }
    else{
    this.listen_data = result.data; 
    console.log(result.data);
    this.getlisten();
    this.listen_data.content_link =  this.sanitizer.bypassSecurityTrustResourceUrl(this.file+this.listen_data.content_link);
    this.audioPlayerRef?.nativeElement.load();
  }
      },
      error => {
      },
      () => {
      }
    )
  }

}
