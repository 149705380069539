<nav aria-label="breadcrumb" id="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; {{"WATCH"|translate}}</li>
</nav>
<!--Banner -->
<section>
<div id="" class="">
<div class="-inner">
<div class="-item ">
<img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner13.jpg">    </div>
<!-- <div class="carousel-item">
<img src="assets/img/banner/banner_listen.jpg">    </div>
<div class="carousel-item">
<img src="assets/img/banner/banner_listen.jpg">
</div> -->
</div>

</div>
</section>
<!--Banner End-->
<!-- Listen1 start -->
<section id="bg_design_2" class="container-fluid slide2 white_slide">
    <div class="container">
            <div class="row">
                    <div class="col-md-12">
                            <h1 class="text-center white">{{'Videogallery'|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                            <ul class="nav nav-tabs flex-nowrap mb-4" id="comman_tab" role="tablist">
                            <li class="col-md-3 col-4 p-0"><form class="col-md-12 p-0">
                                    <div class="form-row">
                                        <div class="form-group mb-0 col-md-12 cos_select">
                                            <select id="basic" (change)="categoryChanged($event)" class="form-control" placeholder="Category">
                                                <option selected="" value="" class="text-center">{{"Category"|translate}}</option>
                                                <option  *ngFor="let link of categories" [value]="link.id">{{link.name}}</option>
                                            </select>
                                        </div>
                                     </div>
                            </form> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" (click)="getWatch()" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{"FEATURED"|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" (click)="orderByLatest()" href="#watch" role="tab" aria-controls="listen" aria-selected="false">{{'Latest'|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#watch"  (click)="orderByTrending()" role="tab" aria-controls="read" aria-selected="false">{{'Trending'|translate}}</a> </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                    <!-- watch slide start-->
                                    <!-- <owl-carousel class="watch_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                        <div class="container item"  *ngFor="let one of watch">
                                                        <div class="row">
                                                                        <div id="icon_play" class="bg-white lg_br padding_1 box_shadow margin_1 watch_item">
                                                                                        <figure><img src="assets/img/featured_content/cinema.jpg" class="img_br">
                                                                                                        <div class="play"> <a href="content.html"> <img src="assets/img/icon/play.svg"></a> </div>
                                                                                        </figure>
                                                                                        <h5>Cinema
                                                                                                        Beyond Identity</h5>
                                                                                        <p>Neque porro quisquam est, qui  dolorem ipsum quia dolor sit  amet, consectetur, adipisci</p>
                                                                                        <div class="col-auto d-flex justify-content-center align-items-center"> <a href="#" class=" btn_sm_1">Play</a>
                                                                                                        <div class="rating" *ngIf="isLoggedIn">
                                                                                                                        <input type="radio" id="star7" name="rating" value="1">
                                                                                                                        <label for="star7">☆</label>
                                                                                                        </div>
                                                                                        </div>
                                                                        </div>

                                                        </div>
                                        </div>
                        </owl-carousel> -->
                                    <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                            <!-- <div class="watch_gallery owl-carousel owl-theme owl-loaded owl-drag text-center"> -->
                                                    <owl-carousel #owlElement [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="watch">
                                                    <div class="container" *ngFor="let one of watch"> 
                                                            <div class="row justify-content-center">
                                                                    <!-- item start -->
                                                                    <div *ngFor="let item of one"  id="icon_play" 
                                                                                                  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                            <figure [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"><img [src]="getImg(item)" class="img_br">
                                                                                    <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                            </figure>
                                                                            <h5 [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center">{{(item.title.length>56)? (item.title | slice:0:56)+'..':(item.title)}}</h5>
                                                                            <p [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                            <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                    <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'PLAY'|translate}}</a>
                                                                                <div class="rating social" *ngIf="isLoggedIn">
                                                                                                <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                </div>
                                                                </div>

                                                                    </div>
                                                                    <!-- item end -->
                                                            </div>
                                                    </div>
                                                </owl-carousel>
                                            <!-- </div> -->
                                            <!-- <div class="col-md-12 text-center">
                                                    <button class="btn_lg_3">View All </button>
                                            </div> -->
                                    </div>
                                    <!-- watch slide end -->
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">Listen</div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">Read</div>
                                    <div class="row  justify-content-center">
                                        <div *ngFor="let item of watch_more"  id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                <figure [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"><img [src]="getImg(item)" class="img_br">
                                                        <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                </figure>
                                                <h5 class="text-center" [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }">{{(item.title.length>56)? (item.title | slice:0:56)+'..':(item.title)}}</h5>
                                                <p [routerLink]="['/watch-content']" class="text-center" [queryParams]="{ q: item.slug }"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                        <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"PLAY"|translate}}</a>
                                                    <div class="rating social" *ngIf="isLoggedIn">
                                                                    <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                    </div>
                                             </div>

                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center mt-4"> <button *ngIf="next_page_url" (click)="load()" class="btn_lg_3 blue_btn">{{'VIEW_MORE'|translate}} </button> </div>

                            </div>
                    </div>
            </div>
    </div>
</section>
<!--Listen1 End -->






<section id="bg_design" class="container-fluid slide1 comman_slide">
        <div class="container">
                        <div class="row">
                                        <div class="col-md-12">
                                                        <h1 class="text-center blue"><span class="dark_grey">{{"FEATURED"|translate}}</span> {{"ARTISTS_SPEAKERS"|translate}}</h1>
                                                        <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
                                                        <owl-carousel class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                </owl-carousel>
                                                        <div class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                        <!-- item group1 start -->
                                                                        <div class="container" *ngFor="let one of featured_artists">
                                                                        <div class="row justify-content-center">
                                                                                <!-- <p>s</p> -->
                                                                                        <!-- item start -->
                                                                                        <div *ngFor="let artist of one" [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class="show_hand bg-white lg_br padding_1 pb-4  box_shadow margin_1 featured_item">
                                                                                                <figure><img [src]="getArtistImg(artist)" class="img_br"></figure> 
                                                                                                <h5> {{artist.name}}</h5>
                                                                                                <p>{{fixed_string(artist.short_description,80)}} </p>
                                                                                                <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1">{{'Explore'|translate}} </a>
                                                                                        </div>
                                                                                        
                                                                                     
                                
                                                                             </div>
                                                                             </div>
                                                                
                                                                        </div>
                                        </div>
                                        <div class="col-md-12 text-center mt-4"> <a  [routerLink]="['/artists']" class="btn_lg_2">{{'VIEW_ALL'|translate}} </a> </div>
                        </div>
        </div>
</section>
<!-- Featured Artists & Speakers End-->
<!-- The path to art begins here. Start -->
<!-- <section id="artist_bg">
        <div class="container-fluid design_corner">
                        <div class="row flex-nowrap">
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
                        </div>
        </div>
        <div class="container">
                        <div class="row text_text flex-nowrap">
                                        <div class="col-md-5 col-3 text-center">
                                        </div>
                                        <div class="col-md-7 col-9 text-center">
                                                        <h1 class="white">The path to art begins here.</h1>
                                                        <h4 class="white">Share the details of your favourite new artist below </h4>
                                                        <a href="#" class="btn_lg_5"> UPLOAD</a>
                                        </div>
                        </div>
        </div>
        <div class="container-fluid design_corner">
                        <div class="row flex-nowrap">
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
                        </div>
        </div>
</section> -->
<!-- The path to art begins here. End -->

<!-- <section id="bg_design" class="container-fluid slide5 comman_slide">
        <div class="container">
                        <div class="row">
                                        <div class="col-md-12">
                                                        <h1 class="text-center blue"><span class="dark_grey">Recommended</span> Playlists</h1>
                                                        <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
                                        </div>
                        </div>
                        <div class="row">
                                        <div class="col-md-12">
                                                        <div class="playlist owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                                   
                                                                                                        <div class="container">
                                                                                                        <div class="row">
                                                                                                                    
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/test3.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Patriotic </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                  
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/sufi.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Sufi</h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                     
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/classical.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Classical </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/soulful.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Soulful </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                
                                                                                                        </div>
</div>
                                                                                               
                                                                                                        <div class="container">
                                                                                                        <div class="row">
                                                                                                                 
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/test3.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Patriotic </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                    
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/sufi.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Sufi</h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                    
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/classical.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Classical </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                             
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/soulful.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Soulful </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/play_0.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                 
                                                                                                        </div>
</div>

                                                                                                  
                                                                                                         
                                                                                        </div>
                                        </div>
                                        <div class="col-md-12 text-center mt-4"> <a  href="#"class="btn_lg_2">View All </a> </div>
                        </div>
        </div>
</section> -->


<!-- Will you recommend us to friends and family? start -->
<!-- <section>
        <div class="container pt-4 pb-5">
                        <div class="row">
                                        <div class="col-md-12 mb-4 text-center">
                                                        <h5 class="blue">Will you recommend our platform to friends & family?</h5>
                                        </div>
                                        <div class="col-md-12 text-center"> <a href="#" class=" btn_yn mr-2">Yes </a> <a href="#" class=" btn_yn ml-2">No </a> </div>
                        </div>
        </div>
</section> -->


<section>
        <div [routerLink]="['/artists']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/watch.png"> </div>
    </section>