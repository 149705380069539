<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html"> &lt; Back to Home</a></li>
          <li class="breadcrumb-item">You are in:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; FAQ</li>
      </div>
    </div>
  </nav>
  <!-- Listen1 start -->
  <section id="bg_design" class="container-fluid slide2 white_slide">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-center blue">FAQs </h1>
          <div class="divder"> <img src="assets/img/icon/line_relive.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="main">
            <div class="container">
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1"> <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#faq1"                            aria-expanded="true" aria-controls="faq1">{{"What is Vishwarang?"|translate}}</a> </div>
                  <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"Vishwarang celebrates Indian culture on a global platform through literature, art, music & cinema and our goal is to unite enthusiasts of Indian culture from across the globe. Our focus is to promote Hindi and other Indian languages while giving a platform to local and regional talent. Vishwarang has its strong presence and participation from over 15+ countries and tie-ups with globally renowned artists."|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"                            aria-expanded="true" aria-controls="faq2">{{"How to sign up for the account?"|translate}}</a> </div>
                  <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                    <div class="card-body">
                      <p> {{"Just click on the user profile icon on the top right corner on the home page and fill in the required details. As a registered user, you can choose your content preferences to get personalized content and playlists recommendations."|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"                            aria-expanded="true" aria-controls="faq3">
                    {{"How do I subscribe to the newsletter?"|translate}}</a> </div>
                  <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"Stay up to date with all that's happening on Vishwarang. Just scroll down to the bottom right corner of the home page, click on the email subscription box, enter your email address and submit"|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead4"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"  aria-expanded="true" aria-controls="faq4">{{"How do I check my nomination?"|translate}}</a> </div>
                  <div id="faq4" class="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"You can check the status of your nomination by contacting us through our email: info@vishwarang.com"|translate}}
                        </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead5"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"               
                                 aria-expanded="true" aria-controls="faq5">{{"Where can you find us?"|translate}}</a> </div>
                  <div id="faq5" class="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"We are available on"|translate}} 
                      </p>
                      <div><a href='https://www.facebook.com/vishwarang.tagorelitfest' target='_blank' rel='nofollow'><i class='p-2 fab fa-facebook'></i></a><a  href='https://www.instagram.com/vishwarang_tagore_lit_fest/' target='_blank' rel='nofollow'><i  class='fab fa-instagram p-2'></i></a><a  href='https://www.youtube.com/c/TagoreLitFestVishwarang' target='_blank' rel='nofollow'><i  class='fab fa-youtube p-2'></i></a></div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead6"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"                  
                              aria-expanded="true" aria-controls="faq6">{{"How do I submit fan letters?"|translate}}</a> </div>
                  <div id="faq6" class="collapse" aria-labelledby="faqhead6" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"You can submit fan letters by sending us an email on info@vishwarang.com. Mention “Fan Letter” in the subject as well as the email body along with your message."|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead7"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq7"                            
                    aria-expanded="true" aria-controls="faq7">{{"Where can I watch Vishwarang videos?"|translate}}</a> </div>
                  <div id="faq7" class="collapse" aria-labelledby="faqhead7" data-parent="#faq">
                    <div class="card-body">
                      <p> {{"All of Vishwarang’s content is available on our website and Youtube channel."|translate}}<a  href='https://www.youtube.com/c/TagoreLitFestVishwarang' target='_blank' rel='nofollow'><i  class='fab fa-youtube p-2'></i></a> </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead8"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq8" 
                                               aria-expanded="true" aria-controls="faq8">{{"Does Vishwarang have any offline events?"|translate}}</a> </div>
                  <div id="faq8" class="collapse" aria-labelledby="faqhead8" data-parent="#faq">
                    <div class="card-body">
                      <p> {{"We conduct many offline events at different locations across India. To stay updated with the latest happenings of Vishwarang, follow our social media handles."|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead4"> <a href="#" class="btn btn-header-link collapsed" 
                    data-toggle="collapse" data-target="#faq9"                            
                    aria-expanded="true" aria-controls="faq9">{{"Can I select artists as per genre?"|translate}}</a> </div>
                  <div id="faq9" class="collapse" aria-labelledby="faqhead9" data-parent="#faq">
                    <div class="card-body">
                      <p>{{" Yes, you can select your preferred genre from the filters on the Artists Page and you will be able to see Artists from that genre."|translate}} </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead10"> <a href="#" class="btn btn-header-link collapsed" 
                    data-toggle="collapse" data-target="#faq10"                            
                    aria-expanded="true" aria-controls="faq10">{{"How do I participate in Vishwarang?"|translate}}</a> </div>
                  <div id="faq10" class="collapse" aria-labelledby="faqhead10" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"If you are an artist, you can drop us an email on info@vishwarang.com or upload your performance under the “Nominate” section."|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead11"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq11"  
                                              aria-expanded="true" aria-controls="faq11">{{"Where can I find the Media Kit?"|translate}}</a> </div>
                  <div id="faq11" class="collapse" aria-labelledby="faqhead11" data-parent="#faq">
                    <div class="card-body">
                      <p>{{"Please visit our Media section to download the media kit and to view all the press coverage."|translate}}</p>
                    </div>
                  </div>
                </div>
                <!--<div class="card">
                  <div class="card-header" id="faqhead12"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq12"                            aria-expanded="true" aria-controls="faq12">I am an artist, how do I submit my portfolio?</a> </div>
                  <div id="faq12" class="collapse" aria-labelledby="faqhead12" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong>How we acquire your data</strong><br>
                        Some of the Data that we acquire cannot identify, distinguish or trace you or your Device, even ifcombined with other identifying information, and some Data that could be considered PII whencombined with other identifying information is not used in a way that identifies, distinguishes ortraces you or your Device, but is instead used in an anonymous way, often aggregated with otheranonymous Data about other users. </p>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>