import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/shared/api.service';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.css']
})
export class ArtistsComponent implements OnInit {

  artists:any  =  [];
  artists_pagination:any  =  [];
  artists_list:any = [];
  img_url = '';
  categories:any = [];
  constructor(private titleService:Title,private head:HeaderComponent,private apiService:ApiService) { 
    titleService.setTitle("Artists");
    this.img_url = this.apiService.storage+'/project/images/thumbnail/artist/';
  }

  ngOnInit(): void {
    this.getArtists();
    this.apiService.getData('/interests?type=artist').subscribe(
      result => {
       return this.categories = result; 
      },
      error => {
      },
      () => {
      }
    )
  }

  getImg(artist:any){
    try{
      return this.img_url + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  load(url:any){
    
    this.apiService.geturlData(url).subscribe(
      result => {
        this.artists = result;
        this.artists_list = this.artists.data.data;
        // this.artists_pagination = this.artists.data.links;
        this.artists_pagination[0] = this.artists.data.links[0];
        this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  getArtists(){
    this.apiService.getData('/artists').subscribe(
      result => {
        this.artists = result;
        this.artists_list = this.artists.data.data;
        this.artists_pagination[0] = this.artists.data.links[0];
        this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  categoryChanged(event:any){
    this.apiService.getData('/artists?category='+event.target.value).subscribe(
      result => {
        this.artists = result;
        this.artists_list = this.artists.data;
      },
      error => {
      },
      () => {
      }
    )
  }
}
