<section id="bg_design" class="container-fluid registration">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <h1 class="text-center blue">{{"SignUp"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
                            <h5> {{"SIGN_UP_2"|translate}} </h5>
                    </div>
                    <div class="col-md-12 text-center mb-4"><strong>{{"Already_a_member"|translate}}</strong>
                            <a href="/login" class=" btn_lg_4 ml-3">{{"LogIn"|translate}} </a>
                    </div>
            </div>

            <div class="row pb-5" id="registration_form">
                    <div class="col-md-10 bg-white p-0 d-flex m-auto lg_br box_shadow">
                            <div class="col-md-7 offset-md-2 pt-3">
                                    <h5 class="text-md-left text-center">{{"Create_An_Account"|translate}} </h5>
                                    <p class=" d-none d-print-block d-lg-block ">All fields are mandatory*</p>
                                    <p class="form_error" *ngFor="let error of errors | keyvalue">{{error.value}}</p>
                                    <br>
                                    <form [formGroup]="registerForm"  (ngSubmit)="onSubmit()">
                                            <div class="form-row">
                                                    <div class="form-group col-md-3 col-12">
                                                            <input type="text" required minlength="4" class="form-control" [placeholder]="'First_Name'|translate" formControlName="first_name">
                                                    </div>
                                                    <div class="form-group col-md-3 col-12">
                                                            <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name">
                                                    </div>
                                                    <div class="form-group col-md-2 col-2">
                                                            <select id="inputState" class="form-control mobile_id"  formControlName="country_code" placeholder="+91">
                                                                    <option>+91</option>
                                                                    <option *ngFor="let one of country">+{{one.phonecode}}</option>
                                                            </select>
                                                    </div>
                                                    <div class="form-group col-md-4 col-10">
                                                            <input type="text" class="form-control" placeholder="Phone No."  formControlName="phone">
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                            <input type="email" class="form-control" id="inputEmail" [placeholder]="'Email_Address'|translate" formControlName="email"/>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                            <input type="password" class="form-control" id="inputPassword" [placeholder]="'Password'|translate"  formControlName="password">
                                                    </div>
                                            </div>
                                            <div class="form-row">
                                                    <div class="form-group col-md-4 col-4">
                                                            <select (change)="coutryChanged($event)" id="inputState" class="form-control" placeholder="India"  formControlName="country">
                                                                <option value="" selected>Select country</option>  
                                                                <option value="India" selected>India</option>   
                                                                <option *ngFor="let one of country" [value]="one.name">{{one.name}}</option>
                                                             
                                                            </select>
                                                    </div>
                                                    <div class="form-group col-md-4 col-4">
               <select  formControlName="state" class="form-control" id="inputState">           
                    <option selected value="">Select state</option>
                    <option *ngFor="let one of state" [value]="one.name">{{one.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4 col-10">
                        <input type="text" class="form-control" placeholder="City" formControlName="city">
                </div>
                                            </div>
                                            <div class="form-row">
                                                    <div class="form-group col-md-3 col-3">
                                                            <select  formControlName="gender" id="inputState" class="form-control" placeholder="Gender">
                                                                <option selected value="">{{"Gender"|translate}}</option>    
                                                                <option >Male</option>
                                                                    <option>Female</option>
                                                                    <option>Other</option>
                                                            </select>
                                                    </div>
                                                    <div class="form-group col-md-6 col-9">
                                                         <input id="datepicker" type="date" formControlName="dob" class="form-control"  [placeholder]="'DateOfBirth' | translate"/>                                                             
                                                    </div>
                                            </div>
                                            <div class="text-center col-md-12">
                                                    <button type="submit" class="btn_lg_4 bg-blue white">{{"SignUp"|translate}}</button>
                                            </div>
                                            <div class="text-center col-md-12">
                                                    <h6><span> </span> {{"or_sign_up_with"|translate}}<span> </span> </h6>
                                            </div>
                                            <div class="text-center col-md-12 d-flex align-items-center justify-content-center mt-4 mb-4">
                                                <div class="col-sm-2"> <img  (click)="signInWithGoogle()" class="social" src="assets/img/icon/google.svg"></div>
                                                <div class="col-sm-2"><img (click)="signInWithFB()" class="social" src="assets/img/icon/facebook_re.svg"> </div>
                                          
                                            </div>
                                    </form>
                            </div>
                            <!-- <div class="col-md-5 pr-0 d-none d-print-block d-lg-block "> <img src="assets/img/banner/banner_registration.jpg" class="lg_img_tr lg_img_br"></div> -->
                    </div>
                    <!-- item end -->
            </div>
    </div>
</section>