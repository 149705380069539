<nav aria-label="breadcrumb" id="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
    <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
  </ol>
  <li class="breadcrumb-item active" aria-current="page">&gt; {{"LISTEN"|translate}}</li>
</nav>
<!--Banner -->
<section>
        <div id="carouselExampleIndicators" class="carousel slide comman_banner_slider" data-ride="carousel">
           <div class="carousel-inner">
            <div class="carousel-item active">
         <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner12.jpg">    </div>
            <!-- <div class="carousel-item">
         <img src="assets/img/banner/banner_listen.jpg">    </div>
            <div class="carousel-item">
            <img src="assets/img/banner/banner_listen.jpg">
            </div> -->
          </div>
          
        </div>
        </section>
        <!--Banner End-->
        <!-- Listen1 start -->
        <section id="bg_design_2" class="container-fluid slide2 white_slide">
                        <div class="container">
                                        <div class="row">
                                                        <div class="col-md-12">
                                                                        <h1 class="text-center white">{{'TopAudioPicks'|translate}}</h1>
                                                                        <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                                                                        <ul class="nav nav-tabs flex-nowrap mb-4" id="comman_tab" role="tablist">
                                                                        <li class="col-md-3 col-4 p-0"><form class="col-md-12 p-0">
                                                                                        <div class="form-row">
                                                                                        <div class="form-group mb-0 col-md-12 cos_select">   
                                                                                          <select  (change)="categoryChanged($event)" id="inputState" class="form-control" placeholder="Category">
                                                                                            <option selected="" value="" class="text-center">{{"Category"|translate}}</option>
                                                                                            <option  *ngFor="let link of categories" [value]="link.id">{{link.name}}</option>
                                                                                    </select>
                                                                                    
                                                                                         </div>
                                                                                        
                                                                                         </div>
                                                                        </form> </li>
                                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white active" id="featured-tab" data-toggle="tab" href="#featured" role="tab" aria-controls="featured" aria-selected="false">{{"Featured"|translate}}</a> </li>
                                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="latest-tab" data-toggle="tab" href="#featured" (click)="orderByLatest()" role="tab" aria-controls="latest" aria-selected="false">{{'Latest'|translate}}</a> </li>
                                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="trending-tab" data-toggle="tab" href="#featured" (click)="byTrending()"  role="tab" aria-controls="read" aria-selected="false">{{'Trending'|translate}}</a> </li>
                                                                        </ul>
                                                                        <div class="tab-content" id="myTabContent">
                                                                                        <!-- watch slide start-->
                                                                                        <div class="tab-pane fade show active" id="featured" role="tabpanel" aria-labelledby="watch-tab">
                                                                                          <!-- <owl-carousel class="listen owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                          </owl-carousel>               -->
                                                                                          <div class=" text-center">
                                                                                    
                                                                                            <!-- <div class="listen owl-carousel owl-theme owl-loaded owl-drag text-center"> -->
                                                                                              
                                                    <owl-carousel #owlElement [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="listen">
                                                                                              <div class="container" *ngFor="let one of listen">
                                                                                                              <div class="row justify-content-center">
                                                                                                                              <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                                                                <figure  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" ><img [src]="getImg(item)" class="img_br">
                                                                                                                                                <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                                                                </figure>
                                                                                                                                <h5  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>  
                                                                                                                                <p  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{item.information|slice: 0: 80}}</p>
                                                                                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{"Play"|translate}}</a>
                                                                                                                                                <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                  <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                </div>
                                                                                                 <!-- item end -->
                                                                                                              </div>
                                                                                              </div>
                                                                                              </owl-carousel>
                                                                                            
                                                                                            <!-- </div> -->
                                                                                                                        <div class="container">
                                                                                                                                        <div class="row">
                                                                                                                                                        <div id="icon_play" *ngFor="let item of listen_more"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                                                                                                        <figure  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" ><img [src]="getImg(item)" class="img_br">
                                                                                                                                                                                        <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                                                                                                        </figure>
                                                                                                                                                                        <h5  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" >{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>
                                                                                                                                                                        <p  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" >{{item.information|slice: 0: 80}}</p>
                                                                                                                                                                        <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                                                        <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">Play</a>
                                                                                                                                                                                        <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                                                          <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                                                          </div>
                                                                                                                                                                        </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!-- item end -->
                                                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                     
                                                                                                        </div>
                                                                                                        <div class="col-md-12 text-center mt-4">
                                                                                                          <button *ngIf="next_page_url" (click)="load()" class="btn_lg_3 blue_btn">{{"VIEW_MORE" | translate}} </button> 
                                                                                                        </div>
                                                                                        </div>
                                                                                        <!-- watch slide end -->
                                                                                        <div class="tab-pane fade" id="latest" role="tabpanel" aria-labelledby="latest-tab">{{'Latest'|translate}}</div>
                                                                                        <div class="tab-pane fade" id="trending" role="tabpanel" aria-labelledby="trending-tab">{{'Trending'|translate}}</div>
                                                                        </div>
                                                        </div>
                                        </div>
                        </div>
        </section>
        <!--Listen1 End -->
        
        
        
        
        
        
        <!-- Featured Artists & Speakers start -->
        <section id="bg_design" class="container-fluid slide1 comman_slide">
                        <div class="container">
                                        <div class="row">
                                                        <div class="col-md-12">
                                                          <h1 class="text-center blue"><span class="dark_grey">{{"FEATURED"|translate}}</span> {{"ARTISTS_SPEAKERS"|translate}}</h1>
                                                                        <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
                                                                        <div class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                          <div class="container" *ngFor="let one of featured_artists">
                                                                            <div class="row  justify-content-center">
                                                                                    <!-- <p>s</p> -->
                                                                                            <!-- item start -->
                                                                                            <div *ngFor="let artist of one" [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }"  class="show_hand bg-white lg_br padding_1 pb-4  box_shadow margin_1 featured_item">
                                                                                                    <figure><img [src]="getArtistImg(artist)" class="img_br"></figure>
                                                                                                    <h5> {{artist.name}}</h5>
                                                                                                    <p>{{artist.short_description|slice: 0: 80}} </p>
                                                                                                    <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1">{{"Explore"|translate}} </a>
                                                                                            </div>
                                                                                            
                                                                                         
                                    
                                                                                 </div>
                                                                                 </div>
                                                                        </div>
                                                        </div>
                                                        <div class="col-md-12 text-center mt-4">
                                                                        <a [routerLink]="['/artists']" class="btn_lg_2">{{"VIEW_ALL" | translate}} </a>
                                                        </div>
                                        </div>
                        </div>
        </section>
        <!-- Featured Artists & Speakers End -->
        
        <!-- The path to art begins here. Start -->
        
        <section>
          <div [routerLink]="['/fresh_talent']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/listen.png"> </div>
      </section>