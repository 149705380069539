<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="container">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/about-us']">{{'Youarein'|translate}}: {{"ABOUT_US"|translate}} &gt; {{"PARTICIPATING_CONTRIES"|translate}}</a></li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; {{api_Data.name}}</li>
      </div>
    </div>
  </nav>
  <section id="bg_design" class=" p-0 country_ban">
    <div class="container">
      <div class="row flex-nowrap">
        <div class="col-md-6 pl-0">
          <div class="country_logo"> <img [src]="flag(api_Data.flag)">
            <h4> {{api_Data.name}} </h4>
          </div>
          <img [src]="profile_pic(main_member.profile_pic)" class=" d-none d-lg-block"> 
           <img [src]="profile_pic(main_member.profile_pic)" class=" d-lg-none">  
        </div>
        <div class="col-md-6 p-0 text-center">
          <div class="col-auto p-0 h-100 d-flex flex-column justify-content-center">
            <div class="col-auto pl-0">
              <h1 class="blue">{{main_member.person_name}}</h1>
              <h5>– {{main_member.designation}}</h5>
              <div class="divder"> <img src="assets/img/icon/line.svg"></div>
              <p class="blue">{{main_member.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Listen1 start -->
  <section id="bg_design" class="blank_bg country_slide comman_slide">
    <div class="container ">
      <div class="row justify-content-center">
        <div class="col-md-12 p-0">
          <div class="con_video owl-carousel owl-theme owl-loaded owl-drag text-center">
            <div class="container">
              <div class="row justify-content-center">
                <!-- item start -->
                <div class="bg-white lg_br padding_1 box_shadow  con_item show_hand" (click)="change_member(member)" *ngFor="let member of team">
                  <figure> <img [src]="profile_pic(member.profile_pic)" class="lg_br"> </figure>
                  <h5> {{member.person_name}}</h5>
                  <p>{{api_Data.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-watch-read-listen [view_type]="watch_read_listen_view_type" [country_id]="country"></app-watch-read-listen>

<section>
  <div [routerLink]="['/login']" *ngIf="!logged_in" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/country.png"> </div>
</section>