<nav aria-label="breadcrumb" id="breadcrumb">
  <div class="col-md-12">
    <div class="row">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
      </ol>
      <li class="breadcrumb-item active" aria-current="page">&gt; {{"NOMINATION"|translate}}</li>
    </div>
  </div>
</nav>

<!--Banner -->
     <section>
        <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner11.jpg"> </div>
      </section>
      <!--Banner End-->

      <section id="bg_design" class="slide1" *ngIf="nominations.length !== 0">
        <div class="container">
                <div class="row justify-content-center">
                        <div class="col-md-12">
                                <h1 class="text-center blue">{{"Approved Nominations"|translate}}</h1>
                                <div class="divder"> <img src="assets/img/icon/line_relive.svg"> </div>
                        </div>
                </div>    
                <div class="row text-center pb-3 justify-content-center">
                           <!-- item start -->
                <div class="col-md-3 col-6 margin_bottom show_hand" *ngFor="let nomination of nominations">
                        <div class="bg-white lg_br padding_1 box_shadow pb-4 py_4">
                                <figure><img [src]="getImg(nomination)" class="img_br"></figure>
                                <h5> {{nomination.name}}</h5>
                        </div>
                </div>
    
        </div>
    
                </div>
    </section>


      <!-- Listen1 start -->
      <section id="bg_design" class="container-fluid nominate slide2c white_slide">
        <div class="container">
          <!-- <div class="row text-center justify-content-center" *ngIf="!logged_in">
            <p class="w-100 mb-2">Login to nominate</p>
            <a [routerLink]="['/login']" [queryParams]="{ redirectTo: '/nomination' }" class="btn_lg_2">{{"LogIn"|translate}}</a>
          </div> -->
          <div class="row text-center">
            <div class="col-md-12">
              <h1 class="text-center blue mb-0">{{"JoinTheTeam"|translate}}</h1>
              <p>{{"Ourglobalstageawaitsyourtalent"|translate}} </p>
              <div class="divder mt-3"> <img src="assets/img/icon/line_relive.svg"> </div>
            </div>
            <div class="col-md-9 m-auto p-0" *ngIf="!message_sent">
                <p class="form_error" *ngFor="let error of errors | keyvalue">{{error.value}}</p>
              <form [formGroup]="nominationForm"  (ngSubmit)="sendNomination()">                  
                <div class="rowll">
                  <div class="col-md">
                    <div class="row">
                      <div class="form-group col-md-12">
                        <input autocomplete="off" class="form-control" formControlName="name" type="text" name="name" placeholder="{{'Name'|translate}}"/>
                        </div>
                        </div>
                  <!-- <div class="form-row"> -->
                  <div class="row">
                    <div class="form-group col-md-3 col-3">
                      <select  formControlName="country_code" id="inputState" class="form-control mobile_id" placeholder="+91">
                              <option selected>+91</option>
                              <option *ngFor="let one of country">+{{one.phonecode}}</option>
                      </select>
              </div>
              <div class="form-group col-md-9 col-9">
                      <input type="text" class="form-control" placeholder="{{'Phone No.'|translate}}"  formControlName="phone">
              </div>

                    <div class="form-group col-md-6 col-6">
                      <select (change)="coutryChanged($event)" id="inputState" class="form-control" placeholder="India"  formControlName="country">
                          <option value="" selected>{{"Select country"|translate}}</option>  
                          <option value="India" selected>India</option>   
                          <option *ngFor="let one of country" [value]="one.name">{{one.name}}</option>
                       
                      </select>
              </div>
              <div class="form-group col-md-6 col-6">
<select  formControlName="state" class="form-control" id="inputState">           
<option selected value="">{{"Select state"|translate}}</option>
<option *ngFor="let one of state" [value]="one.name">{{one.name}}</option>
</select>
</div>
                  </div>


                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <input type="text" class="form-control" placeholder="{{'City'|translate}}" formControlName="city">
                      </div>
                      <div class="form-group col-md-6 col-6">
                        <select  formControlName="gender" id="inputState" class="form-control" placeholder="Gender">
                            <option selected value="">{{"Gender"|translate}}</option>    
                            <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                        </select>
                      </div>

                        <div class="form-group col-md-12">                               
                          <select id="inputState" class="form-control" placeholder="Category" formControlName="category">
                            <option selected="selected" value="">{{"Category"|translate}}</option>
                            <option  *ngFor="let link of categories" [value]="link.id">{{link.name}}</option>
                    </select>
                        </div>
                      
                  
                    <div class="form-group col-md-12">
                      <input autocomplete="off" class="form-control" formControlName="email" type="email" name="email" placeholder="{{'Email'|translate}}"/>
                      </div>
                    
                  </div>


                            
                            <div  class="row">
                              <div class="form-group col-md-12">
                                <label for="custom-file-upload"> 
                                  {{"Attach Profile Photo"|translate}} </label> 

                                  <input type="file" title="hh" #profile_pic  accept="image/*,video/*"  attr.your-custom = "{{ 'Select File' | translate}}" class="tcustom-file-input2" (change)="processProfilePicFile(profile_pic)"/>

              </div>
                            </div>

                    <div class="row">
                      <div class="form-group col-md-12">
                          <textarea  class="form-control" [placeholder]="'TellusaboutNominee'|translate" [(ngModel)]="message"  formControlName="message"></textarea>
                      </div>
                      <div class="form-group col-md-12">
                         <label for="custom-file-upload"> 
                          {{"Attach Nominee's Work"|translate}}  </label> 
        <input type="file"  title="your text"  #files  accept="image/*,video/*" attr.your-custom = "{{ 'Select File' | translate}}" class="tcustom-file-input2" (change)="processFile(files)"/>
     
                      </div>
                      <div class="form-group col-md-12 d-flex" id="add_field01"> 
                        <input style="justify-content: left;height: unset !important;
                        float: left;" autocomplete="off" class="form-control" id="field1" formControlName="links" type="text" name="links" placeholder='{{"URLofNomineeWork"|translate}}' data-items="8"/>
                        <!-- <button id="b1" class="btn add-more " type="button"> </button> -->
                      </div>

                      <div class="form-group col-md-12">
                        <label class="text-left">{{"Social Media Handles"|translate}}</label>
                        <!-- <input autocomplete="off" class="form-control" formControlName="facebook_link" type="url" name="facebook_link" placeholder="Facebook Link"/>
                         -->
                            <input autocomplete="off" class="form-control" formControlName="youtube_link" type="url" name="youtube_link" placeholder="Facebook,Instagram,Youtube etc"/>
                            </div>


                            <div class="form-group col-md-12">
                              <label class="text-left">{{"Contest Name (If any)"|translate}}</label>
                              <!-- <input autocomplete="off" class="form-control" formControlName="facebook_link" type="url"  name="facebook_link" placeholder="Facebook Link"/>
                               -->
                                  <input autocomplete="off" class="form-control" formControlName="contest" type="text" name="contest" placeholder="{{'Contest Name (If any)'|translate}}"/>
                                  </div>
                            </div>

                  </div>
                </div>
                <div class="text-center col-md-12">
                  <p><a [routerLink]="['/privacy-policy']" class="blue">*{{'TermsConditionsApply'|translate}}</a></p>
                  <button type="submit" class="btn_lg_4 mt-3 bg-blue white">{{"SUBMIT"|translate}}</button>
                </div>
              </form>

            </div>
            <div class="col-md-12 pb-5 text-center" *ngIf="message_sent">
                <h5 class="text-center w-100 mt-5">{{'nomination_message'|translate}}</h5>
        </div>
          </div>
        </div>
      </section>

    

    <section id="bg_design" class="container-fluid nominate slide2 white_slide pt-5">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h1 class="text-center blue mb-0">FAQs </h1>
             <div class="divder mt-3"> <img src="assets/img/icon/line_relive.svg"> </div>
          </div>
          <div class="col-md-9 col-12 m-auto p-0  text-left">
         <p><strong>Q:How many entries can one person submit?</strong></p>
         <p class="mb-3">A:In one contest one entry</p>
         <p><strong>Q:What formats are allowed for submission?</strong></p>
         <p class="mb-3">A:Audio, Video, Writing </p>
     
         <p><strong>Q:Eligibility criteria for participation? </strong></p>
         <p class="mb-3">A:Participants age should be 18+ years</p>
     
         <p><strong>Q:Is the contest open for international citizens?</strong></p>
         <p class="mb-3">A: Yes</p>
     
         <!-- <p><strong>Q:How many entries can one person submit?</strong></p>
         <p class="mb-3">A:Only one entry </p> -->
     
         <p><strong>Q:Usage rights of the artists and Vishwarang</strong></p>
         <p class="mb-3">A:Yes</p>
     
         <p><strong>Q:Can we submit an entry which has been already submitted to another contest (third party) </strong></p>
         <p class="mb-3">A:Yes</p>
     
         <p><strong>Q:Are there any cash prizes/monetisation after the entry is live on the website?</strong></p>
         <p class="mb-3">A:No</p>
     
         <p><strong>Q:What are the benefits if I get listed under Fresh Talent?</strong></p>
         <p class="mb-3">A:We will feature your talent on VR social media platforms. 
    </p>
     
         <p><strong>Q:Will I be included in the annual festival?</strong></p>
         <p class="mb-3">A:Depends on the availability. </p>
         <p><strong>Q:What is the last date of submission?</strong></p>
         <p class="mb-3">A:There is  on last date. </p>
          
          </div>
        </div>
      </div>
    </section>

  <section>
      <div [routerLink]="['/artists']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/nominate.png"> </div>
  </section>