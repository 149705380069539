<nav aria-label="breadcrumb" id="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; Forget Password</li>
</nav>
<!-- Sign Up start -->
<section id="bg_design" class="container-fluid registration">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <h1 class="text-center blue">Forget Password</h1>
                            <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
                    </div>
                     
            </div>
            <div class="row pb-5" id="registration_form">
                    <!-- item start -->
                    <div class="col-md-6 bg-white p-0 d-flex m-auto lg_br box_shadow">
                            <div class="col-md-12 pt-3" *ngIf="!otp_sent">
                                    <p class=" d-none d-print-block d-lg-block ">Get OTP on your email</p>
                                    <p class=" success" *ngIf="welcome_user">Account created successfully.</p>
                                    
                                    <p class=" d-none d-print-block d-lg-block error" *ngIf="error">Wrong email or password.</p>
                                    <form  [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                                            <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                            <input type="email" required minlength="4" formControlName="email" class="form-control" id="inputEmail" placeholder="Email Address">
                                                    </div>
                                            </div>

                                            <div class="text-center col-md-12">
                                                    <button type="submit" class="btn_lg_4 bg-blue white">Submit</button>
                                            </div>                                         

                                            <a [routerLink]="['/login']" class="text-center mt-4 mb-4" style="margin: auto;
                                            display: flex;
                                            justify-content: center;
                                            width: fit-content;">Back to Login page</a>
                                    </form>
                            </div>

                            <div class="col-md-12 pt-3" *ngIf="otp_sent && !resetted">

                                <p class=" d-none d-print-block d-lg-block ">Change your password</p>
                                <p>{{message}}</p>
                                
                                <form  [formGroup]="setPasswordForm" (ngSubmit)="setPassword()">
                                        <div class="form-row">
                                                <div class="form-group col-md-12">
                                                        <input type="number" required minlength="4" formControlName="otp" class="form-control" id="otp" placeholder="OTP">
                                                </div>
                                                <div class="form-group col-md-12">
                                                        <input type="password" required minlength="4" formControlName="password" class="form-control" id="password" placeholder="Password">
                                                </div>
                                        </div>

                                        <div class="text-center col-md-12">
                                                <button type="submit" class="btn_lg_4 bg-blue white">Change password</button>
                                        </div>                                         

                                        <a [routerLink]="['/login']" class="text-center mt-4 mb-4" style="margin: auto;
                                        display: flex;
                                        justify-content: center;
                                        width: fit-content;">Back to Login page</a>
                                </form>
                        </div>

                        <div class="col-md-12 pt-3" *ngIf="resetted">
                                <p>{{message}}</p>
                                <a [routerLink]="['/login']" class="text-center mt-4 mb-4" style="margin: auto;
                                display: flex;
                                justify-content: center;
                                width: fit-content;">Continue to Login</a>
                        </div>

                            
                    </div>
                    <!-- item end -->
            </div>
    </div>
</section>