import { Component, OnInit } from '@angular/core';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-painting',
  templateUrl: './painting.component.html',
  styleUrls: ['./painting.component.css']
})
export class PaintingComponent implements OnInit {
  slider:any= [];
  category:any  =  [];
  url='https://uatweb.vishwarang.com/admin/storage/app/public/project/images/painting_categories';
  
  // url='https://vishwarang.com/admin/storage/app/public/project/images/painting_categories';
  constructor(private apiService:ApiService,private initApp:InitAppService) {
  }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory(){
    this.apiService.requestGet('/category_list').subscribe(
      result => { 
        this.category =result.data.data ;
        console.log('CategorySAD',this.category)
      },
      error => {
        
      },
      () => {
      }
    )
  }
}
