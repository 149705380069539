<nav aria-label="breadcrumb" id="breadcrumb">
	<div class="col-md-12">
			<div class="row">
					<ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
                                                <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
					</ol>
					<li class="breadcrumb-item active" aria-current="page">&gt; {{"RELIVE"|translate}}</li>
			</div>
	</div>
</nav>
 <!--Banner -->
<section>
<div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner15.jpg"> </div>
</section>
<!--Banner End-->
<!-- start -->
<section id="bg_design" class="container-fluid slide2 white_slide"><div class="bg_number">
	<div class="container">
			<div class="row text-center">
				
                    <div class="col-md-12">
						<div class="row justify-content-center tab_relive">
                            <ul class="nav nav-tabs mb-4" id="comman_tab" role="tablist">
                                    <li class="nav-item" role="presentation"> <a class="nav-link blue_btn active" id="watch-tab" (click)="loadWatch()" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{'WATCH'|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link blue_btn" id="listen-tab" (click)="loadListen()" data-toggle="tab" href="#listen" role="tab" aria-controls="listen" aria-selected="false">{{'LISTEN'|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link blue_btn" id="read-tab" (click)="loadRead()" data-toggle="tab" href="#read" role="tab" aria-controls="read" aria-selected="false">{{'READ'|translate}}</a> </li>
                            </ul>
</div>
                         <div class="row justify-content-center tab_relive">
							<ul class="nav  col-md-4 col-10 m-auto mb-4 " id="relive_tab">
								<li class="nav-item d-flex " *ngFor="let item of years" (click)="filter_(item,'year')"> 
									<a class="nav-link show_hand text-white" data-toggle="tab" [class.font_bold] = "active_year === item" role="tab">{{item}} </a> 
								</li> 
							</ul>
							</div>
                                                        <br>

                                                        <div class="col-md-12 text-center blue mb-3"> {{"Selectafestivalofyourchoice" | translate}} </div>
                                                        <div  role="tablist" class="row justify-content-center tab_relive mb-4"> 
                                                            <ul class="nav nav-tabs flex-nowrap mb-4" id="comman_tab" role="tablist">
                                                            <li class="nav-item" role="presentation" *ngFor="let item of events">
                                                            <a data-toggle="tab" [href]="'#listen'+item.event_.id" role="tab" (click)="filter_(item.event_.id,'event')" class="nav-link blue_btn">{{item.event_.name|translate}}</a> 
                                                        </li> 
                                                        </ul>
                                                        </div>
                            <div class="tab-content" id="myTabContent">
                                    <!-- watch slide start-->
                                    <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                        <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery', 'slidng']" [options]="{items: 1, dots: true, nav: true}" [items]="watch">
                                                <div class="container" *ngFor="let one of watch"> 
                                                        <div class="row justify-content-center">
                                                                <!-- item start -->
                                                                <div *ngFor="let item of one"  id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                         <figure [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"><img [src]="watchService.getImg(item)" class="img_br">
                                                                                <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                        </figure>
                                                                        <h5 [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center">{{(this.helper.getStringLength(item.title)>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                        <p [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class="text-center"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                        <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'PLAY'| translate}}</a>
                                                                            <div class="rating social" *ngIf="isLoggedIn">
                                                                                            <label (click)="switchWatchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                            </div>
                                                            </div>

                                                                </div>
                                                                <!-- item end -->
                                                        </div>
                                                </div>
                                            </owl-carousel>
                                     </div>
                                    <!-- watch slide end -->
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">
                                                                                                  
                                        <owl-carousel #owlListen [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="listen">
                                                <div class="container" *ngFor="let one of listen">
                                                                <div class="row justify-content-center">
                                                                                <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                  <figure  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" ><img [src]="listenService.getImg(item)" class="img_br">
                                                                                                  <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                  </figure>
                                                                                  <h5  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>  
                                                                                  <p  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{item.information|slice: 0: 80}}</p>
                                                                                  <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                  <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{'LISTEN'| translate}}</a>
                                                                                                  <div class="rating social" *ngIf="isLoggedIn">
                                                                                                    <label (click)="switchListenFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                    </div>
                                                                                  </div>
                                                                  </div>
                                                   <!-- item end -->
                                                                </div>
                                                </div>
                                                </owl-carousel>

                                     </div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">

                                        <owl-carousel #owlRead [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="read">
                                                <div class="container" *ngFor="let one of read">
                                                                <div class="row justify-content-center">
                                                                                <!-- item start -->
                                                                                <div id="icon_play" *ngFor="let item of one" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                <figure [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" > <img [src]="readService.getImg(item)" class="img_br"> </figure>
                                                                                                <h5 [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                         <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'READ'| translate}}</a>
                                                                                                         <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                <label (click)="switchReadFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                </div>
                                                                                                </div>
                                                                                </div>
                                                                                <!-- item end -->
                                                                </div>
                                                </div>
                                        </owl-carousel>
                                     </div>
                            </div>
                    </div>
            
			</div>
	</div>
</div>
</section>
<!--End -->

<!-- start -->
<section id="bg_design_2" class="slide2 white_slide relive01">
	<div class="container">
			<div class="row text-center">
					<div class="col-md-12">
							<h1 class="text-center white"> {{"Gallery"|translate}}</h1>
							<div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>						
                                                        <ul class="nav  col-md-4 col-10 m-auto mb-4" id="relive_tab">
								<li class="nav-item d-flex " *ngFor="let item of media.years" (click)="filter_gallery(item,'year')"> 
									<a class="nav-link show_hand text-white" data-toggle="tab" [class.font_bold] = "active_year === item" role="tab">{{item}} </a> 
								</li> 
							</ul>
			<div class="tab-content" id="myTabContent">
				<div class="tab-pane fade show active" id="watch1" role="tabpanel" aria-labelledby="watch-tab">
					<div class="row justify-content-center tab_relive mb-4"> 
                                                <a href="javascript::void()" *ngFor="let event of media.events"  (click)="filter_gallery(event.event_id,'event')" class="white_btn active">{{event.event_name|translate}}</a> </div>
						<div class="scroll_gallery scroll_gallery03 d-flex flex-wrap">
<!-- Item Start -->
   <div class="col-md-4 col-4 margin_bottom"  *ngFor="let event of media.content"> <a [href]="getMediaImg(event)" data-toggle="lightbox" data-gallery="gallery">
      <img [src]="getMediaImg(event)" class="img-fluid img_br"> </a> 
   </div>
<!-- Item End -->
          </div>
     </div>
    </div>
  </div>
</div>
</div>
</section>


<section>
        <div [routerLink]="['/attend']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/relive.png"> </div>
      </section>