<nav aria-label="breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
                <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; {{"READ"|translate}}</li>
      </nav>
<!--Banner -->
<section>
        <div id="carouselExampleIndicators" class="carousel slide comman_banner_slider" data-ride="carousel">
                        <div class="carousel-inner">
                                        <div class="carousel-item active"> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner14.jpg"> </div>
                                        <!-- <div class="carousel-item"> <img src="assets/img/banner/banner_listen.jpg"> </div>
                                        <div class="carousel-item"> <img src="assets/img/banner/banner_listen.jpg"> </div> -->
                        </div>
                       </div>
</section>
<!--Banner End-->
<!-- Listen1 start -->
<section id="bg_design_2" class="container-fluid slide2 white_slide">
        <div class="container">
                        <div class="row">
                                        <div class="col-md-12">
                                                        <h1 class="text-center white">{{'LiteraryCollection'|translate}}</h1>
                                                        <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                                                        <ul class="nav nav-tabs flex-nowrap mb-4" id="comman_tab" role="tablist">
                                                                        <li class="col-md-3 col-4 p-0">
                                                                                        <form class="col-md-12 p-0">
                                                                                                        <div class="form-row">
                                                                                                                <div class="form-group mb-0 col-md-12 cos_select">
                                                                                                                                <select  (change)="categoryChanged($event)" id="inputState" class="form-control" placeholder="Category">
                                                                                                                                        <option selected="" value="" class="text-center">{{'Category'|translate}}</option>
                                                                                                                                        <option  *ngFor="let link of categories" [value]="link.id">{{link.name}}</option>
                                                                                                                                </select>	
                                                                         </div>
                                                                                                                        
                                                                                                         
                                                                                                        </div>
                                                                                        </form>
                                                                        </li>
                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{'Featured'|translate}}</a> </li>
                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="listen" (click)="orderByLatest()" aria-selected="false">{{'Latest'|translate}}</a> </li>
                                                                        <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="read"  (click)="byTrending()" aria-selected="false">{{'Trending'|translate}}</a> </li>
                                                        </ul>
                                                        <div class="tab-content" id="myTabContent">
                                                                        <!-- watch slide start-->
                                                                        <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                                                                <!-- <owl-carousel class="read_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                </owl-carousel>   -->
                                                                               
                                                                                <!-- <div class="read_gallery owl-carousel owl-theme owl-loaded owl-drag text-center"> -->
                                                                                        
                                                    <owl-carousel #owlElement [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="read">
                                                                                        <div class="container" *ngFor="let one of read">
                                                                                                        <div class="row justify-content-center">
                                                                                                                        <!-- item start -->
                                                                                                                        <div id="icon_play" *ngFor="let item of one" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                                                        <figure [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" > <img [src]="getImg(item)" class="img_br"> </figure>
                                                                                                                                        <h5 [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }"  class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                                                        <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                 <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                                                                                                 <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                        <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                        </div>
                                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <!-- item end -->
                                                                                                                 
                                                                                                        </div>
                                                                                        </div>
                                                                                        </owl-carousel>
                                                                                      
                                                                        <!-- </div> -->

                                                                                <div class="text-center">
                                                                                                        <div class="container">
                                                                                                                        <div class="row">
                                                                                                                                        <!-- item start -->
                                                                                                                                        <div id="icon_play" *ngFor="let item of read_more" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                                                                        <figure [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" > <img [src]="getImg(item)" class="img_br"> </figure>
                                                                                                                                                        <h5 [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" >{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                                                                        <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                                 <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                                                                                                                 <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                                        <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                                        </div>
                                                                                                                                                        </div>
                                                                                                                                        </div>
                                                                                                                                        <!-- item end -->
                                                                                                                                 
                                                                                                                        </div>
                                                                                                        </div>
                                                                                                      
                                                                                        </div>
                                                                                        <div class="col-md-12 text-center mt-4"> <button *ngIf="next_page_url" (click)="load()" class="btn_lg_3 blue_btn">{{"VIEW_MORE" | translate}} </button> </div>
                                                                        </div>
                                                                        <!-- watch slide end -->
                                                                        <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">{{"LISTEN"|translate}}</div>
                                                                        <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">{{"READ"|translate}}</div>
                                                        </div>
                                        </div>
                        </div>
        </div>
</section>
<!--Listen1 End -->

<!-- Featured Artists & Speakers start -->
<section id="bg_design" class="container-fluid slide1 comman_slide">
        <div class="container">
                        <div class="row">
                                        <div class="col-md-12">
                                                        <h1 class="text-center blue"> {{"FeaturedAuthorsandSpeakers"|translate}}</h1>
                                                        <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
                                                        <div class="artists owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                <div class="container" *ngFor="let one of featured_artists">
                                                                        <div class="row justify-content-center">
                                                                                <!-- <p>s</p> -->
                                                                                        <!-- item start -->
                                                                                        <div *ngFor="let artist of one" [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }"  class="show_hand  bg-white lg_br padding_1 pb-4  box_shadow margin_1 featured_item">
                                                                                                <figure><img [src]="getArtistImg(artist)" class="img_br"></figure>
                                                                                                <h5> {{artist.name}}</h5>
                                                                                                <p>{{artist.short_description|slice: 0: 80}} </p>
                                                                                                <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1">{{'Explore'|translate}} </a>
                                                                                        </div>                                
                                                                             </div>
                                                                             </div>
                                                        </div>
                                        </div>
                                        <div class="col-md-12 text-center mt-4"> <a  [routerLink]="['/artists']" class="btn_lg_2">{{"VIEW_ALL" | translate}} </a> </div>
                        </div>
        </div>
</section>
<!-- Featured Artists & Speakers End -->
<!-- The path to art begins here. Start -->

<!-- <section id="artist_bg">
        <div class="container-fluid design_corner">
                        <div class="row flex-nowrap">
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
                        </div>
        </div>
        <div class="container">
                        <div class="row text_text flex-nowrap">
                                        <div class="col-md-5 col-3 text-center">
                                        </div>
                                        <div class="col-md-7 col-9 text-center">
                                                        <h1 class="white">The path to art begins here.</h1>
                                                        <h4 class="white">Share the details of your favourite new artist below </h4>
                                                        <a href="#" class="btn_lg_5"> UPLOAD</a>
                                        </div>
                        </div>
        </div>
        <div class="container-fluid design_corner">
                        <div class="row flex-nowrap">
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
                                        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
                        </div>
        </div>
</section> -->
<!-- The path to art begins here. End -->
<!-- <section id="bg_design" class="container-fluid slide5 comman_slide">
        <div class="container">
                        <div class="row">
                                        <div class="col-md-12">
                                                        <h1 class="text-center blue"><span class="dark_grey">Recommended</span> Playlists</h1>
                                                        <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
                                        </div>
                        </div>
                        <div class="row">
                                        <div class="col-md-12">
                                                        <div class="playlist owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                            
                                                                                                        <div class="container">
                                                                                                        <div class="row">
                                                                                                  
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/test3.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Patriotic </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/sufi.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Sufi</h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                  
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/classical.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Classical </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                     
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/soulful.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Soulful </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                
                                                                                                        </div>
</div>
                                                                                                      
                                                                                                        <div class="container">
                                                                                                        <div class="row">
                                                                                                        
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/test3.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Patriotic </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                         
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/sufi.jpg"  class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Sufi</h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                  
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/classical.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Classical </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                   
                                                                                                                        <div class="bg-white lg_br padding_1 box_shadow margin_1 playlist_item">
                                                                                                                                        <figure> <img src="assets/img/playlists/soulful.jpg" class="img_br">
                                                                                                                                                        <div class="text_0">
                                                                                                                                                                        <p>The</p>
                                                                                                                                                                        <h4>Soulful </h4>
                                                                                                                                                                        <p>Playlist</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div class="play_0"> <img src="assets/img/icon/read.png"> </div>
                                                                                                                                        </figure>
                                                                                                                        </div>
                                                                                                                  
                                                                                                        </div>
</div>

                                                                                              
                                                                                                         
                                                                                        </div>	
                                        </div>
                                        <div class="col-md-12 text-center mt-4"> <a href="#" class="btn_lg_2">View All </a> </div>
                        </div>
        </div>
</section> -->
<!-- <section>
        <div class="container pt-4 pb-5">
                        <div class="row">
                                        <div class="col-md-12 mb-4 text-center">
                                                        <h5 class="blue">Will you recommend our platform to friends & family?</h5>
                                        </div>
                                        <div class="col-md-12 text-center"> <a href="#" class=" btn_yn mr-2">Yes </a> <a href="#" class=" btn_yn ml-2">No </a> </div>
                        </div>
        </div>
</section> -->

<section>
        <div [routerLink]="['/fresh_talent']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/read.png"> </div>
    </section>