
<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="container"><div class="row"><ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
            <li class="breadcrumb-item"><a [routerLink]="['/read']">&gt; {{'READ'|translate}} </a></li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; Content</li></div></div>
</nav>

<section>
    <div class="embed-responsive embed-responsive-16by9">
<iframe class="embed-responsive-item" height="300px" [src]="read_data.content_link"></iframe>
</div>
</section>

<section id="bg_design">
    <div class="container pt-4 pb-5">
            <div class="row">
                    <div class="col-md-12">
                            <h4 class="blue"><strong>{{read_data.title}}</strong></h4>
                            <p class="blue">{{read_data.information}}</p>
                            <div class="pt-2 addthis_inline_share_toolbox"></div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <a *ngIf="read_data.buy_link != null" class="am_btn" target="_blank" [href]="read_data.buy_link"><span><img src="assets/img/amazon-icon.svg"/></span> <span>Buy on amazon</span></a>
                    </div>
                    
                    <div class="col-md-12 mt-5">
                        <p class="blue"> {{helper.getObjectLength(read_data.comments)}} comments</p>
                    </div>
                <div class="col-md-12">
                        <div *ngFor="let comment of read_data.comments">
                               <p> {{comment.comment}} <br> By: {{comment.first_name}} {{comment.last_name}} <button (click)="deleteComment(comment.id)" *ngIf="user_id == comment.user_id" class="m-2 btn_sm_1">Delete</button></p>                                   
                        </div>
                </div> 

        </div>
        
        <div class="row" *ngIf="!logged_in">
                <div class="col-md-12">
                   <p class="w-100">Please login to continue for comment</p>
                   <a [routerLink]="['/login']" [queryParams]="{ redirectTo: current_url }" class="btn_sm_1">Login</a>
                </div>
              </div>

           <div class="row" *ngIf="logged_in">
                   
              <div class="col-md-12">
               <h5 class="blue" style="width: 100% !important;" *ngIf="comment_posted">Comment Posted!</h5>
               <div class="col-5">
                   <p class="blue">Write a comment</p>
                   <div>
                           <textarea [(ngModel)]="comment" class="form-control w-100" style="border-radius: 0 !important; margin-bottom: 10px;height: unset !important;" rows="4"></textarea><button (click)="postComment()" class="btn btn_sm_1">Submit</button>
                   </div>
               </div>
              </div>

       </div>
    </div>
</section>


<section id="bg_design_2" *ngIf="helper.getObjectLength(related_read)" class="container-fluid slide2 white_slide">
    <div class="container">
            <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center white">{{"Suggested_Content"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                            <owl-carousel class="read_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                <div class="container item"  *ngFor="let one of related_read">
                                        </div></owl-carousel>
                             <div class="content_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                    <div class="container" *ngFor="let one of related_read">
                                                            <div class="row justify-content-center">
                                                                    <!-- item start -->
                                                                    <div id="icon_play" *ngFor="let item of one" class="bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                            <figure><img  [src]="getThumbImg(item)" class="img_br">
                                                                                    <div class="play"> <a [routerLink]="['/read-content']" [queryParams]="{ q: item.slug }"> <img src="assets/img/icon/play.svg"></a> </div>
                                                                            </figure>
                                                                            <h5> {{item.title}}</h5>  
                                                                            <p>{{item.information}}</p>
                                                                            <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                <a [routerLink]="['/read-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                                    <!-- <div class="rating">
                                                                                            <input type="radio" id="star1" name="rating" value="1">
                                                                                            <label for="star1">☆</label>
                                                                                    </div> -->
                                                                            </div>
                                                                    </div>
                                                                 
                                                                     
                                                                     
                                                            </div>
                                                    </div>
                                                 
                                            </div>
                    </div>
            </div>
    </div>
</section>
