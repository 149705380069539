import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
} 
@Component({
  selector: 'app-nomination',
  templateUrl: './nomination.component.html',
  styleUrls: ['./nomination.component.css']
})
export class NominationComponent implements OnInit {

  errors:any = [];
  links:Array<any> = [];
  message_sent:boolean = false;
  selectedFiles:ImageSnippet | undefined;
  profile_pic:ImageSnippet|undefined;
  categories:any = [];
  logged_in:boolean = false;
  message:string = '';
  nominations:any = [];
  country:any = [];
  state:any = [];

  nominationForm = this.formBuilder.group({
    'message':new FormControl("", [Validators.required,Validators.minLength(4)]),
    'name':new FormControl(""),
    'email':new FormControl(""),
    'phone':new FormControl(""),
    'category':new FormControl(""),
    'facebook_link':new FormControl(""),
    'instagram_link':new FormControl(""),
    'youtube_link':new FormControl(""),
    'contest':new FormControl(""),
    'links':new FormControl(""),
    'country':new FormControl("", [Validators.required]),
    'state':new FormControl("", [Validators.required]),
    'city':new FormControl("", []),
    'gender':new FormControl("", []),
    'country_code':new FormControl("", [])
   });

  constructor( private formBuilder:FormBuilder, private router:Router,private tokenService:TokenService,private apiService:ApiService) { }

  ngOnInit(): void {
    this.apiService.getData('/interests').subscribe(
      result => {
       return this.categories = result;
      },
      error => {
      },
      () => {
      }
    )

    this.apiService.getData('/word_country').subscribe(
      result => {
        this.country = result.data.country;
      
      },
      error=>{

      });

    this.apiService.getData('/getNomination').subscribe(
      result => {
        console.log(result);
        this.nominations = result.data;
      },
      error => {
      },
      () => {
      })
    if(this.tokenService.isLoggedIn()){
      this.logged_in = true;
    }
  }

  coutryChanged(event:any){
    this.apiService.getData('/word_state/'+event.target.value).subscribe(
      result => {
        this.state = result.data.state;
      },
      error=>{
      });
  }

  getImg(data:any){
    return this.apiService.storage+'/nomination/profile_pic/'+data.profile_pic;
  }

  sendNomination(){
    this.links = [];
    document.getElementsByName("links[]").forEach((element:any) => {
      this.links.push(element.value);
    });

    this.apiService.postNomination(this.nominationForm.value,this.selectedFiles,this.profile_pic).subscribe((res:any)=>{
      if(res.status){    
        this.router.navigateByUrl('nomination/thank-you');
      }
    },
    (error:any)=>{ 
      this.errors = error.error.errors;
    });
  }

  processProfilePicFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.profile_pic = new ImageSnippet(event.target.result, file);
    });
    reader.readAsDataURL(file);
   }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0]; 
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFiles = new ImageSnippet(event.target.result, file);
    });
    reader.readAsDataURL(file);
   }

}
