<nav aria-label="breadcrumb" id="breadcrumb">
  <div class="col-md-12">
    <div class="row">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
      </ol>
      <li class="breadcrumb-item active" aria-current="page">&gt; {{"ENGAGE"|translate}}</li>
    </div>
  </div>
</nav>

<section>
    <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner9.jpg"> </div>
  </section>
  <!--Banner End-->
  <section id="bg_design" class="comman_slide engage">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="text-center blue"> {{'Quiz'|translate}}</h1>
          <div class="divder"> <img src="assets/img/icon/line_relive.svg"> </div>
        </div>
        <div class="col-md-12">
          <div class="quiz owl-carousel owl-theme owl-loaded owl-drag text-center">
            <!-- item group1 start -->
            <div class="row justify-content-center">
              <div class="col-auto p-0 m-auto" *ngIf="quiz_result == 0">
                <p>Good attempt. Try again later</p>
              </div>
              <div class="col-auto p-0 m-auto" *ngIf="quiz_result == 1">
                <p>Good attempt. Try again later</p>
              </div>
              <div class="col-auto p-0 m-auto" *ngIf="quiz_result == 2">
                <p>Good job! Why don't you give it another try?
                </p>
              </div>
              <div class="col-auto p-0 m-auto" *ngIf="quiz_result == 3">
                <p>Wow! We're impressed with your skills!</p>
              </div>
              <div class="col-auto p-0 m-auto" *ngIf="quiz_result == 4">
                <p>Hurray! You got them all!</p>
              </div>
              
              <div class="col-md-10 p-0 m-auto text-center" *ngIf="quiz_result == -1">
                <form  [formGroup]="quizzForm" (ngSubmit)="submit_quizz()">
                <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery',  'slidng']" [options]="{items: 1, dots: false, nav: false}" [items]="quiz">
                  <div class="form-group" *ngFor="let item of quiz">
                    <h5>“{{item.title}}”</h5>
                    <div class="items-collection">
                      <div class="col-12 col-md-10 p-0 m-auto text-center">
                            <div class="select_engage_main">  
                              <label class="select_engage">
                                <input type="radio" [formControlName]="item.id" autocomplete="off" value="1">
                                <span class="checkmark">{{item.option1}}</span>
                              </label>

                              <label class="select_engage">
                                <input type="radio" [formControlName]="item.id" autocomplete="off" value="2">
                                <span class="checkmark">{{item.option2}}</span>
                             
                              </label>
                              <label class="select_engage">
                              <input type="radio" [formControlName]="item.id" autocomplete="off" value="3">
                              <span class="checkmark">{{item.option3}}</span>
                           
                            </label>
                            <label class="select_engage">
                              <input type="radio"  [formControlName]="item.id"  autocomplete="off" value="4">
                              <span class="checkmark">{{item.option4}}</span>
                           
                            </label>
                        
                          </div>
                       
                      </div>
                    
                    </div>
                  </div>
                  </owl-carousel>
                  <button *ngIf="!show_submit" type="button" class="btn_lg_4"><span (click)="next_q()">{{'Next'|translate}}</span></button>
                  
                  <button *ngIf="show_submit" class="btn_lg_4">{{'Submit'|translate}}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

    <app-fan-messages style="width: 100% !important"></app-fan-messages>    


  <section id="bg_design_3" class="white_slide engage">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="text-center white"> {{'Polls'|translate}}</h1>
          <div class="divder"> <img src="assets/img/icon/white0.svg"> </div>
        </div>
        <form [formGroup]="pollForm" (ngSubmit)="submit_poll()" class="w-100">
        <div class="col-md-12">
           
            <div class="row">

              <div class="col-md-12 m-auto text-center" *ngFor="let item of poll">
                <h5 class="white1">“{{item.title}}”</h5>
                  <div class="form-group">
                    <div class="select_engage_main polls_engage justify-content-center m-auto mt-4">
                      <label class="select_engage">
                        <input  [formControlName]="item.id" type="radio" value="1">
                        <span class="checkmark">{{item.option1}}</span>
                      </label>
                      <label class="select_engage">
                        <input  [formControlName]="item.id" type="radio" value="2">
                        <span class="checkmark">{{item.option2}}</span>
                      </label>
                      </div>

                      <div *ngIf="poll_result" class="select_engage_main polls_engage justify-content-center m-auto mt-4">
                        <label class="select_engage">
                          <span class="white1">{{vote_for_1}} % </span>
                        </label>
                        <label class="select_engage">
                          <span class="white1">{{vote_for_2}} % </span>
                        </label>
                        </div>
                  </div>
                  <button *ngIf="!poll_result" class="btn_lg_4 mt-2" style="color: white;border: solid 1px #fff !important;">Submit</button>
              </div>
             
            
            </div>
            <!-- item group1 end -->
       
        
        </div>
      </form>
      </div>
    </div>
  </section>
  
  <section>
    <div [routerLink]="['/artists']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/engage.png"> </div>
</section>