<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html"> &lt; Back to Home</a></li>
          <li class="breadcrumb-item">You are in:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; Privacy Policy</li>
      </div>
    </div>
  </nav>
  <!-- Listen1 start -->
  <section id="bg_design" class="container-fluid slide2 white_slide">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-center blue">Privacy Policy</h1>
          <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="main">
            <div class="container">
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1"> <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#faq1"                            aria-expanded="true" aria-controls="faq1">Privacy Policy</a> </div>
                  <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                    <div class="card-body">
                      <p>Vishwarang is committed to protecting your personal information and being transparent about the information we hold about you.<br>
                        <br>
                        Our policy explains how we collect and use the information we collect from you directly and from third parties. The policy is reviewed from time to time so please check back for any changes. <br>
                        <br>
                        This website does not automatically capture any specific personal information from you, (like name, phone number or e-mail address), that allows us to identify you individually. <br>
                        <br>
                        If the website requests you to provide personal information, you will be informed for the particular purposes for which the information is gathered e.g. feedback form and adequate security measures will be taken to protect your personal information. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"                            aria-expanded="true" aria-controls="faq2">Introduction and Definitions</a> </div>
                  <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                    <div class="card-body">
                      <p>This Privacy Policy governs the websites and other Services that link to, or contain references to, this document and are published or made available by Rabindranath Tagore University, (“we”, “us” or “our”). Please read this Privacy Policy carefully. It summarizes the various ways we acquire and treat your Data when you access, visit and/or use the Service. It also governs your Data that we may acquire offline. This Privacy Policy does not cover information collected on sites, applications, destinations, or services linked to from the Service that we do not own or control.<br>
                        <br>
                        You can access this Privacy Policy any time in our home page, or as otherwise indicated depending on the Service you are using. By registering for any aspect of the Service, or otherwise accessing, visiting or using the Service, you consent to this Privacy Policy. If you do not agree with the terms and conditions of this Privacy Policy, you should not access, visit and/or use the Service. We advise that you print or retain a digital copy of this Privacy Policy for future reference. <br>
                        <br>
                        In addition to reviewing this Privacy Policy, please also review our User Agreement and any other terms and conditions that may be posted elsewhere in the Service or otherwise communicated to our users, because the User Agreement and all such terms and conditions are also part of the Agreement between you and us. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"                            aria-expanded="true" aria-controls="faq3">Types of data acquired by you</a> </div>
                  <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong>Personally Identifiable Information (PII).</strong> <br>
                        We acquire PII that may include, in certain contexts, your name, postal address, zip code, email address, and telephone number. We may also acquire your IP address, User ID, and/or Device ID, which certain jurisdictions consider to be PII because it could be used to identify an individual or Device if it were combined with other identifying information. <br>
                        <br>
                        <strong>Sensitive PII. </strong><br>
                        In certain circumstances, you may provide a credit, debit, or payment account number, or other payment information which we recognize as more sensitive than other PII. We generally do not request on or through the Service other Data that is often considered “highly sensitive,” such as other financial account information (e.g., credit report information, bank account numbers), personal health information, or government issued identification numbers (e.g., Aadhar number, drivers’ license number, or passport number), although we reserve the right to do so when such Data is necessary to offer you certain services. <br>
                        <br>
                        <strong>Anonymous Data.</strong><br>
                        Some of the Data that we acquire cannot identify, distinguish or trace you or your Device, even if combined with other identifying information, and some Data that could be considered PII when combined with other identifying information is not used in a way that identifies, distinguishes or traces you or your Device, but is instead used in an anonymous way, often aggregated with other anonymous Data about other users. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead4"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"                            aria-expanded="true" aria-controls="faq4">How we acquire your data</a> </div>
                  <div id="faq4" class="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong>Data You Provide.</strong><br>
                        We mostly receive PII because you provide it to us. For instance, when you enter a contest, complete a survey, or register and/or set up an account/profile, you may be provided, or required to choose, a password and/or User ID, as well as your name, telephone number(s), email and/or street address. Other Data such as your age, gender, an avatar, and preferences may also be requested. In addition, we may acquire your Data when you contact us by telephone, social media interaction or messaging (e.g., email, SMS, MMS, or similar technologies). <br>
                        <br>
                        <strong>Data Automatically Collected.</strong><br>
                        The Service may also automatically collect Data about you, your online behaviour and your Device. The Data collected may include, without limitation, the make, model, settings, specifications (e.g., CPU speed, connection speed, browser type, operating system, device identifier) and geographic location of you and/or your Device, as well as date/time stamp, IP address, pages visited, time of visits, Content viewed, ads viewed, the site(s), application(s), destination(s), and/or service(s) you arrived from, and other clickstream data. The Service may collect your Data even if the Service is not open on your Device or you’re not logged in. <br>
                        <br>
                        <strong>Social Network Integration.</strong><br>
                        If you choose to access, visit and/or use any third party social networking service(s) that may be integrated with the Service, we may receive your Data that has been made available to those services, including information about your contacts on those services. For example, some social networking services allow you to push Content from our Service to your contacts or to pull information about your contacts so you can connect with them on or through our Service. Some social networking services also will facilitate your registration for our Service or enhance or personalize your experience on our Service. Your decision to use a social networking service in connection with our Service is voluntary. However, you should make sure you are comfortable with sharing your Data with third party social networking services that may make it available to us by visiting those services’ privacy policies and/or modifying your privacy settings directly with those services. We reserve the right to use, transfer, assign, sell, share, and provide access to all of your Data that we receive through third-party social networking services in the same ways as all of your Data we receive through our Service (as described in Section IV below). <br>
                        <br>
                        <strong>Be Careful Publicly Posting PII.</strong><br>
                        Please be aware that Content and PII that you disclose in publicly accessible portions of the Service may be available to other users, and may also be made available outside the Service by third parties, so you should be mindful of all PII, especially sensitive PII, that you may wish to post. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead5"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"                            aria-expanded="true" aria-controls="faq5">How we use your data</a> </div>
                  <div id="faq5" class="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong>Administering the Service.</strong><br>
                        We use your Data for any lawful business purpose in connection with administering the Service that we offer, including without limitation for customer service, to help diagnose problems with servers, to gather broad demographic information, to analyse trends, to seek compatible advertisers, sponsors, clients and customers, and to track users’ movements around the Service and elsewhere on the web or across apps and/ devices. Your geographic location Data may specifically be used to show you Content (including advertising and sponsored messaging) based on geographic location.<br>
                        <br>
                        <strong>Access by Third Party Providers.</strong><br>
                        We may also allow access to your Data by Third Party Providers that provide us with services, such as technical maintenance, market research, community and forums management, auction services, e-commerce, personal/job search and other advertising functionality, but only for the purpose of and to the extent necessary to provide those services. There are also times when you provide Data to us in areas of the Service that may be managed or participated in by one or more Third Party Providers. In such cases, the Data may be used by us and by such Third Party Provider(s), each pursuant to its own policies. While we may seek to require Third Party Providers to follow appropriate privacy policies and will not authorize them to use your Data except for the express purpose for which it is provided, we do not bear any responsibility for any actions or policies of third parties. <br>
                        <br>
                        <strong>Marketing Communications.</strong><br>
                        We may also use your Data to communicate with you about new features or events, Third Party Providers, our advertisers, and other companies and individuals with which we have a relationship.<br>
                        <br>
                        <strong>Health, Safety, and Legal Requests. </strong><br>
                        We reserve the right to access, use, and share with others your Data for purposes of health, safety and other matters in the public interest. We may also provide access to your Data in order to cooperate with official investigations or legal proceedings brought by or otherwise involving governmental and/or law enforcement officials, as well as private parties, including, for example, in response to search warrants, court orders, or other legal process. We may also provide access to protect our rights and property and those of our agents, customers, and others including to enforce our agreements, policies, and terms of use. <br>
                        <br>
                        <strong>Transfer or Sale of Our Business. </strong><br>
                        As our business changes, we may buy or sell various assets owned or controlled by us. In the event all or a portion of the assets are sold, assigned, transferred or acquired by another company due to merger, divestiture, restructuring, reorganization, dissolution, financing, acquisition, bankruptcy or otherwise, your Data may be among the transferred assets. <br>
                        <br>
                        <strong>Combining Your Anonymous Data with PII. </strong><br>
                        We reserve the right to merge or co-mingle anonymous, non-personally identifiable Data about you, your offline and online behaviour, and/or your Device (including its geographic location), with your PII for any lawful business purpose. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead6"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"                            aria-expanded="true" aria-controls="faq6">Sharing your data</a> </div>
                  <div id="faq6" class="collapse" aria-labelledby="faqhead6" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong>Credit/Debit Card and Payment Account Data. </strong><br>
                        If provided, we will not sell or rent your credit/debit card number or payment account Data. We may transfer such Data to Third Party Providers who need access to fulfil a transaction.<br>
                        <br>
                        <strong>Sharing Your Data for Marketing. </strong><br>
                        Unless otherwise specified on the Service, we may sell or share your Data among our parent, subsidiaries, and affiliates and with other third parties who we think may offer or enable you to become aware of information that may be of interest to you.<br>
                        <br>
                        <strong>How to Opt-Out of Sharing for Marketing. </strong><br>
                        If you do not wish to have PII such as your name, email address, postal address or phone number shared with third parties for their marketing purposes, contact our info@vishwarang.com as described at the end of this document. But note that, in order for us to properly administer the Service, we must continue to share your Data with Third Party Providers as described in Section 4(3) above (if you object to such sharing, you should not use the Service). Note also that this option to opt-out applies only to PII, and not Anonymous Data.<br>
                        <br>
                        <strong>How to Opt-Out of Marketing Messages from Us. </strong><br>
                        If you do not wish to receive future marketing or other commercial messages from us, simply follow the unsubscribe instructions contained within the message you receive. But note that you may continue to receive certain communications from us that are necessary for the Service, such as renewal notifications, invoices, technical notices, updates, security alerts, and support and administrative service bulletins.<br>
                        <br>
                        <strong>How to Update Your PII. </strong><br>
                        If you have registered on any part of the Service, please use the mechanism or contact information on the Service that allows you to change or update your member preferences, if available, to keep all such Data accurate and up-to-date. If no such mechanism or contact information is available on the Service, contact our info@vishwarang.com as described below with your changes. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead7"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq7"                            aria-expanded="true" aria-controls="faq7">Copyright Policy</a> </div>
                  <div id="faq7" class="collapse" aria-labelledby="faqhead7" data-parent="#faq">
                    <div class="card-body">
                      <p> Except for User Content (as defined below), all content made available through our Website, including images, designs, templates, text, graphics, images, video, information, software, audio and other files, and their selection and arrangement, and all software used to provide the Services (collectively " Content"), is our property or its licensors. No Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, sold or exploited for any purpose in any form or by any means, in whole or in part, other than as expressly permitted in this Agreement. You may not, directly or indirectly, reverse engineer, decompile, disassemble or otherwise attempt to derive source code or other trade secrets from any Content. <br>
                        <br>
                        Any use of the Content other than as specifically authorized herein is prohibited and will automatically terminate your rights with respect to your use of the Services. All our rights that are not expressly granted in this Agreement are reserved by us and our licensors.<br>
                        <br>
                        <strong>Further, you are prohibited from:</strong><br>
                        Posting User Content that is copyrighted, protected by trade secret or otherwise subject to any third-party intellectual property rights or proprietary rights, including any privacy and publicity rights, unless you are the owner of such rights or have written permission from the rightful owner of such rights to post such content;
                        Uploading, posting, or otherwise transmitting any User Content that is (a) unlawful, harmful, threatening, abusive, harassing, degrading, tortious, libelous, slanderous or otherwise defamatory, vulgar, obscene, pornographic, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable or harmful to minors, or (a) contains computer viruses or any other computer code, files, scripts, macros, or programs designed to alter, interrupt, destroy, or limit the operation of, or infiltrate any computer software, hardware, or computer systems or any data run through such computer system;<br>
                        <br>
                        Uploading, posting, emailing, or otherwise transmitting any unsolicited or unauthorized advertising, promotional materials, “junk mail”, “spam”, “chain letters”, “pyramid schemes,” or any other form of solicitation;<br>
                        <br>
                        Including links to external, services that compete with us, including without limitation, items for sale on your own website; and
                        Collecting or storing personal data about other users.<br>
                        <br>
                        We do not exercise editorial or other control over User Content and do not guarantee the accuracy, integrity, or quality of such User Content. Under no circumstances will we be liable in any way for any User Content, including, but not limited to, liability for any errors or omissions in any User Content or for any loss or damage of any kind incurred as a result of the use of any User Content posted, emailed, or otherwise transmitted via the Support Forum. You shall be solely liable for any damages resulting from any violation of this section, or any other harm resulting from your posting of User Content to our Support Forum. You acknowledge that we may exercise its rights to delete any User Content you submit at any time and for any reason, without notice to you.<br>
                        <br>
                        We do not pre-screen or review any User Content but reserves the right to refuse or delete any User Content that it finds inappropriate for any reason and without notice.
                        
                        Further, the material featured on this website may be reproduced free of charge after taking proper permission by sending a mail to us. Wherever the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorisation to reproduce such material must be obtained from the departments/copyright holders concerned. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead8"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq8"                            aria-expanded="true" aria-controls="faq8">Hyper Linking Policy</a> </div>
                  <div id="faq8" class="collapse" aria-labelledby="faqhead8" data-parent="#faq">
                    <div class="card-body">
                      <p> <strong> Links to external websites/portals.</strong><br>
                        At many places in this website, you shall find links to other websites/portals. These links have been placed for your convenience. We cannot guarantee that these links will work all the time and we have no control over availability of linked pages. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead4"> <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq9"                            aria-expanded="true" aria-controls="faq9">Archival Policy</a> </div>
                  <div id="faq9" class="collapse" aria-labelledby="faqhead9" data-parent="#faq">
                    <div class="card-body">
                      <p> The content published in the Vishwarang Website are generic in nature, having no specific life (Time To Leave). Hence are always live and can be accessible through the website. However, content published under the sections like Events, and Announcements have a life span and will automatically be moved to the online archival section after the assigned end date (displayed along with each content item). </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Listen1 End -->
  <!-- The path to art begins here. Start -->
  <section id="artist_bg">
    <div class="container-fluid design_corner">
      <div class="row flex-nowrap">
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
      </div>
    </div>
    <div class="container">
      <div class="row text_text flex-nowrap">
        <div class="col-md-5 col-3 text-center"> </div>
        <div class="col-md-7 col-9 text-center">
          <h1 class="white">The path to art begins here.</h1>
          <h4 class="white">Share the details of your favourite new artist below </h4>
          <a href="#" class="btn_lg_5"> UPLOAD</a> </div>
      </div>
    </div>
    <div class="container-fluid design_corner">
      <div class="row flex-nowrap">
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
      </div>
    </div>
  </section>
  <!-- The path to art begins here. End -->