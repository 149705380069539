import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ArtistPageComponent } from './pages/artist-page/artist-page.component';
import { ArtistsComponent } from './pages/artists/artists.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './components/register/register.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupTwoComponent } from './components/signup-two/signup-two.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ListenComponent } from './pages/listen/listen.component';
import { WatchDetailComponent } from './pages/watch-detail/watch-detail.component';
import { ReadComponent } from './pages/read/read.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { WatchComponent } from './pages/watch/watch.component';
import { ListenInfoComponent } from './pages/listen-info/listen-info.component';
import { ReadInfoComponent } from './pages/read-info/read-info.component';
import { AttentComponent } from './pages/attent/attent.component';
import { ReliveComponent } from './pages/relive/relive.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { PressComponent } from './pages/press/press.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { TermsComponent } from './static/pages/terms/terms.component';
import { PrivacyPolicyComponent } from './static/pages/privacy-policy/privacy-policy.component';
import { PartnerCountryComponent } from './pages/partner-country/partner-country.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NominationComponent } from './pages/nomination/nomination.component';
import { FreshTalentComponent } from './pages/fresh-talent/fresh-talent.component';
import { EngageComponent } from './pages/engage/engage.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { PlaylistComponent } from './pages/playlist/playlist.component';
import { FaqComponent } from './static/pages/faq/faq.component';
import { CategoryComponent } from './pages/category/category.component';
import { ThankYouComponent } from './static/pages/thank-you/thank-you.component';
import { ExhibitionSliderComponent } from './pages/exhibition-slider/exhibition-slider.component';
import { PaintingComponent } from './pages/painting/painting.component';
import { PaintingDetailComponent } from './pages/painting-detail/painting-detail.component';
import { PaintingCategoryComponent } from './pages/painting-category/painting-category.component';
import { PaintingItemComponent } from './pages/painting-item/painting-item.component';

const routes: Routes = [
  {path:'attend',component:AttentComponent},
  {path:'awards',component:AwardsComponent},
  {path:'register',component:RegisterComponent},
  {path: 'register-step-2', component: SignupTwoComponent },
  {path: 'modify-interest', component: SignupTwoComponent },
  {path:'team',component:PartnerCountryComponent},
  {path: 'login', component: SigninComponent },
  {path:'forget-password',component:ForgetPasswordComponent},
  {path: 'relive', component: ReliveComponent },
  {path: 'profile', component: UserProfileComponent, canActivate: [AuthGuardService]},
  {path: 'watch', component: WatchComponent },
  {path: 'watch-content', component: WatchDetailComponent },
  {path: 'listen', component: ListenComponent },
  {path: 'listen/info', component: ListenInfoComponent },
  {path: 'press', component: PressComponent },
  {path: 'read', component: ReadComponent },
  {path: 'read/info', component: ReadInfoComponent },
  {path: 'artists', component: ArtistsComponent },
  {path: 'artists/:slug', component: ArtistPageComponent },
  {path: 'terms', component: TermsComponent },
  {path: 'privacy-policy', component: PrivacyPolicyComponent },
  {path: 'faq', component: FaqComponent },
  {path:'about_us',component:AboutUsComponent},
  {path:'coming_soon',component:ComingSoonComponent},
  {path:'nomination',component:NominationComponent}, 
  {path:'category',component:CategoryComponent},
  {path:'engage',component:EngageComponent},
  {path:'playlist',component:PlaylistComponent},
  {path:'fresh_talent',component:FreshTalentComponent},
  {path:'nomination/thank-you',component:ThankYouComponent},
  // {path:'painting',component:ExhibitionSliderComponent},
  {path:'painting-detail/:id',component:PaintingDetailComponent},
  {path: 'paintings', component: PaintingComponent },
  {path: 'painting-category', component: PaintingCategoryComponent },
  {path: 'painting-item', component: PaintingItemComponent },
  {path: '', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload',preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})

export class AppRoutingModule { }