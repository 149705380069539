import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { InitAppService } from './init-app.service';

@Injectable({
  providedIn: 'root'
})
export class WatchService {

  img_url = '';

  constructor(private apiService:ApiService,private initApp:InitAppService) { 
    this.img_url = this.apiService.storage+'/project/images/watch/';
  }

  getList(filter:any){
    return new Promise(resolve => {
    var list:any = [];
    this.apiService.getData('/watch?'+filter.join('&')).subscribe(
      result => {
       list = this.apiService.chunk(result.data.data,8);
       resolve(list);
      },
      error => {
      },
      () => {
      }
    )});
  }

  getImg(watch:any){
    try{
      return this.img_url + watch.thumb.project_id+'/'+watch.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  getMediaImg(item: any) {
    try{
      return this.apiService.storage+'/project/images/media/' + item.project_id+'/'+item.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  switchFav(item:any){
    
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  getPastYearsEvents(){
    return new Promise(resolve=>{
      var list:any = [];
    this.apiService.getData('/years-events/watch').subscribe(
      result => {
        resolve(result);
      },
      error => {},
      () => {})
    });
  }

  FreshTalentWatch(chunk_size:number){
    return new Promise(resolve=>{
    this.apiService.getData('/watch/fresh-artist?featured=1').subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {},
      () => {})
    });
  }

  getFeatured(chunk_size:number){
    return new Promise(resolve=>{
    this.apiService.getData('/watch?featured=1').subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {},
      () => {})
    });
  }
  getWatchByCategory(category:number){
    return new Promise(resolve=>{
    this.apiService.getData('/watch?category='+category).subscribe(
      result => {
        resolve(result);
      },
      error => {},
      () => {})
    });
  }
  
  getFeaturedCountry(chunk_size:number,country_id:number){
    return new Promise(resolve=>{
    this.apiService.getData('/watch?country='+country_id).subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {},
      () => {})
    });
  }

}