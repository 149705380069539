import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private apiService:ApiService) { }

  search(word:string){
    return new Promise(resolve=>{
    this.apiService.getData('/search?search='+word).subscribe(
      result => {
        resolve(result);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
   });
  }
}
