import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { TokenService } from 'src/app/shared/token.service';
import { InitAppService } from '../../services/init-app.service';
import { ApiService } from '../../shared/api.service';

@Component({
  selector: 'app-read-info',
  templateUrl: './read-info.component.html',
  styleUrls: ['./read-info.component.css']
})
export class ReadInfoComponent implements OnInit {
 
  read:string="";
  read_data:any = [];
  related_read:any = [];
  comment:string = "";
  user_id = localStorage.getItem('user_id');
  comments:any = [];
  logged_in = false;
  comment_posted = false;
  img_url:string;
  current_url:string = "";
  pdf:string;
  constructor(private router:Router,private tokenService:TokenService,public helper:HelperService,private activatedRoute:ActivatedRoute,private sanitizer:DomSanitizer,private apiService:ApiService,private initApp:InitAppService) { 
    this.activatedRoute.queryParams.subscribe(params => {
    
      this.read = params['q'];
      }); 

      this.current_url = router.url;
      this.img_url = this.apiService.storage+'/project/images/read/';
      this.pdf = this.apiService.storage+'/pdfs/';
      
    }
  
    ngOnInit(): void {
      if(this.tokenService.isLoggedIn()) {
        this.logged_in = true;
       }
       this.initApp.addThis();
    }
  
    getImg(){
      try{
        return this.img_url + this.read_data.thumb.project_id+'/'+this.read_data.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }

    getThumbImg(read:any){
      try{
        
        return this.img_url + read.thumb.project_id+'/'+read.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }
  
    
    
  getread(){
    // console.log(this.read_data);
    this.apiService.getData('/read?artist='+this.read_data.artist+'&not_in='+this.read_data.id).subscribe(
      result => {
        // console.log(result.data);
        this.related_read = this.apiService.chunk(result.data.data,4);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']); 
      }
    )
  }

  linkURL(url:string){
    // this.read_data.pdf_file =  this.sanitizer.bypassSecurityTrustResourceUrl(this.read_data.pdf_file);
  }

    ngAfterViewInit() {
      this.loadInfo();

      var t = this;
      setTimeout(function(){
        t.apiService.postData('/read/push/trending/'+t.read,[]).subscribe(
          result => {},
          error => {},
          () => {})
      },8000);
      this.initApp.initApp();
    }


    postComment(){
      this.comment_posted = true;
      let data = {'key':this.read,'comment':this.comment};
      this.apiService.postData('/comment/read',data).subscribe((res:any)=>{
        this.comment = '';
        this.comment_posted = true;
        this.loadInfo();
      },
      (error:any)=>{
      });  
    }

deleteComment(id:string){
  this.apiService.postData('/comment_delete',{id:id}).subscribe((res:any)=>{
    this.loadInfo();
  },
  (error:any)=>{
  });  
}

loadInfo(){
  this.apiService.getData('/read/info/'+this.read).subscribe(
    result => {
      if(result.data === null){
        this.apiService.navigate('/read');
      }
     this.read_data = result.data;
     this.getread();
     this.read_data.content_link =  this.sanitizer.bypassSecurityTrustResourceUrl(this.pdf+this.read_data.pdf_file);
    },
    error => {
    },
    () => {
    }
  )
}
  
}
