import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from 'src/environments/environment'; 
import { NgAddToCalendarService, ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-attent',
  templateUrl: './attent.component.html',
  styleUrls: ['./attent.component.css']
})

export class AttentComponent implements OnInit {

  events:any = [];
  next_page_url = null;
  popup_item:any= {category:{}};
  not_loaded = false;
  google_calender:any = '';
  i_calender:any = '';
  yahoo_calender:any = '';

  constructor(private apiService:ApiService,public helper:HelperService,private _addToCalendarService: NgAddToCalendarService,
    private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    
    this.loadEvents();
  }

  popUp(item:any){
    this.popup_item = item;
  }
  loadEvents(){
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June","July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.apiService.getData('/v_event').subscribe(res=>{
      this.not_loaded = true;
      this.next_page_url= res.next_page_url;
      this.events = res.data;
      let t = this;
      this.events.forEach(function (value:any, i:number) {
        console.log( i, value);
        t.events[i]['calender'] = t.getCalender(value);
        let s = monthNames[new Date(value.start_date).getMonth()]+' '+new Date(value.start_date).getDate();
        let e =  monthNames[new Date(value.end_date).getMonth()]+' '+new Date(value.end_date).getDate();
        t.events[i]['date_line'] = s +' - '+ e;
        if(s == e){
          t.events[i]['date_line'] = s;
        }
        try{
          t.events[i]['time_line'] = t.tConvert(value.start_time)+'-'+t.tConvert(value.end_time);
        }
        catch(e){
          console.log(e);
        }
      });
    },
    error=>{

    })
  }

  tConvert (time:any) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    time[4] = '';
    console.log(time);
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  getCalender(event:any){
   
  let newEvent = {
      // Event title
      title: event.name,
      // Event start date
      start: new Date('June 15, 2013 19:00'),
      // Event duration (IN MINUTES)
      duration: 120,
      // If an end time is set, this will take precedence over duration (optional)
      end: new Date('June 15, 2013 23:00'),
      // Event Address (optional)
      address: event.location,
      // Event Description (optional)
      description: event.description
  };
  
 this.google_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.google, newEvent));
 this.i_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.iCalendar, newEvent));
 this.yahoo_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.yahoo, newEvent));
  console.log(event);
  }


  loadMore(){

    let  url:any = this.next_page_url;
    this.apiService.geturlData(url).subscribe(
      result => {
        this.events = this.events.concat(result.data);
        let t = this;
        this.events.forEach(function (value:any, i:number) {
          t.events[i]['calender'] = t.getCalender(value);
      });
        this.next_page_url = result.next_page_url;
      },
      error => {
      },
      () => {
      }
    )
  }

  return_cover(){
  let data = this.popup_item;
    try{
      return this.apiService.storage+"/project/images/v_event/"+data.id+"/"+data.cover.path;
    }
    catch(e){
      return null;
    } 
  }

  return_thumb(data:any){

try{
  return this.apiService.storage+"/project/images/thumbnail/category/"+data.category.id+"/"+data.category.thumb.path;

}
catch(e){
  return null;
}
  }

}
