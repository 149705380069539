

<section id="bg_design_2" class="media slide2 white_slide">
    <div class="container">           
            <div class="row text-center">
                    <div class="col-md-12 p-0">                    
                            <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade active show" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                        	<div class="row">
                                                    <div class="col-md-9 p-0 m-auto">
                                                            <div class="row">
                                                              
                                                                    <div class="col-md-6 margin_bottom" *ngFor="let list of category;let i=index">
                                                          
                                                                        <h5 class="text-center blue"><span class="dark_grey">
                                                                                {{list.title}}
                                                                          </span> 
                                                                            </h5>
                                                                            <div id="icon_play" class="bg-white box_shadow ">
                                                                                   
                                                                                 <img src="{{url}}/{{list.id}}/{{list.painting_category_image?.path}}" class="example-image" alt="{{list.title}}" />
                                                                                    <a routerLink="/painting-detail/{{list.id}}" class="view-more">View More</a>
                                                                            </div>
                                                                    </div>
                                                        
                                                                    <!-- Item End -->
                                                          
                                                            </div>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">Listen</div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">Read</div>
                            </div>
                    </div>
            </div>
    </div>
</section>


