<section id="bg_design_2" class="media slide2 white_slide">
        <div class="container">
                <div class="row text-center">
                        <div class="col-md-12 p-0">
                                <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade active show" id="watch" role="tabpanel"
                                                aria-labelledby="watch-tab">

                                                <div class="row">
                                                        <div class="col-md-12">
                                                                <!-- <div class="circle-box-main">
                                                                        <div class="share-button"></div>
                                                                        <div class="circle first-circle">
                                                                                <a href="https://api.whatsapp.com/send?text={{urls}}/{{this.paintingId}}"
                                                                                        data-action="share/whatsapp/share"
                                                                                        target="_blank"
                                                                                        class="button"><i
                                                                                                class="fa fa-whatsapp"></i></a>
                                                                        </div>
                                                                        <div class="circle second-circle">
                                                                                <a href="https://twitter.com/intent/tweet?text={{urls}}/{{this.paintingId}}"
                                                                                        class="button"></a>
                                                                        </div>
                                                                        <div class="circle third-circle">
                                                                                <a href="https://www.facebook.com/sharer/sharer.php?u={{urls}}/{{this.paintingId}}"
                                                                                        class="button"></a>
                                                                        </div>
                                                                        <div class="circle forth-circle">
                                                                                <a href="https://www.linkedin.com/shareArticle?mini=true&url={{urls}}/{{this.paintingId}}"
                                                                                        class="button"></a>
                                                                        </div>
                                                                        <div class="circle fifth-circle">
                                                                                <a href="mailto:?" class="button"></a>
                                                                        </div>
                                                                </div> -->
                                                                
                                                                <div class="shareit circle-box-main">
                                                                        <!-- facebook -->
                                                                        <!-- <a  class="facebook" href="https://www.facebook.com/sharer/sharer.php?u={{urls}}/{{this.paintingId}}" target="blank" title="facebook"> <i class="fa fa-facebook" aria-hidden="true"></i>
                                                                        </a> -->
                                                                      <!-- whatsaap -->
                                                                      <a class="whatsapp" href="https://api.whatsapp.com/send?text={{urls}}/{{this.paintingId}}" data-action="share/whatsapp/share"target="_blank"title="whatsapp"><i
                                                                        class="fa fa-whatsapp"></i></a>


                                                                        <!-- twitter -->
                                                                        <!-- <a class="twitter" href="https://twitter.com/intent/tweet?text=YOUR_TITLE&url={{urls}}/{{this.paintingId}}" target="blank" title="twitter"><i class="fa fa-twitter"></i></a> -->
                                                                      
                                                                        <!-- linkedin -->
                                                                        <!-- <a class="linkedin" href="https://www.linkedin.com/shareArticle?mini=true&url=YOUR_URL&title={{urls}}/{{this.paintingId}}" target="blank" title="linkedin"><i class="fa fa-linkedin"></i></a> -->
                                                                  
                                                                
                                                                      </div>
                                                        </div>
                                                        <div class="col-md-9 p-0 m-auto painting-detail-main">
                                                                <div class="row">
                                                                        <!-- Item Start -->

                                                                        <div class="col-md-6 margin_bottom"
                                                                                *ngFor="let paintingitem of painting;let i=index">

                                                                                <h5 class="text-center blue">
                                                                                        <div id="icon_play"
                                                                                        class="bg-white box_shadow detail-main">
                                                                                        <a class="example-image-link"
                                                                                                href="{{url}}/{{paintingitem.id}}/{{paintingitem.painting_item_image?.path}}"
                                                                                                data-fancybox="gallery">
                                                                                                <img src="{{url}}/{{paintingitem.id}}/{{paintingitem.painting_item_image?.path}}"
                                                                                                        class="example-image"
                                                                                                        alt="{{paintingitem.name}}" /></a>
                                                                                                        <a  class="view-more text-capitalize">{{paintingitem.name}}</a>

                                                                                        </div> 
                                                                                </h5>
                                                                             
                                                                        </div>

                                                                        <!-- Item End -->
                                                                </div>
                                                        </div>

                                                </div>
                                                <!-- Button trigger modal -->
                                        </div>
                                        <div class="tab-pane fade" id="listen" role="tabpanel"
                                                aria-labelledby="listen-tab">Listen</div>
                                        <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">
                                                Read</div>
                                </div>
                        </div>
                </div>
        </div>
</section>