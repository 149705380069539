import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { ApiService } from 'src/app/shared/api.service';
import { InitAppService } from '../init-app.service';

@Injectable({
  providedIn: 'root'
})
export class ReadService {
  img_url = '';

  constructor(private apiService:ApiService,private initApp:InitAppService) { 
    this.img_url = this.apiService.storage+'/project/images/read/';
  }

  getList(filter:any){
    return new Promise(resolve => {
    var list:any = [];
    this.apiService.getData('/read?'+filter.join('&')).subscribe(
      result => {
       list = this.apiService.chunk(result.data.data,8);
       resolve(list);
      },
      error => {
      },
      () => {
      }
    )});
  }

  getImg(read:any){
    try{
      return this.img_url + read.thumb.project_id+'/'+read.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  switchFav(item:any){
    
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  getPastYearsEvents(){
    return new Promise(resolve=>{
      var list:any = [];
    this.apiService.getData('/years-events/read').subscribe(
      result => {
        resolve(result);
      },
      error => {},
      () => {})
    });
  }

  FreshTalentRead(chunk_size:number){
    return new Promise(resolve=>{
    this.apiService.getData('/read/fresh-artist?featured=1').subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
   });
  }

  getFeatured(chunk_size:number){
    return new Promise(resolve=>{
    this.apiService.getData('/read?featured=1').subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
   });
  }

  getReadByCategory(category:number){
    return new Promise(resolve=>{
    this.apiService.getData('/read?category='+category).subscribe(
      result => {
        resolve(result);
      },
      error => {},
      () => {})
    });
  }

  getFeaturedCountry(chunk_size:number,country_id:number){
    return new Promise(resolve=>{
    this.apiService.getData('/read?country='+country_id).subscribe(
      result => {
        resolve(this.apiService.chunk(result.data.data,chunk_size));
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
   });
  }

}
