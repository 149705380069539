
<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="container">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
          <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt;  {{'ABOUT_US'|translate}}</li>
      </div>
    </div>
  </nav>
  <!--Banner -->
  <section>
    <div> <img src="assets/img/banner/{{'lang'|translate}}/banner7.jpg"> </div>
  </section>
  <!--Banner End-->
  <section id="bg_design" class="container-fluid slide2 pt-5 pb-5">
      <div class="bg_number">
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-0">
           
          <ul class="nav nav-tabs mb-4" id="comman_0_tab" role="tablist">
            <li class="nav-item" role="presentation"> <a class="nav-link white active mb-2" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="false">{{"OUR_STORY"|translate}}</a> </li>
            <li class="nav-item" role="presentation"> <a class="nav-link white  mb-2" id="host-tab" data-toggle="tab" href="#host" role="tab" aria-controls="host" aria-selected="false">{{'HOSTCITY'|translate}}</a> </li>
            <li class="nav-item" role="presentation"> <a class="nav-link white  mb-2" id="university-tab" data-toggle="tab" href="#university" role="tab" aria-controls="university" aria-selected="false">{{'HostUniversity'|translate}}</a> </li>
          </ul>
          <div class="tab-content col-md-10 col-12 m-auto" id="myTabContent">
            <!-- watch slide start-->
            <div class="tab-pane fade show active blue text-center" id="about" role="tabpanel" aria-labelledby="about-tab">
  
   <div id="summary">
          <div class="collapse more" id="collapseSummary">
          {{"ABOUT_LINE_1"|translate}} <br> <br>
  
  
          {{"ABOUT_LINE_2"|translate}}  <br> <br>
  
          {{"ABOUT_LINE_3"|translate}}  <br> <br>
  
          {{"ABOUT_LINE_4"|translate}}  <br> <br>
  
          {{"ABOUT_LINE_5"|translate}}   
          </div>
          <div class="myclass" data-value="PLAY"></div>



          <a class="collapsed" attr.data-read_more="{{'READ_MORE'|translate}}" attr.data-read_less="{{'READ_LESS'|translate}}" data-toggle="collapse" href="#collapseSummary" aria-expanded="false" aria-controls="collapseSummary"></a>
        </div>

            </div>
            <!-- watch slide end -->
            <div class="tab-pane fade text-center blue" id="host" role="tabpanel" aria-labelledby="host-tab">

              <div id="summary">
          <div class="collapse more" id="collapseSummary">
            {{"HOST_CITY_1"|translate}} <br> <br>
  
            {{"HOST_CITY_2"|translate}}  <br> <br>
            {{"HOST_CITY_3"|translate}}  <br> <br>
            {{"HOST_CITY_4"|translate}} 
          </div>
          <a class="collapsed" attr.data-read_more="{{'READ_MORE'|translate}}" attr.data-read_less="{{'READ_LESS'|translate}}"  data-toggle="collapse" href="#collapseSummary" aria-expanded="false" aria-controls="collapseSummary"></a>
        </div>  
                
               
  </div>
            <div class="tab-pane fade blue text-center" id="university" role="tabpanel" aria-labelledby="university-tab">
                
                
                <div id="summary">
          <div class="collapse more" id="collapseSummary">
          
                
                
                "{{"HOST_U_CITY_1"|translate}}  <br> <br>
  
                {{"HOST_U_CITY_2"|translate}} 
  <br> <br>
  {{"HOST_U_CITY_3"|translate}} 
  <br> <br>
  <strong>{{"HOST_U_CITY_4"|translate}} </strong> <br>  
  {{"HOST_U_CITY_5"|translate}} <br> <br> 
  {{"HOST_U_CITY_6"|translate}} <br> <br> 
  {{"HOST_U_CITY_7"|translate}} <br> <br> 
  {{"HOST_U_CITY_8"|translate}} <br> <br> 
  {{"HOST_U_CITY_9"|translate}} <br> <br> 
  {{"HOST_U_CITY_10"|translate}} <br> <br> 
  {{"HOST_U_CITY_11"|translate}} <br> <br> 
  {{"HOST_U_CITY_12"|translate}} <br> <br> 
  {{"HOST_U_CITY_13"|translate}} <br> <br> 
  {{"HOST_U_CITY_14"|translate}} <br> <br> 
  {{"HOST_U_CITY_15"|translate}}  <br> <br> 
  {{"HOST_U_CITY_16"|translate}}  <br> <br> 
  {{"HOST_U_CITY_17"|translate}}  <br> <br> 
  
  {{"HOST_U_CITY_8"|translate}} "
          </div>
          <a class="collapsed" attr.data-read_more="{{'READ_MORE'|translate}}" attr.data-read_less="{{'READ_LESS'|translate}}"  data-toggle="collapse" href="#collapseSummary" aria-expanded="false" aria-controls="collapseSummary"></a>
        </div>  
                
                
                
                
                
                
                
                
                
                
                
              
  </div>
          </div>
        </div>
      </div>
    </div>
          </div>
  </section>
  <!-- slide2 start -->
  <section id="bg_design_2">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-center white">{{'TheTeam'|translate}}</h1>
          <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
        </div>
        <div class="col-md-12 p-0">
          <div class="row slide2 text-center justify-content-center">
            <!-- item end -->
           
            <div class="bg-white lg_br padding_1 box_shadow margin_1 pb-4 team_item" *ngFor="let member of team">
              <figure> <img [src]="profile_pic(member.profile_pic)" class="lg_br"> </figure>
              <div class="team_box">
              <h5> {{member.person_name}}</h5>
              <span>{{member.designation}}</span></div>
              <p>{{member.description}}</p>
              <a [routerLink]="['/team/']" [queryParams]="{ q: 'India',k: 2,default_member: member.id }" class=" btn_sm_1">{{"KnowMore"|translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- slide2 End -->
  <!-- slide4 start -->
  <section id="bg_design" class="container-fluid slide4"><div class="bg_number">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <h1 class="text-center blue">{{'PARTICIPATING_CONTRIES'|translate}}</h1>
          <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
        </div>
      </div>
      <div class="row justify-content-center">
      
        <div class="col-md-auto box1" *ngFor="let c of countries">
            <img  *ngIf="c.director != null" [routerLink]="['/team/']" [queryParams]="{ q: c.name,k: c.id }" class="show_hand" [src]="getFlag(c.flag)">
            <p  *ngIf="c.director != null" [routerLink]="['/team/']" [queryParams]="{ q: c.name,k: c.id }"  class="show_hand dark_grey"> {{c.name}} </p>

            <img *ngIf="c.director == null" class="show_hand" [src]="getFlag(c.flag)">
            <p *ngIf="c.director == null" class="show_hand dark_grey"> {{c.name}} </p>
    
          </div>
      
      </div>
      </div> </div>
  </section>

  <section>
    <div [routerLink]="['/fresh_talent']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/about.png"> </div>
</section>
<!-- slide10 start -->
<section id="bg_design" class="container-fluid bg-white partners">
  <div class="container">
    <!-- Partners start -->
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center blue">{{'Partners'|translate}}</h1>
        <div class="divder"> <img src="assets/img/icon/line_relive.svg"> </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10 d-flex">
        <div class="col-md-6" id="partner_1"> <img src="assets/img/logos/raman4.jpg" class="border p-2"> </div>
        <div class="col-md-6" id="partner_2"> <img src="assets/img/logos/raman5.jpg" class="border p-2"> </div>
      </div>
      
      <div class="col-md-6 d-flex  justify-content-center">
        <div class="col-md-12 text-center"  id="partner_5"> <img src="assets/img/logos/raman.jpg" class="border p-2"> </div>
      </div>
      <div class="col-md-12 mb-5 text-center mt-4"> <a href="#" class="btn_lg_2">{{"VIEW_ALL"|translate}} </a> </div>
    </div>
    <!-- Partners end -->
    <!-- Sponsors start -->
    <div class="row mt-5" id="Sponsors">
      <div class="col-md-12">
        <h1 class="text-center blue">{{'Sponsors'|translate}}</h1>
        <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
      </div>
    </div>
    <div class="row justify-content-center" id="logo1">
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s1.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s2.png"></div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s3.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s4.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s5.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s6.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s7.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/s8.png"> </div>
      </div>
    <!--  <div class="col-md-12 mb-5 text-center mt-4"> <a href="#" class="btn_lg_2">View All </a> </div>-->
    </div>
    <!-- Sponsors end -->
    <!-- Associated start -->
    <div class="row mt-5">
      <div class="col-md-12" id="Associa">
        <h1 class="text-center blue">{{"Associated_Organizations"|translate}}</h1>
        <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
      </div>
    </div>
    <div class="row justify-content-center" id="logo2">
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/1.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/2.png"></div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/3.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/4.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/5.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/6.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/7.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/8.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/9.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/10.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/11.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/12.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/13.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/14.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/15.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/16.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/17.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/18.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/19.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/20.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/21.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/22.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/23.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/24.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/25.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/26.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/27.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/28.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/29.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/30.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/31.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/32.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/33.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/34.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/35.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/36.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/37.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/38.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/39.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/40.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/41.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/42.png"> </div>
      </div>
       <div class="col-md-3 p-0">
        <div class="img_box"> <img src="assets/img/logos/43.png"> </div>
      </div>
      <div class="col-md-3 p-0">
        <div class="img_box"> </div>
      </div>
      <!--<div class="col-md-12 mb-5 text-center mt-4"> <a href="#" class="btn_lg_2">View All </a> </div>-->
    </div>
    <!-- Associated end -->

    <!-- Associated end -->
  </div>
</section>
<!-- slide10 end -->
