<!-- Footer Start --> <footer>
  <div class="container">
    <div class="row align-items-end d-flex flex-nowrap successfully-main">
      <div class="col-md-6 footer_logo "> <img src="assets/img/footer/logo_footer.png"></div>
      <div class="col-md-6 pl-5 pb-5 footer_sub">
        <form *ngIf="!subscribed" action="#" method="Post" id="subscribe" class="d-flex align-items-center">
          <input type="text" name="text" [(ngModel)]='email' placeholder="{{'ENTER_YOUR_EMAIL_ID'|translate}}" class="col-md-11">
          <button type="button" class="box_shadow" (click)="newsletter()">{{'SUBSCRIBE_NOW'|translate}}</button>
        </form>
        <h2 *ngIf="subscribed" style="text-align: center;color: white;">Subscribed successfully</h2>
      </div>
    </div>
    <div class="row mt-5 d-flex flex-nowrap">
      <!--- Addd Start -->
      <div class="col-md-6 border-right footer_add">
        <h1>{{"ADDRESS"|translate}}</h1>
        <p class="mb-5">{{"Rabindranth_Tagore_University"|translate}},<br>
          {{"Village_Mendua_Post_Bhojpur"|translate}},<br>
          {{"Near_Bangrasiya_chouraha"|translate}},<br>
          {{"Bhopal_Chiklod_Road"|translate}},<br>
          {{"District_Raisen_MP"|translate}}</p>
        <h1>{{"CONTACT"|translate}}</h1> 
        <p class="pb-0 mb-0">> info@vishwarang.com
        </p>
        <p>> <a href="" data-toggle="modal" data-target="#reachus">{{"Reach Us"|translate}}</a></p>

      </div>
      <!--- Addd End -->
      <!--- Link Start -->
      <div class="col-md-6 d-flex footer_weblink">
        <div class="col-md-8 text-right"> <a routerLink="/"> {{'HOME'|translate}} </a><a routerLink="/watch"> {{'WATCH'|translate}} </a>  
          <a routerLink="/listen"> {{'LISTEN'|translate}} </a><a routerLink="/read"> {{'READ'|translate}} </a>
          <a routerLink="/about_us"> {{'ABOUT_US'|translate}} </a>  <a routerLink="/artists"> {{'ARTISTS'|translate}} </a> 
         </div>
        <div class="col-md-4 text-right pr-0"> 
           <a routerLink="/engage"> {{'ENGAGE'|translate}} </a>
            <a routerLink="/relive"> {{'RELIVE'|translate}} </a>
            <a routerLink="/nomination"> {{'NOMINATION'|translate}} </a> 
            <a routerLink="/press"> {{'MEDIA'|translate}} </a>
            <a routerLink="/awards">{{'AWARDS'|translate}} </a>
            <a routerLink="/fresh_talent"> {{'FRESH_TALENT'|translate}}</a>
          </div>
      </div>
      <!--- Link End -->
    </div>

    <div class="row align-items-center flex-nowrap">
      <!--- Social Start -->
      <div class="col-md-6 col-3">
        <div id="fawesomeicons"> 
          <a href="https://www.facebook.com/vishwarang.tagorelitfest"  target="_blank" rel="nofollow"><i class="fab fa-facebook"></i></a>
          <!-- <a href="#"  target="_blank" rel="nofollow"><i class="fa fa-twitter"></i></a>  -->
          <a href="https://www.instagram.com/vishwarang_tagore_lit_fest/"  target="_blank" rel="nofollow"><i class="fab fa-instagram"></i></a>
          <a href="https://www.youtube.com/c/TagoreLitFestVishwarang"  target="_blank" rel="nofollow"><i class="fab fa-youtube"></i></a> 
        </div>
      </div>
      <!--- Social End -->
      <div class="col-md-6 col-9 white text-right" id="footer_bottom_link">
        <a href="https://vishwarang.com/vishwarang-calendar-2022.pdf" target="_blank">{{"Vishwarang Calendar"|translate}} 2022</a>|
        <a routerLink="/faq">{{'FAQ'|translate}}</a> |  <a routerLink="/privacy-policy">{{'PrivacyPolicy'|translate}}</a> </div>
    </div>
  </div>
  <div class=" container-fluid bottom_bg pt-4 pb-4 mt-5 text-center"> © 2021 {{"Rabindranth_Tagore_University"|translate}} </div>
</footer>
<a id="back-to-top" href="#" class="btn btn-light btn-lg back-to-top" role="button"><i class="fa fa-angle-up"></i></a>
<!-- Footer End -->

<div id="reachus" class="modal fade" role="dialog">
  <div class="modal-dialog">

<!-- Modal content-->
<div class="modal-content">
<div class="modal-header">
<h4 class="modal-title">{{"Reach Us"|translate}}</h4>
<button type="button" class="close" data-dismiss="modal">&times;</button>
</div>
<div class="modal-body">
        <form role="form" method="post" id="reused_form" >
        <div class="form-group">
            <input type="text" class="form-control" 
            id="name" name="name"  placeholder="Name"  required maxlength="50">
        </div>
        <div class="form-group">
            <input type="email" class="form-control" 
            id="email" name="email" placeholder="Email" required maxlength="50">
        </div>
			   <div class="form-group">
            <input type="phone" class="form-control" 
            id="email" name="phone" placeholder="Phone"  required maxlength="50">
        </div>
        <div class="form-group">
            <textarea class="form-control msg" type="textarea" name="message"
            id="message" placeholder="Send in your query" 
            maxlength="2000" rows="7"></textarea>
        </div>
        <button type="submit" class="btn reach btn-block" id="btnContactUs">Submit</button>
    </form>
    <div id="success_message" style="width:100%; height:100%; display:none; ">
        <h3>Sent your message successfully!</h3>
    </div>
    <div id="error_message" 
    style="width:100%; height:100%; display:none; ">
        <h3>Error</h3>
        Sorry there was an error.
    </div> 
</div>
  
</div>
</div>
</div>