import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';


@Component({
  selector: 'app-painting-detail',
  templateUrl: './painting-detail.component.html',
  styleUrls: ['./painting-detail.component.css'],
})
export class PaintingDetailComponent implements AfterViewInit, OnInit {
  slider:any= [];
  paintingId:any
  painting:any=[];
  url='https://uatweb.vishwarang.com/admin/storage/app/public/project/images/painting_items';
  urls='https://uatweb.vishwarang.com/painting-detail'

  // urls='https://vishwarang.com/painting-detail'
  // url='https://vishwarang.com/admin/storage/app/public/project/images/painting_items';

  

  constructor(private apiService:ApiService,private activatedRoute: ActivatedRoute) {
    this.paintingId = this.activatedRoute.snapshot.params['id'];
  }
 
  ngOnInit(): void {
    setTimeout(() => {
      this.getItem();
    }, 100)

  }

  ngAfterViewInit(): void {    
     
    
  } //EOF ngAfterViewInit
  getItem(){
    this.apiService.requestGet('/items_by_category/'+this.paintingId).subscribe(
      result => { 
        this.painting = result.data.data ;
        console.log('painting',this.painting)
      },
      error => {
        console.log(error)
      },
      () => {
      }
    )
  }

}
