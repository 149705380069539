<nav aria-label="breadcrumb" id="breadcrumb">
  <div class="col-md-12">
    <div class="row">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
      </ol>
      <li class="breadcrumb-item active" aria-current="page">&gt; {{"FRESH_TALENT"|translate}}</li>
    </div>
  </div>
</nav>
<section>
    <div> <img src="assets/img/banner/{{'lang'|translate}}/banner6.jpg"> </div>
  </section>
  <!--Banner End-->
  <!-- Listen1 start -->
<app-watch-read-listen-fresh></app-watch-read-listen-fresh>
  <!--Listen1 End -->
  <!-- Featured Artists & Speakers start -->
  <section id="bg_design" class="container-fluid slide1 comman_slide"><div class="bg_number">
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-0">
          <h1 class="text-center blue"><span class="dark_grey">{{"Budding"|translate}}</span> {{"Talent"|translate}}</h1>
          <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
          <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery', 'slidng']" [options]="{items: 1, dots: true, nav: true}" [items]="artists">
            <div class="container" *ngFor="let one of artists"> 
                    <div class="row justify-content-center">
                            <!-- item start -->
                            <div *ngFor="let artist of one" class="col-md-3 col-6">
                            <div class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                              <figure><img [src]="getArtistImg(artist)" class="img_br"></figure>
                              <h5 class="text-center"> {{artist.name}}</h5>
                              <p class="text-center">{{(this.helper.getStringLength(artist.short_description)>80)? (artist.short_description | slice:0:80)+'..':(artist.short_description)}} </p>
                              <div class="d-flex m-auto justify-content-center">
                                <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1">{{"Explore"|translate}} </a>
                              </div>
                              </div>
                            </div>
                            <!-- item end -->
                    </div>
            </div>
        </owl-carousel>
           </div>
        <!-- <div class="col-md-12 text-center mt-4"><a href="#" class="btn_lg_2">View All </a> </div> -->
         </div>
        </div>
      </div>
  </section>


  
<section>
  <div [routerLink]="['/nominate']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/fresh_talent.png"> </div>
</section>