<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
          <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; {{"AWARDS"|translate}}</li>
      </div>
    </div>
  </nav>
  <section>
    <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner10.jpg"> </div>
  </section>
  <!--Banner -->
  <!-- <section>
    <div id="carouselExampleIndicators" class="carousel slide comman_banner_slider" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active"> <img src="assets/img/banner/banner_artists.jpg">
          <div class="banner_text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br>
              eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            <a href="#">Contact</a></div>
        </div>
        <div class="carousel-item"> <img src="assets/img/banner/banner_artists.jpg">
          <div class="banner_text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br>
              eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            <a href="#">Contact</a></div>
        </div>
        <div class="carousel-item"> <img src="assets/img/banner/banner_artists.jpg">
          <div class="banner_text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br>
              eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            <a href="#">Contact</a></div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> </a> <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> </a> </div>
  </section> -->
  <!--Banner End-->
  <section id="bg_design">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <h1 class="text-center blue">{{"FromChairmansDesk"|translate}}</h1>
          <div class="divder mb-4"> <img src="assets/img/icon/line.svg"> </div>
        </div>
      </div>
      <div class="row  d-flex align-items-center">
        <div class="col-md-6 blue m-auto text-center">
          <p class="blue"> {{'BELOW_CHAIRMAN_DESK'|translate}}</p>
        </div>
      </div>
    </div>
  </section>
  <section id="bg_design_2" class="media">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12 p-0">
          <ul class="nav nav-tabs col-md-4 col-8 m-auto mb-4" id="award_lab" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let item of awards_filter_params_years; let isOdd=odd" [class.d-flex]="isOdd"> <a class="nav-link  blue active " id="watch-tab" data-toggle="tab" href="#watch" (click)="filter_gallery(item.year,'year')" role="tab" aria-controls="watch" aria-selected="true">{{item.year}}</a> </li>
          </ul>
          <div class="tab-content award_lab" id="myTabContent">
            <div class="tab-pane fade active show" id="watch" role="tabpanel" aria-labelledby="watch-tab">
              <div class="row justify-content-center tab_relive mb-4"> 
                <a *ngFor="let item of awards_filter_params" href="javascript::void()" (click)="filter_gallery(item.id,'event')" class="white_btn active font-weight-bold pl-5 pr-5 m-1">{{item.event_name}}</a> </div>
              <div class="row">
                <div class="col-md-7">
                  <h1 class="white mb-0">{{display_award.award.event_name}}</h1>
                  <h5>{{display_award.awardee_name}} </h5>
                  <p class="white">{{display_award.description}}</p>
                  <div class="col-auto p-0 mt-4 mb-4"> <img [src]="cover(display_award)"></div>
                </div>
                <div class="col-md-5">
                  <div class="scroll_gallery scroll_gallery03 d-flex flex-wrap row">
                    <!-- Item Start -->
                    <div class="col-md-4 col-3 margin_bottom" *ngFor="let item of awards">
                      <div id="icon_play" (click)="display_award = item" class="bg-white lg_br padding_1 box_shadow">
                        <figure> <img [src]="cover(item)" class="img_br"> </figure>
                      </div>
                    </div>
                    <!-- Item End -->
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">Listen</div>
            <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">Read</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- The path to art begins here. Start -->
  <!-- <section id="artist_bg">
    <div class="container-fluid design_corner">
      <div class="row flex-nowrap">
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
      </div>
    </div>
    <div class="container">
      <div class="row text_text flex-nowrap">
        <div class="col-md-5 col-3 text-center"></div>
        <div class="col-md-7 col-9 text-center">
          <h1 class="white">The path to art begins here.</h1>
          <h4 class="white">Share the details of your favourite new artist below </h4>
          <a href="#" class="btn_lg_5"> UPLOAD</a></div>
      </div>
    </div>
    <div class="container-fluid design_corner">
      <div class="row flex-nowrap">
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
        <div class="col-md-6 p-0 "> <img src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
      </div>
    </div>
  </section> -->
  <!-- The path to art begins here. End -->

<section>
    <div [routerLink]="['/artists']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/awards.png"> </div>
</section>