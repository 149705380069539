import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { OwlCarousel } from 'ngx-owl-carousel';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-engage',
  templateUrl: './engage.component.html',
  styleUrls: ['./engage.component.css']
})
export class EngageComponent implements OnInit {
  quiz:any = [];
  poll:any = [];
  quiz_pos:number = 1;
  show_submit:boolean = false;
  vote_for_1:any;
  poll_result:boolean = false;
  vote_for_2:any;
  q1:any;
  quiz_result = -1;
  quizzForm = this.fb.group({
  });
  pollForm = this.fb.group({
  });

  @ViewChild('owlElement') owlElement?: OwlCarousel;
  constructor(private fb:FormBuilder, private initApp:InitAppService,private apiService:ApiService) { }

  ngOnInit(): void {
    this.initApp.initApp();
    this.getRandomQuiz();
    this.getRandomPoll();
  }
  
  getRandomQuiz(){
    this.apiService.getData('/quizz').subscribe((res:any)=>{
             
               this.quiz = res.data.data;
               this.quiz.forEach((element:any) => {
                this.quizzForm.addControl(element.id,new FormControl());
              });
    },
    (error:any)=>{

    })
  }
  
  getRandomPoll(){
    this.apiService.getData('/polls').subscribe((res:any)=>{
            
               this.poll = res.data.data;
               this.poll.forEach((element:any) => {
                this.pollForm.addControl(element.id,new FormControl());
              });
            },
    (error:any)=>{})
  }

  next_q(){
    this.quiz_pos++;
     if(this.quiz_pos != 5){
      this.owlElement?.next();
     }
     
     if(this.quiz_pos == 4){
       this.show_submit = true;
     }
  }

  submit_quizz(){
    this.apiService.postData("/quiz/result",this.quizzForm.value).subscribe((res:any)=>{
      this.quiz_result = 0;
      this.quiz_result = res.result;
    },(error:any)=>{
      this.quiz_result = -1;
    })
  }

  submit_poll(){
    this.apiService.postData("/poll/result",this.pollForm.value).subscribe((res:any)=>{
   
      this.poll_result = true;
      let v1 = res.result.vote_for_1;
      let v2 = res.result.vote_for_2;


      this.vote_for_1  = Math.round(100*v1/(parseInt(v1)+parseInt(v2)));
      this.vote_for_2 = Math.round(100*v2/(parseInt(v1)+parseInt(v2)));
    },(error:any)=>{
    })
  }

}
