import { Component, OnInit } from '@angular/core';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit {

  press:any = [];
  years:any = [];
  lang = 'en';

  constructor(private initApp:InitAppService,private api:ApiService) {
    this.lang = this.api.lang;
  }

  ngOnInit(): void {
    this.lang = this.api.lang;
    this.getPress();
    this.getPressYears();
    
  }

  getPressYears(){
    this.api.getData('/press/years').subscribe(res=>{
    
      this.years = res;
    },
      err=>{

      })
  }

  getPress(){
    this.api.getData('/press').subscribe(res=>{
   
      this.press = res;
      let t = this;
      setTimeout(function(){
        t.initApp.press();
      },1000);
    },
      err=>{

      })
  }

  filter(year:string){
    this.api.getData('/press?year='+year).subscribe(res=>{
    
      this.press = res;
      let t = this;
      setTimeout(function(){
        t.initApp.press();
      },1000);
    },
      err=>{
      })
  }

  getDocLink(data:any){
    try{
      return this.api.storage+"/project/press_document/"+data.id+"/"+data.doc.path;
    }
    catch(e){
      return null;
    } 
  }

  cover(data:any){
      try{
        return this.api.storage+"/project/images/press/"+data.id+"/"+data.cover.path;
      }
      catch(e){
        return null;
      } 
  }

  formated_date(date:any){
      return new Date(date).toLocaleString('en-us',{day: 'numeric',month:'short', year:'numeric'}); 
  }

}
