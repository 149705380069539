import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  img_url = '';

  constructor(private apiService:ApiService) {
    this.img_url = this.apiService.storage+'/project/images/flag/';
   }


  getCountries(){
    return new Promise(resolve=>{
    this.apiService.getData('/countries').subscribe(
      result => {
        resolve(result);
      },
      error => {
      },
      () => {
      }) })
     }

     getCountry(id:number){
      return new Promise(resolve=>{
      this.apiService.getData('/country/'+id).subscribe(
        result => {
          resolve(result);
        },
        error => {
        },
        () => {
        }) })
       }

     getFlag(flag:any){
      try{
        return this.img_url + flag.project_id+'/'+flag.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }

    getTeamDP(team:any){
      try{
        return this.apiService.storage+'/project/images/team/' + team.project_id+'/'+team.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }
}
