<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
      <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
          <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
        </ol>
        <li class="breadcrumb-item active" aria-current="page">&gt; {{"NOMINATION"|translate}}</li>
      </div>
    </div>
  </nav>

  <!--Banner -->
       <!-- <section>
          <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner11.jpg"> </div>
        </section> -->
        <!--Banner End-->  
        <!-- Listen1 start -->
        <section id="bg_design" class="container-fluid nominate slide2c white_slide">
          <div class="container">
            <!-- <div class="row text-center justify-content-center" *ngIf="!logged_in">
              <p class="w-100 mb-2">Login to nominate</p>
              <a [routerLink]="['/login']" [queryParams]="{ redirectTo: '/nomination' }" class="btn_lg_2">{{"LogIn"|translate}}</a>
            </div> -->
            <div class="row text-center">
              <div class="col-md-12">
                <h1 class="text-center blue mb-0">{{"JoinTheTeam"|translate}}</h1>
                <p>{{"Ourglobalstageawaitsyourtalent"|translate}} </p>
                <div class="divder mt-3"> <img src="assets/img/icon/line_relive.svg"> </div>
              </div>
              <div class="col-md-12 pb-5 text-center">
                  <h5 class="text-center w-100 mt-5">{{'nomination_message'|translate}}</h5>
                  <p style="margin-bottom: 10px" class="mt-3">Follow us on:</p>
                  <div class="col-md-12">
                    <div id="fawesomeicons"> 
                      <a href="https://www.facebook.com/vishwarang.tagorelitfest"  target="_blank" rel="nofollow"><i class="fab fa-facebook fa-lg"></i></a>
                      <!-- <a href="#"  target="_blank" rel="nofollow"><i class="fa fa-twitter"></i></a>  -->
                      <a href="https://www.instagram.com/vishwarang_tagore_lit_fest/"  target="_blank" rel="nofollow"><i class="fab fa-instagram fa-lg"></i></a>
                      <a href="https://www.youtube.com/c/TagoreLitFestVishwarang"  target="_blank" rel="nofollow"><i class="fab fa-youtube fa-lg"></i></a> 
                    </div>
                  </div>
          </div>
            </div>
          </div>
        </section>
  
        <section>
          <div [routerLink]="['/artists']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/nominate.png"> </div>
      </section>