import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListenComponent } from 'src/app/pages/listen/listen.component';
import { WatchComponent } from 'src/app/pages/watch/watch.component';
import { ReadComponent } from 'src/app/pages/read/read.component';
import { InitAppService } from 'src/app/services/init-app.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { WatchService } from 'src/app/services/watch.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
} 

@Component({
  selector: 'app-artist-page',
  templateUrl: './artist-page.component.html',
  styleUrls: ['./artist-page.component.css']
})



export class ArtistPageComponent implements OnInit {
 
  selectedFile?: ImageSnippet;
  artist:string= '';
  message_state = "Submit";
  artist_data:any=[];
  img_url:string="";
  isLoggedIn:boolean = false;
  read:any=[];
  message_sent:boolean = false;
  watch:any=[];
  listen:any=[];
  errors:any =[];
  show_message_section:boolean = false;
  message:string = '';

  fanMessageForm = this.formBuilder.group({
    'message':new FormControl("", [Validators.required,Validators.minLength(4)]),
   });
 
  constructor(private formBuilder:FormBuilder,private readService:ReadService,private listenService:ListenService,private tokenService:TokenService, private initApp:InitAppService,private apiService:ApiService,private activatedRoute:ActivatedRoute,private watchService:WatchService,private router: Router) { 
    this.activatedRoute.queryParams.subscribe(params => {
      
      this.artist = params['q'];
      }); 

      this.img_url = this.apiService.storage+'/project/images/artist/';
  }

  ngOnInit(): void {
  if(this.tokenService.isLoggedIn()){
         this.isLoggedIn = true;
       }
       this.initApp.addThis();
  }

  getImg(){
    try{
      return this.img_url + this.artist_data.profile_image.project_id+'/'+this.artist_data.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }
  switchFav(item:any){
    this.watchService.switchFav(item); 
}

getWatchImg(item:any){
  return this.watchService.getImg(item);
}


getReadImg(item:any){
 return this.readService.getImg(item);
}

getListenImg(item:any){
 return this.listenService.getImg(item);
}
  ngAfterViewInit() {
    this.apiService.getData('/artist/info/'+this.artist).subscribe(
      result => {
        if(result.data.watch !== null){
          this.watch = this.apiService.chunk([result.data.watch],4);  
        }
        
        if(result.data.listen !== null){
          this.listen = this.apiService.chunk([result.data.listen],4);  
        }
        
        if(result.data.read !== null){
          this.read = this.apiService.chunk([result.data.read],4);
        }
        
        this.artist_data = result.data;
      },
      error => {
      },
      () => {
      }
    )
    this.initApp.initApp();
  }

  sendFanMessage(){
    this.message_state = 'Submitting..';
    this.apiService.postFanLetter(this.message,this.artist,this.selectedFile).subscribe((res:any)=>{
      if(res.status){    
          this.message_state = "Submitted";
          this.message_sent = true;
      }
    },
    (error:any)=>{
      this.message_state = "Submit";
      this.errors = JSON.parse(error.error);
    });
  }


processFile(imageInput: any) {
  const file: File = imageInput.files[0];
  const reader = new FileReader();
  reader.addEventListener('load', (event: any) => {
    this.selectedFile = new ImageSnippet(event.target.result, file);
  });
  reader.readAsDataURL(file);
 }

}
