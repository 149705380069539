import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { HeaderComponent } from '../../components/header/header.component';
import { InitAppService } from '../../services/init-app.service';
import { ApiService } from '../../shared/api.service';
import { TokenService } from '../../shared/token.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.css']
})
export class ReadComponent implements OnInit {
  @ViewChild('owlElement') owlElement?: OwlCarousel;

  read:any  =  [];
  isLoggedIn:boolean = false;
  featured:any  =  [];
  next_page_url  = null;
  featured_artists:any = [];
  read_more:any = [];
  artist_img_path:string = '';
  img_url = '';
  categories:any = [];

  constructor(private tokenService:TokenService,private titleService:Title,private head:HeaderComponent , private initApp:InitAppService,private apiService:ApiService) { 
    titleService.setTitle("Read");
    this.img_url = this.apiService.storage+'/project/images/read/';
    this.artist_img_path = this.apiService.storage+'/project/images/thumbnail/artist/';
  }

  ngAfterViewInit(){
    this.getFeaturedArtists();
    if(this.tokenService.isLoggedIn()){
      this.isLoggedIn = true;
     }

    this.apiService.getData('/interests?type=read').subscribe(
      result => { return this.categories = result; },
      error => { },
      () => {
      })
    this.initApp.initApp();   
  }

  categoryChanged(event:any){
    this.apiService.getData('/read?category='+event.target.value).subscribe(
      result => {
        this.read = this.apiService.chunk(result.data.data,8);
        this.next_page_url = result.data.next_page_url;
        this.read_more = [];
        // this.apiService.chunk(result.data.data,8);
      
        // this.artists_pagination[0] = this.artists.data.links[0];
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  getArtistImg(artist:any){
    try{
      return this.artist_img_path + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  ngOnInit(): void {
      this.getRead();
      
      this.getFeaturedArtists();
  }

  getImg(read:any){
    try{
      return this.img_url + read.thumb.project_id+'/'+read.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  load(){
    let url:any = this.next_page_url;
    this.apiService.geturlData(url).subscribe(
      result => {
        this.next_page_url = result.data.next_page_url;
        this.read_more = this.read_more.concat(result.data.data);    
        
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  getFeatured(){
    this.apiService.getData('/read?featured=1').subscribe(
      result => {
        this.featured = this.apiService.chunk(result.data.data,4);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  switchFav(item:any){
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  getFeaturedArtists(){
    this.apiService.getFeaturedArtists('Read').subscribe(
      result => {
        this.featured_artists = this.apiService.chunk(result.data,4);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  getRead(){
    this.read_more = [];
    this.apiService.getData('/read').subscribe(
      result => {
        // this.read = result.data.data;
          this.next_page_url = result.data.next_page_url;
          this.read = this.apiService.chunk(result.data.data,8);
          setTimeout(() =>{this.initApp.initReadSlider();},90);
      },
      error => {
      },
      () => {
      }
    )}

  orderByLatest(){
    
    this.read_more = [];
    this.apiService.getData('/read?orderBy=DESC').subscribe(
      result => {
        this.read = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
      }
    )
  }

  byTrending(){
    this.read_more = [];
    this.apiService.getData('/read?trending=1').subscribe(
      result => {
        this.read = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initReadSlider();},90);
      },
      error => {
      },
      () => {
      }
    )
  }
}
