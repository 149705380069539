import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-fresh-talent',
  templateUrl: './fresh-talent.component.html',
  styleUrls: ['./fresh-talent.component.css']
})
export class FreshTalentComponent implements OnInit {

  artists:any = [];
  artist_img_url = this.apiService.storage+'/project/images/thumbnail/artist/';
  
  constructor(private initApp:InitAppService,private apiService:ApiService,public helper:HelperService) { }

  ngOnInit(): void {
    this.getFreshTalents();
    this.initApp.initApp();

  }
  getArtistImg(artist:any){
    try{
      return this.artist_img_url + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }


  getFreshTalents(){
    this.apiService.getData('/fresh-talents').subscribe(
      result => {
        
        this.artists = this.apiService.chunk(result.data.data,4); 
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }
} 