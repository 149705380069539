<nav aria-label="breadcrumb" id="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; {{"ARTISTS"|translate}}</li>
</nav>
<!--Banner -->
<!-- <section>
    <div> <img src="assets/img/banner/banner_artists.jpg"> </div>
</section> -->
<section>
        <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner8.jpg"> </div>
</section>
<section id="bg_design" class="slide1">
    <div class="container">
            <div class="row justify-content-center">
                    <div class="col-md-12">
                            <h1 class="text-center blue">{{"ARTISTS"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_relive.svg"> </div>
                    </div>
                    <div class="col-md-9 text-center m-auto pb-4 blue">{{"Meet inspirational artists and their breathtaking work from across the globe."|translate}}                </div>
            </div>
            <div class="row justify-content-center">
                    <div class="col-md-6 m-auto">
                            <form>
                                    <div class="form-row col-md-6 col-5 mb-4 p-0 border_radius artist_box cos_select" style="justify-content: center;margin: auto;">
                                    <!-- <div class="form-group col-md-6 col-6 mb-0 pl-0 pr-3 ">
                                            <select id="inputState" class="form-control level_4" placeholder="Content Type">
                                                    <option selected="">All Type</option>
                                                    <option value="1">Watch</option>
                                                    <option value="2">Listen</option>
                                                    <option value="3">Read</option>
                                            </select>
                                    </div> -->
                                    <select id="basic" (change)="categoryChanged($event)" class="form-control" placeholder="Category">
                                        <option selected="" value="" class="text-center">{{"Category"|translate}}</option>
                                        <option  *ngFor="let link of categories" [value]="link.id">{{link.name}}</option>
                                    </select>
                                    </div>
                            </form> 
                    </div>
            </div>

            <div class="row text-center pb-3 justify-content-center">
                       <!-- item start -->
                       
            <div class="col-md-3 col-6 margin_bottom show_hand" [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" *ngFor="let artist of artists_list">
                    <div class="bg-white lg_br padding_1 box_shadow pb-4 py_4">
                            <figure><img [src]="getImg(artist)" class="img_br"></figure>
                            <h5> {{artist.name}}</h5>
                            <p>{{(artist.short_description.length>80)? (artist.short_description | slice:0:80)+'..':(artist.short_description)}} </p>
                            <a [routerLink]="['/artists/info']" [queryParams]="{ q: artist.slug }" class=" btn_sm_1"> {{"Explore"|translate}}</a>
                    </div>
            </div>

    </div>

            </div>
</section>
<section>
        <div [routerLink]="['/nomination']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/artist.png"> </div>
    </section>