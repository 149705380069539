import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

  @Injectable({
    providedIn: 'root'
  })

  export class ApiService {
    // route = "https://vishwarang.com/admin/api";
    // storage= 'https://vishwarang.com/admin/storage';
    route = "https://uatweb.vishwarang.com/admin/api";
    storage= 'https://uatweb.vishwarang.com/admin/storage';
    // route = "http://127.0.0.1:8000/api";
    // storage= 'http://127.0.0.1:8000/storage';
    placeholder= '';
    lang = "en";

    requestGet(endUrl: string): Observable<any> {
      return this.http.get(this.route + endUrl);
    }//EOF re1uestGet
    // User registration
    getData(path:string): Observable<any> {
      return this.http.get(this.route+path);
    }

chunk(arr:any, size:any)
  {
    var chunks = [],
      i = 0,
      n = Object.keys(arr).length;
if(n== 0){
  return [];
}
  while (i < n) {
    chunks.push(arr.slice(i, i += size));
  }
 
  return chunks;
 
    let result = arr.reduce((rows:any, key:any, index:any) => (index % size == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows, []);
 
    return result;
}

    geturlData(path:string): Observable<any> {
      return this.http.get(path);
    }

    postData(path:string,data:any): Observable<any> {
      return this.http.post(this.route+path,data);
    }

    uploadImage(path:string,image: File): Observable<any>{
      const formData = new FormData();
      formData.append('image', image);
      return this.http.post(this.route+path,formData);
      // return this.http.post('/api/v1/image-upload', formData);
    }

  postFanLetter(message:string,artist:string,image: any): Observable<any>{
      const formData = new FormData();
      try{formData.append('file', image['file']);}
      catch(e){}
      formData.append('artist', artist);
      formData.append('message', message);
      return this.http.post(this.route+'/postFanLetter',formData);
  }

  postNomination(form_data:any,file: any,profile_pic: any): Observable<any>{
    const formData = new FormData();

    try{formData.append('profile_pic', profile_pic['file']);}
    catch(e){}

      try{formData.append('file', file['file']);}
      catch(e){}
  
    formData.append('message', form_data['message']);
    formData.append('email', form_data['email']);
    formData.append('name', form_data['name']);
    formData.append('city', form_data['city']);
    formData.append('contest', form_data['contest']); 
    formData.append('state', form_data['state']);
    formData.append('country', form_data['country']);
    formData.append('gender', form_data['gender']);
    formData.append('phone', form_data['phone']);
    formData.append('youtube_link', form_data['youtube_link']);
    formData.append('category', form_data['category']);
    formData.append('facebook_link', form_data['facebook_link']);
    formData.append('instagram_link', form_data['instagram_link']);
    formData.append('links', form_data['links']);
    formData.append('country_code', form_data['country_code']);

    return this.http.post(this.route+'/postNomination',formData);
}

  getFeaturedArtists(type:any): Observable<any> {
      // type in : ['Homepage','Watch','Listen','Read']
      return this.http.get(this.route+'/artists/'+ type);
  }
 
  switchFav(type:any,item_id:any): Observable<any> {
      // type in : ['watch','listen','read']
      return this.http.get(this.route+'/fav/'+ type+'/'+item_id);
    }


    fixed_string(string:string,length:number) {
     return (string.length>80) ? string.slice(0,length)+'..':string;
    }

    navigate(arg0: string) {
      this.router.navigateByUrl(arg0);
    }


    constructor(private http: HttpClient,private router:Router) { 
      if(localStorage.getItem('lang') != undefined){
        var l:string = localStorage.getItem('lang')+'';
        this.lang = l;
      }
    }
  
}
