import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// User interface
export class User {
  name!: String;
  email!: String;
  password!: String;
  password_confirmation!: String;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  route = "https://uatweb.vishwarang.com/admin/api"; 
  // route = "https://vishwarang.com/admin/api";
  constructor(private http: HttpClient) { }

  // User registration
  register(user: User): Observable<any> {
    return this.http.post(this.route+'/auth/register', user);
  }

  // Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(this.route+'/auth/login', user);
  }

  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(this.route+'/auth/user-profile');
  }
}
