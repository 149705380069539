import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { InitAppService } from 'src/app/services/init-app.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { WatchService } from 'src/app/services/watch.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';
import { WatchComponent } from '../watch/watch.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  isLoggedIn:boolean = false;
  featured_watch:any = [];
  featured_read:any = [];
  featured_listen:any = [];
  watch:any = [];
  cat_name = '';
  read:any = [];
  media:any = [];
  gallery_filter_year:string= "";
  gallery_filter_event:string= "";
  listen:any = [];
  category:number = 0;
  categories:any=[];
  category_img_url = this.apiService.storage+'/project/images/thumbnail/category/';
  artist_img_url = this.apiService.storage+'/project/images/thumbnail/artist/';
  playlist_img_url = this.apiService.storage+'/images/thumbnail/playlist';

  load_more_url:string = "";

  constructor(private router:Router,public watchService:WatchService,private activatedRoute:ActivatedRoute,protected initApp:InitAppService, public helper:HelperService, private apiService:ApiService,private tokenService:TokenService,public readService:ReadService,public listenService:ListenService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.activatedRoute.queryParams.subscribe(params => {
      this.category = params['q'];
      this.cat_name = params['name'];
      this.loadMedia();
      });
      console.log('s');
  }

  filter_gallery(val:any,type:string){

    if(type == 'year'){
     this.gallery_filter_year = val;
    }
    else{

     this.gallery_filter_event = val;
    }

    this.loadMedia();
         }

         loadMedia(){
    
          this.apiService.getData('/media-painting?year='+this.gallery_filter_year+'&event='+this.gallery_filter_event).subscribe(
            result => {
               this.media = result;
            },
            error => {
            },
            () => {
            }
          )
        }

  ngOnInit(): void {
    this.apiService.getData('/interests').subscribe(
      result => { return this.categories = result; },
      error => { },
      () => {
      })
    if(this.tokenService.isLoggedIn()){
      this.isLoggedIn = true;
     }

     this.watchTab();
     this.readTab();
     this.listenTab(); 
 }

 getMediaImg(item:any){
  try{
    return this.apiService.storage+'/project/images/painting_galleries/' + item.project_id+'/'+item.path;
  }
  catch(e){
      return this.apiService.placeholder;
  }
}

  watchTab(){
    // this.featured_watch = [];
    this.watch = [];
    this.watchService.getWatchByCategory(this.category).then((data:any)=>{
      console.log(data.data.next_page_url);
      this.load_more_url = data.data.next_page_url;
      this.featured_watch = this.apiService.chunk(data.data.data,8);
    });
  }

  listenTab(){
    // this.featured_listen = [];
    this.listen = [];
    this.listenService.getListenByCategory(this.category).then((data:any)=>{
      console.log(data.data.next_page_url);
      this.load_more_url = data.data.next_page_url;
      this.featured_listen = this.apiService.chunk(data.data.data,8);
    });
  }

  readTab(){
    // this.featured_read = [];
    this.read = [];
    this.readService.getReadByCategory(this.category).then((data:any)=>{
      console.log(data.data.next_page_url);
      this.load_more_url = data.data.next_page_url;
      this.featured_read = this.apiService.chunk(data.data.data,8);
    });
  }

  loadWatchMore(){
    console.log(this.load_more_url);
    this.apiService.geturlData(this.load_more_url).subscribe((res:any)=>{
      this.load_more_url = res.data.next_page_url;
      this.watch = this.watch.concat(res.data.data);
    });
  }

  loadReadMore(){
    this.apiService.geturlData(this.load_more_url).subscribe((res:any)=>{
      this.load_more_url = res.data.next_page_url;
      this.read = res.data.data;
    });
  }

  loadListenMore(){
    this.apiService.geturlData(this.load_more_url).subscribe((res:any)=>{
      this.load_more_url = res.data.next_page_url;
      this.listen = this.listen.concat(res.data.data);
    });
  }

  categoryChanged(event:any){
    const queryParams: Params = {q:event.target.value,name:event.target.options[event.target.options.selectedIndex].text };
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    // this.router.navigateByUrl('/category?q='+event.target.value+'&name='+event.target.options[event.target.options.selectedIndex].text);
  }

  switchListenFav(item:any){
    this.apiService.switchFav('listen',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchReadFav(item:any){
  
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchWatchFav(item:any){
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }


}
