<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="container"><div class="row"><ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']" fragment="playlist"> &lt; {{'BacktoHome' | translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein' | translate}}:</li>
            <li class="breadcrumb-item"><a [routerLink]="['']" fragment="playlist">&gt; {{"HOME"|translate}} </a></li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; {{"Playlist"|translate}}</li></div></div>
</nav>

<!-- Banner -->
<section class="bg_design">
        <figure> <img src="{{playlist_img_url}}{{'lang'|translate}}/{{playlist.banner}}" class="img_br"/></figure>
    <div class="mt-3 embed-responsive embed-responsive-16by9 col-md-6 m-auto">
      <iframe class="embed-responsive-item mt-3" [src]="playlist.ContentLink" allowfullscreen></iframe>
    </div>
</section>
<!--Banner End-->

<section class="bg_design_2">
    <div class="container pb-5">
            <div class="row">
                    <div class="col-md-12">
                            <h4 class="blue"><strong>{{playlist.title}}</strong></h4>
                            <p class="blue">{{playlist.description}}</p>                            
                            <div class="pt-2 addthis_inline_share_toolbox"></div>
                    </div>
                    <div class="col-md-12 mt-3">
                            <p class="blue"> {{helper.getObjectLength(playlist.comments)}} comments</p>
                    </div>

                    <div class="col-md-12">
                            <div *ngFor="let comment of playlist.comments">
                                   <p> {{comment.comment}} <br> By: {{comment.first_name}} {{comment.last_name}}<button (click)="deleteComment(comment.id)" *ngIf="user_id == comment.user_id" class="m-2 btn_sm_1">Delete</button></p>                                   
                            </div>
                    </div> 
            </div>

            <div class="row" *ngIf="!logged_in">
                 <div class="col-md-12">
                    <p class="w-100">Please login to continue for comment</p>
                    <a [routerLink]="['/login']" [queryParams]="{ redirectTo: current_url }" class="btn_sm_1">Login</a>
                 </div>
               </div>
            <div class="row" *ngIf="logged_in">
               <div class="col-md-12">
                <h5 class="blue" style="width: 100% !important;" *ngIf="comment_posted">Comment Posted!</h5>
                <div class="col-5">
                    <p class="blue">Write a comment</p>
                    <div>
                            <textarea [(ngModel)]="comment" class="form-control w-100" style="border-radius: 0 !important; margin-bottom: 10px;height: unset !important;" rows="4"></textarea><button (click)="postComment()" class="btn btn_sm_1">Submit</button>
                    </div>
                </div>
               </div>
        </div>       
    </div>
</section>
