import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { WatchService } from 'src/app/services/watch.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-watch-read-listen-fresh',
  templateUrl: './watch-read-listen.component.html',
  styleUrls: ['./watch-read-listen.component.css']
})
export class WatchReadListenFreshComponent implements OnInit {

  featured_watch:any = [];
  featured_read:any = [];
  isLoggedIn:boolean = false;
  @Input() view_type? : string;

  featured_listen:any = [];
  constructor(private apiService:ApiService,public helper:HelperService ,public readService:ReadService,public watchService:WatchService,public listenService:ListenService) { }

  ngOnChanges():void{

    console.log(this.view_type);
  }


  ngOnInit(): void {

   console.log(this.view_type);
    this.readService.FreshTalentRead(8).then(data=>{
      this.featured_read = data;
   });

    this.listenService.FreshTalentListen(8).then(data=>{
      this.featured_listen = data;
   });

   this.watchService.FreshTalentWatch(8).then(data=>{
    this.featured_watch = data;
 });

  }

  switchListenFav(item:any){
 
    this.apiService.switchFav('listen',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchReadFav(item:any){
  
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchWatchFav(item:any){
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }
}
