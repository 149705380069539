 <div  oncontextmenu="return false">
<nav aria-label="breadcrumb" id="breadcrumb">
  <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/"> &lt; {{'BacktoHome'|translate}}</a></li>
      <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
  </ol>
  <li class="breadcrumb-item active" aria-current="page">&gt; {{"PAINTING"|translate}}</li>
</nav>


<nav class="mt-4"> 
  <h1 class="text-center"> {{"NATIONAL TAGORE EXHIBITION OF PAINTING "|translate}}</h1>
</nav>
<div class="divder">
  <img loading="lazy" src="assets/img/icon/line.svg">
</div>
<div class="container  margin-bottom-20 ">
 
  <div class="row">
    <div class="silder-main"> 
      <h1 class="text-center blue"><span class="dark_grey">
        {{"5th NATIONAL TAGORE EXHIBITION OF PAINTING 2023"|translate}}
      </span> 
        </h1>
      <owl-carousel-o [options]="customOptions"  *ngIf="slider1" >
        <ng-template carouselSlide *ngFor="let bannerImage1 of slider1;">
          <img [src]="'../assets/img/exhibition-slider/1_Page_'+bannerImage1+'.jpg'" class="imgwithresp" />
        </ng-template>  
      </owl-carousel-o>
    </div>
    </div>
   </div>
     
 <div class="divder mt-4">
  <img loading="lazy" src="assets/img/icon/line.svg">
</div>
</div>
      
      
 