<nav aria-label="breadcrumb" id="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt;  {{'LogIn'|translate}}</li>
</nav>
<!-- Sign Up start -->
<section id="bg_design" class="container-fluid registration">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <!-- <h1 class="text-center blue">Sign In</h1>
                            <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div> -->
                            <h5> {{"Get access to exclusive content and artists at Vishwarang."|translate}} </h5>
                    </div>
                     
            </div>
            <div class="row pb-5" id="registration_form">
                    <!-- item start -->
                    <div class="col-md-10 bg-white p-0 d-flex m-auto lg_br box_shadow">
                            <div class="col-md-8  offset-md-2 col-lg-6  offset-lg-3 pt-3">
                                    <h5 class="text-md-left text-center">{{"LogIn"|translate}}</h5>
                                    <p class=" d-none d-print-block d-lg-block ">All fields are mandatory*</p>
                                    <p class=" success" *ngIf="welcome_user">Account created successfully.</p>
                                    
                                    <p class=" d-none d-print-block d-lg-block error" *ngIf="error">Wrong email or password.</p>
                                    <form  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                            <div class="form-row">
                                                     
                                                     
                                                     
                                                      
                                                    <div class="form-group col-md-12">
                                                            <input type="email"  required minlength="4"  formControlName="email" class="form-control" id="inputEmail" [placeholder]="'Email'|translate">
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                            <input type="password"  required minlength="4" formControlName="password" class="form-control" id="inputPassword" [placeholder]="'Password'|translate">
                                                    </div>
                                            </div>
                                             
                                             
                                            <div class="text-center col-md-12">
                                                    <button type="submit" class="btn_lg_4 bg-blue white">{{'Submit'|translate}}</button>
                                            </div>
                                            <div class="text-center col-md-12">
                                                    <h6><span> </span> Or Login with<span> </span> </h6>
                                            </div>
                                            <div class="text-center col-md-12 d-flex align-items-center justify-content-center mt-4 mb-4">
                                                    <div class="col-sm-2"> <img  (click)="signInWithGoogle()" class="social" src="assets/img/icon/google.svg"></div>
                                                    <div class="col-sm-2"><img (click)="signInWithFB()" class="social" src="assets/img/icon/facebook_re.svg"> </div>
                                                    <!-- <div class="col-sm-2"> <img class="social" (click)='linkedinLogin()' src="assets/img/icon/linkedin_re.svg"></div>
                                                    <div class="col-sm-2"><img class="social" src="assets/img/icon/twitter_re.svg"> </div> -->
                                            </div>
                                            
                                            <div class="text-center col-md-12">
                                                <h6><span> </span> New user?<span> </span> </h6>
                                        </div>
                                <a [routerLink]="['/register']" class="btn_lg_4 bg-blue white text-center" style="color: white;margin: auto;
                                display: flex;
                                justify-content: center;
                                width: fit-content;">{{"Create_An_Account"|translate}} </a>
                                    </form>
                                    <a [routerLink]="['/forget-password']"  class="mt-3 mb-3 text-center" 
                                    style="margin: auto;display: flex;justify-content: center;width: fit-content;">Forgot password</a>
                            </div>
 
                            <!-- <div class="col-md-5 pr-0 d-none d-print-block d-lg-block "> <img src="assets/img/banner/banner_registration.jpg" class="lg_img_tr lg_img_br"></div> -->
                    </div>
                    <!-- item end -->
            </div>
    </div>
</section>