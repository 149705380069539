<header>
    <nav class="navbar navbar-expand-sm navbar-dark bg-white flex-nowrap"> 
            <div class=" col-md-3 col-3 pl-0">
             <a class="navbar-brand pl-0" [routerLink]="['/']">
         <img src="assets/img/header_top/logo.png" class="vr_logo"> </a></div>
         <!-- <a class="navbar-brand col-md-3 col-3 pl-0" href="index.html"> <img src="assets/img/header_top/logo.png" class="vr_logo"> </a> -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
        <div class="collapse navbar-collapse col-md-6" id="navbarsExample03">
          <div class="row flex-nowrap align-items-center mt-4 d-md-none">
            <div class="col-md-6 pl-0 menu_logo "> <a [routerLink]="['/']"> <img src="assets/img/icon/home.png"> </a></div>
            <div class="col-md-6 pr-0 text-right">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation"> <i class="fa fa-times navbar-toggler-icon" aria-hidden="true"></i> </button>
            </div>
          </div>
                    <ul class="navbar-nav mr-auto ml-auto">
                            <div class="col-auto  first_nav">
                                <li class="nav-item active"> 
                                 <a class="nav-link toogle_menu" routerLink="/watch">{{'WATCH' | translate }} </a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/listen">{{'LISTEN'|translate}}</a> </li>
                                <li class="nav-item"> <a  class="nav-link toogle_menu" routerLink="/read">{{'READ'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/about_us">{{'ABOUT_US'|translate}}</a> </li>                    
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/artists">{{'ARTISTS'|translate}}</a> </li>
                                <li class="nav-item"><a class="nav-link border-0 toogle_menu" routerLink="/category" [queryParams]="{q:'13',name:'Paintings'}">{{'Paintings'|translate}}</a></li>
                            </div>

                            <div class="col-auto p-0 sec_nav">
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/engage">{{'ENGAGE'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/relive">{{'RELIVE'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/attend">{{'ATTEND'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/nomination">{{'NOMINATION'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/press">{{'MEDIA'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link toogle_menu" routerLink="/awards">{{'AWARDS'|translate}}</a> </li>
                                <li class="nav-item"> <a class="nav-link border-0 toogle_menu" routerLink="/fresh_talent">{{'FRESH_TALENT'|translate}}</a> </li>
                        </div>
                    </ul>
            </div>

            <div class="col-md-3 pr-0 form_side">
                <div class="col-md-12 p-0">
                        <form class="my-2 my-md-0 text-right"  action="profile"> 
                                <input type="text" class="search-click" name="" placeholder="search here..." name="word" [(ngModel)]="word" (input)="search()"/>
                              <a  *ngIf="!logged_in" id="log_in" [routerLink]="['/login']">{{'LogIn'|translate}}</a>
                              <a [routerLink]="['profile']" *ngIf="user.image == null && logged_in"><img src="assets/img/header_top/user.png"></a> 
                              <a [routerLink]="['profile']"  *ngIf="user.image !== null && logged_in"><img style="width: 50px;border-radius: 50%;margin: 1px;" [src]="getDP(user.image)"></a>
                              <a *ngIf="logged_in" (click)="Logout()" class="social" id="log_in">Logout</a>  
                              <!-- <button *ngIf="!logged_in" id="log_in" (click)="switchLang()">{{lang === 'en'?'hi':'en'}}</button> -->
                         </form>
                </div>
                <div class="col-md-12 p-0 text-right lan mb-2 mt-2">
                        <a href="javascript::void()" (click)="switchLang('en')" class="font-weight-bold active" [class.active] = "lang == 'en'" [class.font-weight-bold] = "lang == 'en'">English</a> | 
                        <a (click)="switchLang('hi')" href="javascript::void()" [class.active] = "lang == 'hi'" [class.font-weight-bold] = "lang == 'hi'">Hindi</a> </div>
                
              </div>          
    </nav>
</header>
<div class="col-auto p-0 third_nav mt-2 mb-3 text-center">
     <a href="https://vishwarang.com/vishwarang" target="_blank">{{"Registration of Vishwarang"|translate}}</a>
     <a href="Vishwarang-2023-calendar.pdf" target="_blank">{{"Vishwarang Calendar"|translate}}</a>
     <a href="https://vishwarang.com/vishwarang-2022/gsp" target="_blank" >{{"Registration for Children's Literature Festival"|translate}}</a>
     <!-- <a href="https://vishwarang.com/vishwarang-2022/ScheduleChildrenFestival" target="_blank">{{"Schedule of Children's Festival"|translate}}</a>  -->
     <a href="/assets/Final Vishwarang Coffee Table.pdf" class="btn-danger btn" target="_blank">{{"Coffee Table Book"|translate}}</a> 
     <!-- <a href="https://vishwarang.com/vishwarang-2022/live" class="btn-danger btn" target="_blank">{{"Watch Live!"}}</a>  -->
</div>

<section id="bg_design_2" *ngIf="word.length > 2" class="container-fluid slide2 white_slide">
  <div class="container">
          <div class="row"> 
                  <div class="col-md-12">
                          <h1 class="text-center white">Search result</h1>
                          <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                          <ul class="nav nav-tabs mb-4" id="comman_tab" role="tablist">
                                  <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{"WATCH"|translate}}</a> </li>
                                  <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" href="#listen" role="tab" aria-controls="listen" aria-selected="false">{{"LISTEN"|translate}}</a> </li>
                                  <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#read" role="tab" aria-controls="read" aria-selected="false">{{"READ"|translate}}</a> </li>
                          </ul>
                          <div class="tab-content" id="myTabContent">
                                  <!-- watch slide start-->
                                  <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                      <!-- <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery', 'row', 'slidng']" [options]="{items: 1, dots: true, nav: true}" [items]="search_watch"> -->
                                              <div class="container"> 
                                                      <div class="row">
                                                              <!-- item start -->
                                                              <div *ngFor="let item of search_watch" [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                      <figure><img [src]="watchService.getImg(item)" class="img_br">
                                                                              <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                      </figure>
                                                                      <h5 class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                      <p class="text-center"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                      <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                              <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"PLAY"|translate}}</a>
                                                          </div>

                                                              </div>
                                                              <!-- item end -->
                                                      </div>
                                              </div>
                                          <!-- </owl-carousel> -->
                                          <p *ngIf="isEmptyObject(search_watch)" class="text-center">No data</p>
                                
                                  </div>
                                  <!-- watch slide end -->
                                  <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">
                                                                                                
                                      <!-- <owl-carousel #owlListen [carouselClasses]="['owl-theme','watch_gallery', 'row', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="search_listen"> -->
                                              <div class="container">
                                                              <div class="row">
                                                                              <div id="icon_play" *ngFor="let item of search_listen"  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                <figure><img [src]="listenService.getImg(item)" class="img_br">
                                                                                                <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                </figure>
                                                                                <h5 class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>  
                                                                                <p  class="text-center">{{item.information|slice: 0: 80}}</p>
                                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{"PLAY"|translate}}</a>
                                                                                             
                                                                                </div>
                                                                </div>
                                                 <!-- item end -->
                                                              </div>
                                              </div>
                                              <!-- </owl-carousel> -->
 
                                  </div>
                                  <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">

                                      <!-- <owl-carousel #owlRead [carouselClasses]="['owl-theme','watch_gallery', 'row', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="search_read"> -->
                                              <div class="container" >
                                                              <div class="row">
                                                                              <!-- item start -->
                                                                              <div id="icon_play" *ngFor="let item of search_read" [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                              <figure> <img [src]="readService.getImg(item)" class="img_br"> </figure>
                                                                                              <h5 class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                              <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                       <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                                                     
                                                                                              </div>
                                                                              </div>
                                                                              <!-- item end -->
                                                              </div>
                                              </div>
                                      <!-- </owl-carousel> -->
                                  
                                  </div>
                          </div>
                  </div>
          </div>
  </div>
</section>