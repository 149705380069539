import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-watch-detail',
  templateUrl: './watch-detail.component.html',
  styleUrls: ['./watch-detail.component.css']
})
export class WatchDetailComponent implements OnInit {

  watch:string="";
  watch_data:any = [];
  related_watch:any = [];
  comment:string = "";
  user_id = localStorage.getItem('user_id');
  comments:any = [];
  logged_in = false;
  comment_posted = false;
  img_url:string;
  current_url:string="";
  

  constructor(private router:Router,public helper:HelperService, private tokenService:TokenService,private activatedRoute:ActivatedRoute,private sanitizer:DomSanitizer,private apiService:ApiService,private initApp:InitAppService) { 
    this.activatedRoute.queryParams.subscribe(params => {
      window.scrollTo(0,0);
      this.watch = params['q'];
      
      this.afterLoaded();
      });

      this.img_url = this.apiService.storage+'/project/images/watch/';
      this.current_url = router.url;
    }

    ngOnInit(): void {
      if(this.tokenService.isLoggedIn()) {
        this.logged_in = true;
       }
       this.initApp.addThis();  
    }
  
    getImg(){
      try{
        return this.img_url + this.watch_data.thumb.project_id+'/'+this.watch_data.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }

    getThumbImg(watch:any){
      try{
        return this.img_url + watch.thumb.project_id+'/'+watch.thumb.path;
      }
      catch(e){
          return this.apiService.placeholder;
      }
    }
  
    
    
  getWatch(){
    this.apiService.getData('/watch?artist='+this.watch_data.artist+'&not_in='+this.watch_data.id).subscribe(
      result => {
        this.related_watch = this.apiService.chunk(result.data.data,4);
        setTimeout(() =>{this.initApp.initWatchSlider();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  linkURL(url:string){
    this.watch_data.ContentLink =  this.sanitizer.bypassSecurityTrustResourceUrl(this.watch_data.ContentLink);
  }

    ngAfterViewInit() {
      this.afterLoaded();
    }

    afterLoaded(){
      var t = this;
      setTimeout(function(){
        t.apiService.postData('/watch/push/trending/'+t.watch,[]).subscribe(
          result => {
          },
          error => {
          },
          () => {
          }
        )
        
      },8000);



      this.loadInfo();
      this.initApp.initApp();
    }

    postComment(){
          this.comment_posted = true;
          let data = {'key':this.watch,'comment':this.comment};
          this.apiService.postData('/comment/watch',data).subscribe((res:any)=>{
            this.comment = '';
            this.comment_posted = true;
            this.loadInfo();
          },
          (error:any)=>{
          });  
    }

    deleteComment(id:string){
      this.apiService.postData('/comment_delete',{id:id}).subscribe((res:any)=>{
        this.loadInfo();
      },
      (error:any)=>{
      });
      
}

loadInfo(){
  this.apiService.getData('/watch/info/'+this.watch).subscribe(
    result => {
      if(result.data === null){
        this.apiService.navigate('/watch');            
      }
     this.watch_data = result.data;
     this.getWatch();
     this.watch_data.ContentLink =  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.watch_data.ContentLink);
    },
    error => {
    },
    () => {
    }
  )
}

}
