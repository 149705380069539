import { Injectable,EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  afterLogin = new EventEmitter<String>(); 

  constructor() { }
}
