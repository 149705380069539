import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  country:number = 2;
  countries:any = [];
  team:any = [];
  main_member:any = [];
  

  constructor(private teamService:TeamService,private router:Router) {
  
   }

   transMe(event:any,text:string)
{
  console.log(event);

}
  ngOnInit():void{
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
  };
    this.teamService.getCountry(this.country).then((data:any)=>{
      this.team = data.team;
  
      this.main_member = data.director;
      
    });

    this.teamService.getCountries().then((data:any)=>{
      this.countries = data;
    })
   }

   profile_pic(member:any){
    return this.teamService.getTeamDP(member);
   }

   getFlag(flag:any){
    return this.teamService.getFlag(flag);
  }

}
