import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStateService } from 'src/app/shared/auth-state.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TokenService } from 'src/app/shared/token.service';
import { EventEmitter } from '@angular/core';
import { LoginService as LoginEvent } from 'src/app/services/event/login.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ApiService } from 'src/app/shared/api.service';
import { LinkedInLoginProvider } from 'src/app/services/linkedIn-login-provider';
let hello = require('hellojs/dist/hello.all.js');

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {
 
  loginForm = this.fb.group({
    email: [],
    password: []
  });

  error:boolean= false;
  welcome_user:boolean = false;
  redirectTo:string = '';


  constructor(  public router: Router,
    public fb: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private socialAuthService: SocialAuthService,
    private api:ApiService,
    private loginEvent:LoginEvent,
    private authState: AuthStateService,private activatedRoute:ActivatedRoute) {

      this.activatedRoute.queryParams.subscribe(params => {
        this.redirectTo = params['redirectTo'];
        }); 

      hello.init({
        linkedin: "783hlt6bpp8qbe"
      }, {redirect_uri: 'http://localhost:4200'});

     let test = this.router.getCurrentNavigation()?.extras.state;
       

      if(test?.welcome_user !== undefined){
           this.welcome_user = true;
      }

     }

  ngOnInit(): void {
    if(this.token.isLoggedIn()) {
      this.router.navigate(['/']);
     }
  }

  
  onSubmit() {
    this.error = false;
    this.authService.signin(this.loginForm.value).subscribe(
      result => {
        if(result.error === "Unauthorized"){
          this.error = true;
        }
        this.responseHandler(result);
        
      },
      error => {
        this.error = true;
      },() => {
        this.authState.setAuthState(true);
        this.loginForm.reset()
        this.loginEvent.afterLogin.emit('LoggedIn');
        if(this.redirectTo != '' && this.redirectTo != undefined){
          this.router.navigateByUrl(unescape(this.redirectTo)); 
        }
        else{
          this.router.navigate(['profile']);
        }
        // this.loginEvent.afterLogin.emit('LoggedIn');
      }
    );
}

// getEmitter() { 
//   return this.afterLogin; 
// } 

// Handle response
responseHandler(data:any){
  localStorage.setItem('user_id',data.id);
  this.token.handleData(data.access_token);
}

signInWithGoogle(): void {
  const googleLoginOptions = {
    scope: 'profile email'
  };
  this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID,googleLoginOptions).then((res: any) => {    
  
    this.api.postData('/auth/social',res).subscribe(
      result => {
        this.responseHandler(result);        
      },
      error => {
        this.error = true;
      },() => {
        this.authState.setAuthState(true);
        this.router.navigate(['profile']);
        this.loginEvent.afterLogin.emit('LoggedIn');
      });
  });
}

linkedinLogin(): void {

  // const googleLoginOptions = {
  //   scope: 'profile email'
  // };

  this.socialAuthService.signIn(LinkedInLoginProvider.PROVIDER_ID).then((res: any) => {    
 
    this.api.postData('/auth/social',res).subscribe(
      result => {
        this.responseHandler(result);
        this.authState.setAuthState(true);
      },
      error => {
        this.error = true;
      },() => {
        this.authState.setAuthState(true);
      });
  });
}

signInWithFB(): void {
  this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
}

linkedinLogin1():void{
  hello('linkedin').login().then(function() {
    hello('linkedin').api('me').then(function(json:any) {
   
    }, function(e:any) {
      alert('Whoops! ' + e.error.message);
    });
  }, function(e:any) {
    alert('Signin error: ' + e.error.message); 
  });
 let  fb = hello('linkedin').getAuthResponse();
}

 signOut(): void {
  this.socialAuthService.signOut();
 }

}
