import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { InitAppService } from '../../services/init-app.service';
import { ApiService } from '../../shared/api.service';
import { TokenService } from '../../shared/token.service';

@Component({
  selector: 'app-listen',
  templateUrl: './listen.component.html',
  styleUrls: ['./listen.component.css']
})
export class ListenComponent implements OnInit {
  @ViewChild('owlElement') owlElement?: OwlCarousel;

  listen:any  =  [];
  img_url = '';
  featured_artists:any = [];
  categories:any = [];
  listen_more:any = [];
  next_page_url = null;
  isLoggedIn:boolean = false;
  constructor(private titleService:Title,private tokenService:TokenService,private initApp:InitAppService,private apiService:ApiService) { 
    titleService.setTitle("Listen");
    this.img_url = this.apiService.storage+'/project/images/listen/';
  }

  ngOnInit(): void {
    this.getListen();
    this.getFeaturedArtists();
  }

  ngAfterViewInit() {
    if(this.tokenService.isLoggedIn()){
      this.isLoggedIn = true;
     }
    // if(this.tokenService.isLoggedIn()){
    //   this.isLoggedIn = true;
    //  }

    this.apiService.getData('/interests?type=listen').subscribe(
      result => {
       return this.categories = result;
      },
      error => {
      },
      () => {
      }
    )

    this.initApp.initApp();   
  }

  getArtistImg(artist:any){
    try{
      return this.apiService.storage+'/project/images/thumbnail/artist/' + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  getFeaturedArtists(){
    this.apiService.getFeaturedArtists('Listen').subscribe(
      result => {
        this.featured_artists = this.apiService.chunk(result.data,4);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }
  
  getImg(listen:any){
    try{
      return this.img_url + listen.thumb.project_id+'/'+listen.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  categoryChanged(event:any){
    this.apiService.getData('/listen?category='+event.target.value).subscribe(
      result => {
        
        this.listen = this.apiService.chunk(result.data.data,8);
        this.next_page_url = result.data.next_page_url;
        this.listen_more = [];
      
        // this.artists_pagination[0] = this.artists.data.links[0];
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  switchFav(item:any){
    
    this.apiService.switchFav('listen',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }


  load(){
    let  url:any = this.next_page_url;
    this.apiService.geturlData(url).subscribe(
      result => {
        this.listen_more = this.listen_more.concat(result.data.data);
        this.next_page_url = result.data.next_page_url;
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  getListen(){
    this.listen_more = [];
    this.apiService.getData('/listen').subscribe(
      result => { 
        this.listen = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.listen();},100);
        this.next_page_url = result.data.next_page_url;
        
      },
      error => {
        
      },
      () => {
      }
    )
  }

  orderByLatest(){
    this.listen_more = [];
    this.apiService.getData('/listen?orderBy=DESC').subscribe(
      result => {
        this.listen = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
      }
    )
  }

  byTrending(){
    this.listen_more = [];
    this.apiService.getData('/listen?trending=1').subscribe(
      result => {
        this.listen = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
      }
    )
  }
}
