import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})
export class PlaylistComponent implements OnInit {

  id:any;
  ContentLink:any = '';
  logged_in = false;
  user_id = localStorage.getItem('user_id');
  playlist:any=[];
  comment_posted:boolean =false;
  comment:string= "";
  playlist_img_url = this.apiService.storage+'/images/banner/playlist/';
  current_url:string="";
  constructor(private router:Router,public helper:HelperService,private activatedRoute:ActivatedRoute, private tokenService:TokenService,private apiService:ApiService,private sanitizer:DomSanitizer) { 
    this.user_id = localStorage.getItem('user_id');
    this.activatedRoute.queryParams.subscribe(params => {
      window.scrollTo(0,0);
      this.id = params['qid'];
      });
  
      this.current_url = router.url;
      }

  ngOnInit(): void {
    if(this.tokenService.isLoggedIn()) {
      this.logged_in = true;
     }
    this.getPlaylistData();
  }

  getPlaylistData(){
    this.apiService.getData('/playlist/'+this.id).subscribe(
      result => {
        this.playlist = result;
        console.log(result);
        this.playlist.ContentLink = this.sanitizer.bypassSecurityTrustResourceUrl(result.url);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  postComment(){
    this.comment_posted = true;
    let data = {'key':this.playlist.id,'comment':this.comment};
    this.apiService.postData('/comment/playlist',data).subscribe((res:any)=>{
      this.comment = '';
      this.comment_posted = true;
      this.getPlaylistData();
    },
    (error:any)=>{
    });  
}

deleteComment(id:string){
this.apiService.postData('/comment_delete',{id:id}).subscribe((res:any)=>{
  this.getPlaylistData();
},
(error:any)=>{
});

}

}
