import { Injectable } from '@angular/core';


declare var jquery: any;
declare var $: any;
declare var addthis:any;

@Injectable({
  providedIn: 'root'
})

export class InitAppService {
    
    videoM(){
        var videos = document.getElementsByTagName("video");

        var fraction = 0.8; // Play when 80% of the player is visible.

    for(var i = 0; i < videos.length; i++) {

        var video = videos[i];

        var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
            b = y + h, //bottom
            visibleX, visibleY, visible;

            visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
            visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

            visible = visibleX * visibleY / (w * h);

            if (visible > fraction) {
                video.play();
            } else {
                video.pause();
            }
    }
}

  award() {
    try{$("#content-5").mCustomScrollbar({
        theme: "dark-thin"
      });}
      catch(e){
      }
  }

  addThis(){
    addthis.init(); 
    addthis.layers.refresh();
  }

  press() {
    try{
    $("#content-5").mCustomScrollbar({
        theme: "dark-thin"
      });}
      catch(e){
      }
   
  }
  initMediaScroll() {
    
    $("#content-5").mCustomScrollbar({
        theme: "dark-thin"
      });

      $(document).on("click", '[data-toggle="lightbox"]', function (event:any) {
        event.preventDefault();
        $(event).ekkoLightbox();
      });
  }

  constructor() { }


  initApp(){
    var t = this;
    window.onscroll = function() {setTimeout(function(){t.videoM()},100);};
     
    try{$('.artists').owlCarousel({
        loop:false,
        margin: 0,
    /*    autoplay:false,
        autoplayTimeout:5000,
    */    dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
     
     $('.featured').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
    
     $('.playlist').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
     
    
    
    
     $('.fan').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                  stagePadding: 50,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:3,
                
                nav:true,
            }
        }
    });
    
    
    
      $('.events').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
               nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
    
   
    

     
     $('.video_galley').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });

    $('.content_gallery').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    }); 
      
      
      
      
      
      
      
      
      
      
     $('.con_video').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });}
    catch(e){
    }
  }


  listen(){
    $('.listen').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
  }

  initWatchSlider(){
    $('.watch_gallery').trigger('destroy.owl.carousel');
    $('.watch_gallery').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        rewind: true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
  }

  initReadSlider(){
      
    $('.read_gallery').owlCarousel({
        loop:false,
        margin: 0,
        autoplay:false,
        autoplayTimeout:5000,
        dots:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1,
                nav:true,
            }
        }
    });
  }

}
