<nav aria-label="breadcrumb" id="breadcrumb">
        <div class="col-md-12">
                <div class="row">
                        <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a>
                                </li>
                                <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
                        </ol>
                        <li class="breadcrumb-item active" aria-current="page">&gt; {{'ATTEND'|translate}}</li>
                </div>
        </div>
</nav>
<!--Banner -->
<section *ngIf="helper.getObjectLength(events) && not_loaded">
        <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner4.jpg"> </div>
</section>
<!--Banner End-->
<!-- Attend start -->
<section id="bg_design" *ngIf="helper.getObjectLength(events)"
        class="container-fluid slide7 white_slide event_slide fan-messages-main">
        <div class="container">
                <div class="row">
                        <div class="col-md-12">
                                <h1 class="text-center blue">{{"Event"|translate}}</h1>
                                <div class="divder"> <img src="assets/img/icon/line_relive.svg"></div>
                        </div>
                        <div class="col-md-12">
                                <!-- 1 item start -->
                                <!-- item start -->
                                <div class="events_0 max_width">
                                        <div class="white_box_2" *ngFor="let item of events">
                                                <div class="white_img"><img [src]="return_thumb(item)" class="lg_br">
                                                </div>
                                                <div class="content_1">
                                                        <h6>{{item.name}}</h6>
                                                        <h5>{{item.description| slice:0:106}}</h5>
                                                        <div class="row flex-nowrap lt_0">
                                                                <div
                                                                        class="col-md-6  col-8 pr-1 d-flex align-items-start">
                                                                        <img src="assets/img/icon/loction.jpg">
                                                                        <p>{{item.location}}</p>
                                                                </div>
                                                                <div
                                                                        class="col-md-6 pr-0 col-4 d-flex align-items-start">
                                                                        <img src="assets/img/icon/calendar2.png">
                                                                        <h4>{{item.date_line}} <br> {{item.time_line}}
                                                                        </h4>
                                                                </div>
                                                        </div>

                                                        <div class="row flex-nowrap pl-3 lt_0">
                                                                <div>
                                                                        <a href="#"
                                                                                class="btn_event mt-2 ml-2 font-weight-light"
                                                                                (click)="popUp(item)"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModal">{{"KnowMore"|translate}}
                                                                        </a>
                                                                        <ul>
                                                                                <li class="dropdown"
                                                                                        style="list-style: none;">
                                                                                        <button class="dropdown  btn_event mt-2 ml-2 font-weight-light "
                                                                                                data-toggle="dropdown">{{'AddtoCalendar'|translate}}<b
                                                                                                        class="caret"></b></button>
                                                                                        <ul class="dropdown-menu add_c">
                                                                                                <li><a [href]="google_calender"
                                                                                                                target="_blank">Google</a>
                                                                                                </li>
                                                                                                <li class="divider">
                                                                                                </li>
                                                                                                <li><a [href]="i_calender"
                                                                                                                target="_blank">iCalendar</a>
                                                                                                </li>
                                                                                                <li class="divider">
                                                                                                </li>
                                                                                                <li><a [href]="yahoo_calender"
                                                                                                                target="_blank">Yahoo</a>
                                                                                                </li>

                                                                                        </ul>
                                                                                </li>
                                                                        </ul>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <!-- item end -->

                                </div>
                                <!-- item end -->
                        </div>
                </div>
        </div>
        <div class="col-md-12 text-center mt-4">
                <button *ngIf="next_page_url" (click)="loadMore()" class="btn_lg_3 blue_btn">View More </button>
        </div>
</section>

<div class="modal fade attend_0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
                <div class="modal-content">
                        <div class="modal-header">
                                <h5 class="modal-title blue" style="width: 100%;" id="exampleModalLabel">
                                        <img style="width: 100%;" [src]="return_cover()">
                                </h5>
                        </div>
                        <div class="modal-body text-center pt-4 mb-3">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
                                                aria-hidden="true">&times;</span> </button>
                                <h1 class="blue">{{popup_item.name}}</h1>
                                <div class="divder mb-3"> <img src="assets/img/icon/line_relive.svg"></div>
                                <div class="col-md-8 m-auto blue "> {{popup_item.description}} </div>
                        </div>
                        <div class="col-md-7 col-10 mt-4 m-auto blue border_blue sm_br padding_2">
                                <div class="col-md-12 d-flex mb-3 p-0">
                                        <div class="col-md-2 col-1 p-0"> <img src="assets/img/attend/genre.png"></div>
                                        <div class="col-md-10">{{popup_item.category.name}} </div>
                                </div>
                                <div class="col-md-12 d-flex mb-3 p-0">
                                        <div class="col-md-2 col-1 p-0"> <img src="assets/img/attend/calendar.png">
                                        </div>
                                        <div class="col-md-10">{{popup_item.date_line}} | {{popup_item.time_line}}
                                        </div>
                                </div>
                                <div class="col-md-12 d-flex  p-0">
                                        <div class="col-md-2 col-1 p-0"> <img src="assets/img/attend/map.png"></div>
                                        <div class="col-md-10">{{popup_item.location}} </div>
                                </div>
                        </div>
                </div>
        </div>
</div>
<!--attend  End -->

<section *ngIf="!helper.getObjectLength(events)">
        <div> <img src="assets/img/banner/Coming soon.png" class="w-100" /> </div>
</section>

<section>
        <div [routerLink]="['/relive']" class="show_hand"> <img class="w-100"
                        src="assets/img/footer_banner/{{'lang'|translate}}/attend.png"> </div>
</section>