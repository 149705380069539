<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
            <div class="row">
                    <ol class="breadcrumb">
                           
                            <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome' | translate}}</a></li>
                        <li class="breadcrumb-item">{{'Youarein' | translate}}:</li>
                    </ol>
                    <li class="breadcrumb-item active" aria-current="page">&gt; {{"MEDIA"|translate}}</li>
            </div>
    </div>
</nav>
<!--Banner -->
<section>
        <div> <img class="w-100" src="assets/img/banner/{{'lang'|translate}}/banner3.jpg"> </div>
      </section>
<!--Banner End-->
<section id="bg_design" class=" slide2 ">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <h1 class="text-center blue">{{"PressKit"|translate}}</h1>
                            <div class="divder mb-3"> <img src="assets/img/icon/line.svg"> </div>
                    </div>
            </div>
            <div class="row mt-4 d-flex align-items-center">
                    <div class="col-md-6 blue m-auto text-center"> {{"PRESS_KIT_SUB_LINE"|translate}} </div>
                    <div class="col-md-12 mt-5 text-center">
                             <a href="assets/vr-press-kit-english.pdf" target="_blank" *ngIf="lang === 'en'" class="btn_lg_2">{{"DownloadPressKit"|translate}}</a>
                             <a href="assets/vr-press-kit-hindi.pdf" target="_blank" *ngIf="lang !== 'en'" class=" btn_lg_2">{{"DownloadPressKit"|translate}}</a>
                </div>
            </div>
    </div>
</section>
<section id="bg_design_2" class="media slide2 white_slide">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <h1 class="text-center white">{{"PressCoverage" | translate}}</h1>
                            <div class="divder mb-3"> <img src="assets/img/icon/line_white.svg"> </div>
                            <h6 class="white1 mb-4">{{"PressConverage_sub_line"|translate}}</h6>
                    </div>
            </div>
            <div class="row text-center">
                    <div class="col-md-12 p-0">
                            <ul class="nav nav-tabs col-md-4 m-auto mb-4" id="media_tab" role="tablist">
                                    <li class="nav-item" *ngFor="let item of years; let isOdd=odd" [class.d-flex]="isOdd" role="presentation">
                                             <a class="nav-link  blue active" id="watch-tab" data-toggle="tab" href="javascript::void()" (click)="filter(item)" role="tab" aria-controls="watch" aria-selected="true">{{item}}</a>
                                         </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade active show" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                        <!--<div class="row justify-content-center tab_relive mb-4"> <a href="#" class="white_btn font-weight-bold pl-5 pr-5 active">Digital </a> <a href="#" class="white_btn  pl-5 pr-5">Video</a>  <a href="#" class="white_btn  pl-5 pr-5">Print Media</a>   </div>-->	<div class="row">
                                                    <div class="col-md-9 p-0 m-auto">
                                                            <div class="scroll_gallery row">
                                                                    <!-- Item Start -->
                                                                    <div class="col-md-6 col-6 margin_bottom" *ngFor="let item of press">
                                                                            <div id="icon_play" class="bg-white lg_br padding_1 box_shadow pb-3">
                                                                                    <figure><img [src]="cover(item)" class="img_br"> </figure>
                                                                                    <!-- <h5> {{item.title}}</h5> -->
                                                                                    <p>{{item.published_by}}</p>
                                                                                    <p class="mb-3">{{formated_date(item.publishing_at)}}</p>
                                                                                    <a target="_blank" *ngIf="item.link != null" [routerLink]="item.link" class=" btn_sm_4 mt-3">{{"ViewFullArticle"|translate}} </a>

                                                                                    <a target="_blank" *ngIf="item.link == null" href="{{getDocLink(item)}}" class=" btn_sm_4 mt-3">{{"ViewFullArticle"|translate}} </a>
                                                                            </div>
                                                                    </div>
                                                                    <!-- Item End -->
                                                          
                                                            </div>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">Listen</div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">Read</div>
                            </div>
                    </div>
            </div>
    </div>
</section>
<!-- The path to art begins here. Start -->
<!-- <section id="artist_bg">
    <div class="container-fluid design_corner">
            <div class="row flex-nowrap">
                    <div class="col-md-6 p-0 "> <img src="assets/img/icon/design.svg" class="float-left pl-1 pt-1"> </div>
                    <div class="col-md-6 p-0 "> <img src="assets/img/icon/design2.svg" class="float-right pr-1 pt-1"></div>
            </div>
    </div>
    <div class="container">
            <div class="row text_text flex-nowrap">
                    <div class="col-md-5 col-3 text-center"> </div>
                    <div class="col-md-7 col-9 text-center">
                            <h1 class="white">The path to art begins here.</h1>
                            <h4 class="white">Share the details of your favourite new artist below </h4>
                            <a href="#" class="btn_lg_5"> UPLOAD</a> </div>
            </div>
    </div>
    <div class="container-fluid design_corner">
            <div class="row flex-nowrap">
                    <div class="col-md-6 p-0 "> <img src="assets/img/icon/design4.svg" class="float-left pl-1 pb-1"> </div>
                    <div class="col-md-6 p-0 "> <img src="assets/img/icon/design3.svg" class="float-right pr-1 pb-1"> </div>
            </div>
    </div>
</section> -->
<!-- The path to art begins here. End -->


<section>
        <div [routerLink]="['/attend']" class="show_hand"> <img class="w-100" src="assets/img/footer_banner/{{'lang'|translate}}/media.png"> </div>
      </section>