import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  email:string = '';
  subscribed:boolean =false;

  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
  }

  newsletter(){

    this.apiService.postData('/newsletter',{email:this.email}).subscribe((res:any)=>{
      this.subscribed = true;
    },
    (error:any)=>{
         alert(error.error.errors.email[0]);
    })
  }

}
