import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { HelperService } from 'src/app/services/helper/helper.service';
import { InitAppService } from 'src/app/services/init-app.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { WatchService } from 'src/app/services/watch.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-relive',
  templateUrl: './relive.component.html',
  styleUrls: ['./relive.component.css']
})
export class ReliveComponent implements OnInit {
  @ViewChild('owlElement') owlElement?: OwlCarousel;
  watch:any = [];
  listen:any = [];
  read:any = [];
  years:any = [];
  events:any = [];
  active_tab = 'watch';
  active_year:string  = "";
  active_event:string = "";
  gallery_filter_year:string= "";
  gallery_filter_event:string= "";
  isLoggedIn:boolean = false;
  media:any = {years:[],events:[],content:[]};
 
  constructor(public helper:HelperService,private initApp:InitAppService,private apiService:ApiService,public watchService:WatchService,private tokenService:TokenService,public readService:ReadService,public listenService:ListenService){ }

  ngOnInit(): void{
       this.loadWatch();
       this.loadMedia();
       if(this.tokenService.isLoggedIn()){
         this.isLoggedIn = true;
       }

       this.initApp.initMediaScroll();
   } 

   loadWatch(){
   this.active_event = '';
   this.active_year = '';
   this.watchService.getList([]).then((data: any)=>{
      this.watch = data;
      var t = this;
      setInterval(function(){ t.initApp.initApp();},100); 
    }).catch((error:any)=>{
    });

    this.watchService.getPastYearsEvents().then((data:any)=>{
      this.years = data.years; 
      this.events = data.events;
    });
   }

   
  switchListenFav(item:any){
    this.apiService.switchFav('listen',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchReadFav(item:any){
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchWatchFav(item:any){
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  loadMedia(){
    
    this.apiService.getData('/media?year='+this.gallery_filter_year+'&event='+this.gallery_filter_event).subscribe(
      result => {
         this.media = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  
  filter_gallery(val:any,type:string){

    if(type == 'year'){
     this.gallery_filter_year = val;
    }
    else{

     this.gallery_filter_event = val;
    }

    this.loadMedia();
         }

   loadListen(){
    this.active_tab = 'listen';
    this.active_event = '';
    this.active_year = '';
    this.listenService.getList([]).then((data: any) => {
       this.listen = data;
       var t = this;
       setInterval(function(){ t.initApp.initApp();},100); 
     });
 
     this.listenService.getPastYearsEvents().then((data:any)=>{
       this.years = data.years;
       this.events = data.events;
     });
   }

   loadRead(){
    this.active_tab = 'read';
    this.active_event = '';
    this.active_year = '';
    this.readService.getList([]).then((data: any) => {
       this.read = data;
       var t = this;
       setInterval(function(){ t.initApp.initApp();},100); 
     });
 
     this.readService.getPastYearsEvents().then((data:any)=>{
       this.years = data.years;
       this.events = data.events;
     });
   }

   switchFav(item:any){
       this.watchService.switchFav(item); 
   }

   getWatchImg(item:any){
     return this.watchService.getImg(item);
   }

   getMediaImg(item:any){
    return this.watchService.getMediaImg(item);
  }
   
   getReadImg(item:any){
    return this.readService.getImg(item);
  }

   getListenImg(item:any){
    return this.listenService.getImg(item);
  }

 


   
   filter_(event:any,type:string){

     if(type == 'year'){
      this.active_year = event;
     }
     else{

      this.active_event = event;
     }
     
    if(this.active_tab == 'watch'){
            this.watchService.getList(new Array('year='+this.active_year,'event='+this.active_event)).then((data: any) => {   
                this.watch = data;   
                  
               });   
           }

           if(this.active_tab == 'read'){
            this.readService.getList(new Array('year='+this.active_year,'event='+this.active_event)).then((data: any) => {   
                this.read = data;   
                  
               });   
           }

           if(this.active_tab == 'listen'){
            this.listenService.getList(new Array('year='+this.active_year,'event='+this.active_event)).then((data: any) => {   
                this.listen = data;   
                  
               });   
           }
          }

}
