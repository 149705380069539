import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { InitAppService } from 'src/app/services/init-app.service';

@Component({
  selector: 'app-fan-messages',
  templateUrl: './fan-messages.component.html',
  styleUrls: ['./fan-messages.component.css']
})
export class FanMessagesComponent implements OnInit {

  @ViewChild('owlFanMessages') owlElement?: OwlCarousel;
  constructor(public initApp:InitAppService) { 
    this.initApp.initApp();
  }

  ngOnInit(): void {
    this.initApp.initApp();
    this.initApp.initMediaScroll();
  }

}
