import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from 'angularx-social-login';
import { LoginService as LoginEvent} from 'src/app/services/event/login.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { SearchService } from 'src/app/services/search/search.service';
import { WatchService } from 'src/app/services/watch.service';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from '../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { SignupTwoComponent } from '../signup-two/signup-two.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logged_in = false;
  userimage:string = "";
  user:any = [];

  search_artists:any = [];
  search_watch:any = [];
  search_read:any = [];
  lang = 'en';
  search_listen:any = [];
  word:string = '';

  constructor(private translate: TranslateService,private searchService:SearchService,public watchService:WatchService, public listenService:ListenService, public readService:ReadService, private socialAuthService:SocialAuthService,private apiService:ApiService, private auth:AuthService,private login:LoginEvent,private tokenService:TokenService,private router:Router) {

    router.events.subscribe((val) => {
      // see also 
      this.word = '';
  });

    if(localStorage.getItem('lang') != undefined){
      var l:string = localStorage.getItem('lang')+'';
      this.translate.use(l);
      this.lang = l;
      this.apiService.lang = l;
    }
    else{
      this.lang = translate.getDefaultLang();
    }

  if(this.tokenService.isLoggedIn()) {
     this.logged_in = true;
    }
  }

  isEmptyObject(obj:any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ObjectLength(obj:any){
    return Object.keys(obj).length;
  }

  switchLang(ln:string){
   let lang = this.translate.currentLang;
   this.translate.use(ln);
   localStorage.setItem('lang',ln);
   this.apiService.lang = ln;
   if(this.router.url.startsWith('/team')){
    this.router.navigateByUrl('/about-us');
  }
  else{
    this.router.navigateByUrl('/');
  }

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };
    //  window.location.reload()
    this.lang = this.lang == 'en'?'hi':'en';
  }

  search(){

    if(this.word.length > 2){
  
    this.searchService.search(this.word).then((data:any)=>{
  
      this.search_watch = data.watch.data;
      this.search_read = data.read.data;
      this.search_listen = data.listen.data;
    })
  }
  }

  ngOnInit(): void {
    this.login.afterLogin.subscribe((LoggedIn) => { 
      this.auth.profileUser().subscribe(
      result => {
        this.user =result;
        console.log(result); 
        // this.userimage = result.image;
      if(result.status == false){   
         this.tokenService.removeToken();
         this.logged_in = false;
      }
      else{
        if(Object.keys(result).length){
          if(result.interests == null){
             this.router.navigateByUrl('/register-step-2');
          }
        }
      }
      },
      error=>{
      } 
    )
    if(this.tokenService.isLoggedIn()) {
      this.logged_in = true;
     }else{
      this.logged_in = false;
     }
    });

    this.auth.profileUser().subscribe(
      result => {
        this.user =result;
        // this.userimage = result.image;
      if(result.status== false){   
         this.tokenService.removeToken();
         this.logged_in = false;
      }else{
        if(Object.keys(result).length){
          if(result.interests == null){
             this.router.navigateByUrl('/register-step-2');
          }
        }
      }
      },
      error=>{

      } 
    )
    if(this.tokenService.isLoggedIn()) {
      this.logged_in = true;
     }else{
      this.logged_in = false;
     }
  }

  Logout(){
    this.tokenService.removeToken();
    this.logged_in = false;

    this.login.afterLogin.emit("LoggedOut");
    this.socialAuthService.signOut();
    this.router.navigate(['/']);
  }

  getDP(url:string){
    if(url != undefined || url != null){
      if(url.startsWith("http")){
        return url;
      }
     
    else{
     return this.apiService.storage+"/"+url;
    }
  }
  return url;
}

}