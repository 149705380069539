import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-signup-two',
  templateUrl: './signup-two.component.html',
  styleUrls: ['./signup-two.component.css']
})
export class SignupTwoComponent implements OnInit {
  registerForm = this.formBuilder.group({
    'data':new FormControl([], [Validators.required]),
   });

   interest_list:any = [];
   my_interests:any = [];
   user_data:any = [];
  //  checked:any = [];
   
  constructor(private auth:AuthService,private tokenService:TokenService, private formBuilder:FormBuilder,private apiService:ApiService,private router:Router){ 

    this.registerForm = this.formBuilder.group({
      checkArray: this.formBuilder.array([]),
      data:new FormControl([], [Validators.required]),
    })
  }

  checkValue(value:string,arr:any){
    // const checkArray: FormArray = this.registerForm.get('checkArray') as FormArray;
    var status = false;
    // console.log(arr);

    for(var i=0; i<arr.length; i++){
      var name = arr[i];
      if(parseInt(name) == parseInt(value)){
        this.my_interests.push(value);
        // console.log(parseInt(name), parseInt(value));
        // checkArray.push(new FormControl(parseInt(value)));   
        status = true;
        break;
      }
    }
    return status;
  }

  ngOnInit(): void {
    if(!this.tokenService.isLoggedIn()) {
      this.router.navigate(['/login']);
     }

     this.auth.profileUser().subscribe(
      result => {
        if(result.interests == null){
          result.interests = [];
        }
        this.user_data = result;
        // console.log(result);
        
        const pluck = (arr:any, key:any) => arr.map((i:any) => i[key]);
        
        this.my_interests = pluck(result.interests,'id');
        console.log(  this.my_interests);
      if(result.status== false){
         this.tokenService.removeToken();
      }else{
        if(Object.keys(result).length){
          if(result.interests == null){
            //  this.router.navigateByUrl('/register-step-2');
          }}}},
      error=>{});

      this.apiService.getData('/interests').subscribe(
        result => {
          this.interest_list = result;
        },
        error => {
        },
        () => {
        }
      )
  }

  removeItemAll(arr:any, value:any) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  onCheckboxChange(e:any) {
    // const checkArray: FormArray = this.registerForm.get('checkArray') as FormArray;
    let target_val = e.target.value;
    if (e.target.checked) {
      // checkArray.push(new FormControl(e.target.value));
      this.my_interests = this.my_interests.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
      this.my_interests.push(parseInt(target_val));
    } else {
      this.my_interests = this.my_interests.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
      this.my_interests = this.removeItemAll(this.my_interests,parseInt(target_val));
     
      let i: number = 0;
      // checkArray.controls.forEach((item) => {
      //   if (parseInt(item.value) == parseInt(e.target.value)) {
      //     checkArray.removeAt(i);
      //     return;
      //   }
      //   i++;
      // });
    }
  }

  onSubmit() {
    this.my_interests = this.my_interests.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
    console.log(this.my_interests);
  //  let checkArray = this.registerForm.value.checkArray.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
  //  console.log(checkArray);
    this.apiService.postData('/auth/register-step-2',{checkArray:this.my_interests}).subscribe(
      result => {
      },
      error => {
      },
      () => {
        this.router.navigate(['/profile']);
      }
    )
  }
}
