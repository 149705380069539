import { Component, OnInit } from '@angular/core';
import { InitAppService } from 'src/app/services/init-app.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {

  display_award:any = {award:{},cover:{}};
  awards:any  = [{award:{},cover:{}}];
  awards_filter_params:any = [];
  gallery_filter_year: any="";
  gallery_filter_event: any="";
  awards_filter_params_years:any = [];
  constructor(private initApp:InitAppService,private api:ApiService) { }

  ngOnInit(): void {
    this.getAwards();
    this.getAwards_filter_params();
   
  }
  
  getAwards_filter_params(){ 
    this.api.getData('/awards_filter_params?year='+this.gallery_filter_year).subscribe(res=>{
      this.awards_filter_params_years = res.years;
      this.awards_filter_params = res.all;
    },
    error=>{
        
    })
  }

  getAwards(){ 
    this.api.getData('/awards?year='+this.gallery_filter_year+'&event='+this.gallery_filter_event).subscribe(res=>{
  
      this.awards = res;
      this.display_award = res[0];
      let t =this;
      setTimeout(function(){
        t.initApp.award();
      },1000);
    },
    error=>{
        
    })
  }

  filter_gallery(val:any,type:string){

    if(type == 'year'){
     this.gallery_filter_year = val;
     this.getAwards_filter_params();
     this.gallery_filter_event = '';
    }
    else{
     this.gallery_filter_event = val;
    }
    this.getAwards();
  }

  cover(data:any){
    try{
      return this.api.storage+"/project/images/award_gallery/"+data.id+"/"+data.cover.path;
    
    }
    catch(e){
      return null;
    } 
  }

}
