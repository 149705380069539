<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12"><div class="row"><ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li> 
            <li class="breadcrumb-item"><a [routerLink]="['/artists']">&gt; {{"ARTISTS"|translate}} </a></li>
    </ol>
    <li class="breadcrumb-item active" aria-current="page">&gt; {{artist_data.name}}</li></div> </div>
</nav>
<section id="bg_design" class=" p-0 country_ban">
    <div class="container pl-0">
            <div class="row flex-nowrap">
                    <div class="col-md-6 pl-0"> <img [src]="getImg()" class="">  </div>
                    <div class="col-md-6 p-0 text-center">
                            <div class="col-auto p-0 h-100 d-flex flex-column justify-content-center">
                                    <div class="col-auto pl-0">
                                            <h1 class="blue">{{artist_data.name}}</h1>

<!--												<h5>– Director, VishwaRang, India</h5>
-->												<div class="divder"> <img src="assets/img/icon/line.svg"></div>
                                            <p class="blue">{{artist_data.short_description}}</p>
                                            <div class="pt-2 addthis_inline_share_toolbox"></div>

                                    </div>
                            </div>
                    </div>
            </div>
    </div>
</section>
<!-- Gallery start -->
<section id="bg_design_2"  *ngIf="watch?.length > 0 || listen?.length > 0 || read?.length > 0" class="container-fluid slide2 white_slide">
    <div class="container">
            <div class="row">
                    <div class="col-md-12">
                            <h1 class="text-center white">{{"Gallery"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                            <ul class="nav nav-tabs flex-nowrap mb-4" id="comman_tab" role="tablist">
                                    <li *ngIf="watch?.length > 0" class="nav-item" role="presentation"> <a class="nav-link white active" id="watch_a-tab" data-toggle="tab" href="#watch_a" role="tab" aria-controls="watch_a" aria-selected="false">{{"WATCH"|translate}}</a> </li>
                                    <li *ngIf="listen?.length > 0" class="nav-item" role="presentation"> <a class="nav-link white" id="listen_a-tab" data-toggle="tab" href="#listen_a" role="tab" aria-controls="listen_a" aria-selected="false">{{"LISTEN"|translate}}</a> </li>
                                    <li *ngIf="read?.length > 0" class="nav-item" role="presentation"> <a class="nav-link white" id="listen_a-tab" data-toggle="tab" href="#read_a" role="tab" aria-controls="listen_a" aria-selected="false">{{"READ"|translate}}</a> </li>

                                </ul>
                            <div class="tab-content" id="myTabContent">
                        	<div class="tab-pane fade show active" id="watch_a" role="tabpanel" aria-labelledby="watch-tab">
												
                                        <div class="">
                                                <owl-carousel class="watch_gallery owl-carousel owl-theme owl-loaded owl-drag text-center"></owl-carousel>
                                                        <div class="watch_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                        <div class="container" *ngFor="let one of watch">
                                                                                        <div class="row justify-content-center">
                                                                                                        <!-- item start -->
                                                                                                        <div  *ngFor="let item of one" id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                                                        <figure [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"><img [src]="getWatchImg(item)" class="img_br">
                                                                                                                                        <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                                                                        </figure>
                                                                                                                        <h5 [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                                        <p [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                                                                        <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                                                                        <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"Play"|translate}}</a>
                                                                                                                                <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                                <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                </div>
                                                                                                </div>

                                                                                                        </div>
                                                                                                        <!-- item end -->
                                                                                        </div>
                                                                        </div>
                                                           
                                                        </div>
                                                        <!-- <div class="col-md-12 text-center">
                                                                        <button class="btn_lg_3">View All </button>
                                                        </div> -->
                                         
                                        </div>
                                        <!-- watch slide start-->
                                        <!-- watch slide end -->
                        </div>
                        <div class="tab-pane fade" id="listen_a" role="tabpanel" aria-labelledby="listen-tab">
                                <owl-carousel class="listen owl-carousel owl-theme owl-loaded owl-drag text-center">
                                </owl-carousel>              
                                <div class="listen owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                          <div class="container"  *ngFor="let one of listen">
                                                                                                                          <div class="row justify-content-center">
                                                                                                                                                          <!-- item start -->
                                                                                                                                                          <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                                                                                                                          <figure><img [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }"  [src]="getListenImg(item)" class="img_br">
                                                                                                                                                                                                                          <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                                                                                                                          </figure>
                                                                                                                                                                                          <h5 [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" >{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>
                                                                                                                                                                                          <p [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" >{{item.information|slice: 0: 80}}</p>
                                                                                                                                                                                          <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                                                                                          <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{"LISTEN"|translate}}</a>
                                                                                                                                                                                                                          <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                                                                                                <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                                                                                </div>
                                                                                                                                                                                          </div>
                                                                                                                                                          </div>
                                                                                                                                                          <!-- item end -->
                                                                                                                                                          </div>
                                                                                          </div>
                                                                                   
                                                          </div>
                        </div>
                        <div class="tab-pane fade" id="read_a" role="tabpanel" aria-labelledby="read-tab">
                                <owl-carousel class="read_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                </owl-carousel>     
                                <div class="read_gallery owl-carousel owl-theme owl-loaded owl-drag text-center">
                                                                                <div class="container"  *ngFor="let one of read">
                                                                                                                <div class="row justify-content-center">
                                                                                                                                                <!-- item start -->
                                                                                                                                                <div id="icon_play" *ngFor="let item of one"  class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                                                                                                <figure> <img [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" [src]="getReadImg(item)" class="img_br"> </figure>
                                                                                                                                                                                <h5 [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                                                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                                                                                                                 <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                                                                                                                                                 <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                                                                                                                <label (click)="switchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                                                                                                </div>
                                                                                                                                                                                </div>
                                                                                                                                                </div>
                                                                                                                                                <!-- item end -->
                                                                                                                                 
                                                                                                                </div>
                                                                                </div>
                                                                          
                                                </div>
                        </div>
                        </div>
                    </div>
            </div>
    </div>
</section>
<!--Gallery End -->
<section>

    <div class="container pt-4 pb-4" *ngIf="!show_message_section && isLoggedIn">
            <div class="row">
                    <div class="col-md-12 text-center" >
                            <h5 class="blue"> {{'Sendanotetoyourfavouriteartist'|translate}} </h5>
                    </div>
                    <div class="col-md-12 mt-4 mb-3 text-center">
                            <button (click)="show_message_section = true" class=" btn_lg_1 white1 bg-blue border-0 pl-4 pr-4"><strong>{{"Addmessage"|translate}} </strong></button>
                    </div>
            </div>
    </div>

    <div class="row pb-5" id="registration_form" *ngIf="show_message_section && !message_sent">
        <div class="col-md-10 bg-white p-0 d-flex m-auto lg_br box_shadow">
                <div class="col-md-7 offset-md-2 pt-3">
                        <h5 class="blue"> {{'Sendanotetoyourfavouriteartist'|translate}} </h5>
                        <p class="form_error" *ngFor="let error of errors | keyvalue">{{error.value}}</p>
                        <br>
                        <form [formGroup]="fanMessageForm"  (ngSubmit)="sendFanMessage()">
                                <div class="form-row"> 
                                  <div class="form-group col-md-12">
                                   <textarea class="form-control" rows="10" [(ngModel)]="message" placeholder="Your Message" formControlName="message"></textarea>
                                 </div>
                           <div class="form-group col-md-12">
                                  <input class="form-control" #imageInput type="file" accept="image/*,video/*" (change)="processFile(imageInput)">
                             </div>
                          </div>
 
                                <div class="text-center col-md-12">
                                        <button type="submit" class="btn_lg_4 bg-blue white">{{message_state}}</button>
                                </div>
                              
                              
                        </form>
                </div>
                <!-- <div class="col-md-5 pr-0 d-none d-print-block d-lg-block "> <img src="assets/img/banner/banner_registration.jpg" class="lg_img_tr lg_img_br"></div> -->
        </div>
        <!-- item end -->
</div>
<div class="row pb-5" id="registration_form" *ngIf="message_sent">
        <h5 class="text-center w-100 mt-5">Your Message sent successfully.</h5>
</div>
</section>
