import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { LoginService } from 'src/app/services/event/login.service';
import { ApiService } from 'src/app/shared/api.service';
import { AuthStateService } from 'src/app/shared/auth-state.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
 
  errors:any =[];
  country:any = [];
  state:any = [];

  registerForm = this.formBuilder.group({
  'first_name':new FormControl("", [Validators.required,Validators.minLength(4)]),
  'last_name':'',
  'email':new FormControl("", [Validators.required,Validators.minLength(4)]),
  'password':new FormControl("", [Validators.required,Validators.minLength(4)]),
  'phone':new FormControl("", [Validators.required,Validators.minLength(4)]),
  'gender':new FormControl("", [Validators.required,Validators.minLength(2)]),
  'country':new FormControl("", [Validators.required]),
  'state':new FormControl("", [Validators.required]),
  "country_code":new FormControl("", [Validators.required]),
  'dob':new FormControl("", [Validators.required,Validators.minLength(4)]),
  'city':new FormControl("", []),
 });

  constructor(private loginEvent:LoginService,private authState:AuthStateService,private token:TokenService,private so:SocialAuthService, private formBuilder:FormBuilder,private tokenService:TokenService,private api:ApiService,private authService:AuthService,private router:Router) { }

  ngOnInit(): void {
    if(this.tokenService.isLoggedIn()) {
      this.router.navigate(['/']);
     }
     
    this.api.getData('/word_country').subscribe(
      result => {
        this.country = result.data.country;
      
      },
      error=>{

      });
  }

  coutryChanged(event:any){
    this.api.getData('/word_state/'+event.target.value).subscribe(
      result => {
        this.state = result.data.state;
      },
      error=>{
      });
  }

  responseHandler(data:any){
    this.token.handleData(data.access_token);
  }

  signInWithFB(): void {
    this.so.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle(): void {
    const googleLoginOptions = {
      scope: 'profile email'
    };
    this.so.signIn(GoogleLoginProvider.PROVIDER_ID,googleLoginOptions).then((res: any) => {    
    
      this.api.postData('/auth/social',res).subscribe(
        result => {
          this.responseHandler(result);        
        },
        error => {
          // this.error = true;
        },() => {
          this.authState.setAuthState(true);
          this.router.navigate(['profile']);
          this.loginEvent.afterLogin.emit('LoggedIn');
        });
    });
  }
  

  // onSubmit(): void {
  //   // Process checkout data here
  //   console.warn('Your order has been submitted', this.registerForm.value);
  //   this.registerForm.reset();
  // }

  onSubmit() {
    this.authService.register(this.registerForm.value).subscribe(
      result => {
      },
      error => {
        this.errors = JSON.parse(error.error);
        // this.errors = error.error;
      },
      () => {
        // this.registerForm.reset()
        this.router.navigate(['login'], { state: { welcome_user: '1' } });
        // this.router.navigate(['register-step-2']);
      }
    )
  }

}
