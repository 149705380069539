import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TokenService } from 'src/app/shared/token.service';
import { HeaderComponent } from '../../components/header/header.component';
import { InitAppService } from '../../services/init-app.service';
import { ApiService } from '../../shared/api.service';
import {OwlCarousel} from 'ngx-owl-carousel';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.css']
})

export class WatchComponent implements OnInit {

  @ViewChild('owlElement') owlElement?: OwlCarousel;

  watch:any  =  [];
  isLoggedIn:boolean = false;
  featured:any  =  [];
  next_page_url = null;
  watch_more:any = [];
  featured_artists:any = [];
  artist_img_path:string = '';
  img_url = '';
  categories:any = [];
  constructor(private tokenService:TokenService,private titleService:Title,private head:HeaderComponent , private initApp:InitAppService,private apiService:ApiService) { 
    titleService.setTitle("Watch");
    this.img_url = this.apiService.storage+'/project/images/watch/';
    this.artist_img_path = this.apiService.storage+'/project/images/thumbnail/artist/';
  }

  ngAfterViewInit() {
    if(this.tokenService.isLoggedIn()){
      this.isLoggedIn = true;
     }

    this.apiService.getData('/interests?type=watch').subscribe(
      result => {
       return this.categories = result;
      },
      error => {
      },
      () => {
      }
    )
    this.initApp.initApp();
   
  }

  categoryChanged(event:any){
    this.apiService.getData('/watch?category='+event.target.value).subscribe(
      result => {
        
        this.watch = this.apiService.chunk(result.data.data,8);
        this.next_page_url = result.data.next_page_url;
        this.watch_more = [];
        // this.artists_pagination[0] = this.artists.data.links[0];
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  getArtistImg(artist:any){
    try{
      return this.artist_img_path + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  ngOnInit(): void {
      this.getWatch();
      this.getFeaturedArtists();
  }

  getImg(watch:any){
    try{
      return this.img_url + watch.thumb.project_id+'/'+watch.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  // load(url:any){
  //   this.apiService.geturlData(url).subscribe(
  //     result => {

  //       this.watch = result.data.data;
        
  //     },
  //     error => {
  //     },
  //     () => {
  //       // this.router.navigate(['']);
  //     }
  //   )
  // }

  getFeatured(){
    this.watch_more = [];
    this.apiService.getData('/watch?featured=1').subscribe(
      result => {
        this.next_page_url = result.data.next_page_url;
        this.featured = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initWatchSlider();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  getFeaturedArtists(){
    this.watch_more = [];
    this.apiService.getFeaturedArtists('Watch').subscribe(
      result => {
        this.featured_artists = this.apiService.chunk(result.data,8);
        setTimeout(() =>{this.initApp.initApp();},90);
      },
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  load(){
    // this.watch_more = [];
    let  url:any = this.next_page_url;
    this.apiService.geturlData(url).subscribe(
      result => {
        this.watch_more = this.watch_more?.concat(result.data.data);
        this.next_page_url = result.data.next_page_url;
      }, 
      error => {
      },
      () => {
        // this.router.navigate(['']);
      }
    )
  }

  switchFav(item:any){
    
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  getWatch(){
    this.watch_more = [];
    this.apiService.getData('/watch').subscribe(
      result => {
        this.next_page_url = result.data.next_page_url;
        this.watch = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initWatchSlider();},90);
      },
      error => {
      },
      () => {
      }
    )
  }

  orderByLatest(){
    this.watch_more = [];
    this.watch = [];
    this.apiService.getData('/watch?orderBy=DESC').subscribe(
      result => {
        this.watch = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initWatchSlider();},90);
      },
      error => {
      },
      () => {
      }
    )
  }

  orderByTrending(){
    this.watch_more = [];
    this.watch = [];
    this.apiService.getData('/watch?trending=1').subscribe(
      result => {
        this.watch = this.apiService.chunk(result.data.data,8);
        setTimeout(() =>{this.initApp.initWatchSlider();},90);
      },
      error => {
      },
      () => {
      }
    )
  }


  fixed_string(string:string,length:number){
   return this.apiService.fixed_string(string,length);
  }
}
