<nav aria-label="breadcrumb" id="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
    </ol>     
    <li class="breadcrumb-item active" aria-current="page">&gt; {{"SignUp"|translate}}</li>
</nav>

<section id="registration" class="registration container-fluid">
          <div class="container">
                  <div class="row text-center">
                          <div class="col-md-12">
                                  <h1 class="text-center blue">{{"Thank_you_for_signing_up"|translate}}!</h1>
                                  <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
                                  <h5 class=" d-none d-print-block d-lg-block"> Stay tuned for a host of cultural content from your favourite artists</h5>
                          </div>
                   </div>
                  <div class="row pb-5" id="registration_form">
                          <!-- item start -->
                          <div class="col-md-10 bg-white p-0 d-flex m-auto lg_br box_shadow">
                                  <div class="col-md-10 pt-3 text-center registration_sel" > 
                                  <h5>{{"Thank_you_for_signing_up_below"|translate}} </h5>
                                   <form  [formGroup]="registerForm"  (ngSubmit)="onSubmit()">

   <div class="form-group">
               <div class="items-collection justify-content-center">
                   <div class="items col-md-3 col-4" *ngFor="let interest of interest_list">
                      <div class="info-block block-info clearfix">
                          <div data-toggle="buttons" class="btn-group bizmoduleselect">
                              <label class="btn btn-default" [ngClass]="{'active': checkValue(interest.id,my_interests) === true}">
                                  <div class="itemcontent">
                                      <input type="checkbox" style="display: none;" (change)="onCheckboxChange($event)" formControlName="data" autocomplete="off" [value]="interest.id">
                                       <p>{{interest.name}}</p>
                                  </div> 
                              </label>
                          </div>
                      </div>
                  </div>
           
           <div class="text-center col-md-12">
    <button type="submit" class="btn_lg_4 bg-blue white">{{"Submit"|translate}}</button>
    </div>		  
              </div>
          </div>  
     
    
                                  </form>
                                  </div>
                                  <div class="col-md-2 pr-0 d-none d-print-block d-lg-block"> <img src="assets/img/banner/banner_registration2.jpg" class="lg_img_tr lg_img_br"></div>
                           
                          </div>
                          <!-- item end -->
                       
                  </div>
          </div>
  </section>
   