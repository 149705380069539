import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }


  // getDevicePathString(){
  //     const isMobile = this.deviceService.isMobile();
  //     const isTablet = this.deviceService.isTablet();
  //     const isDesktopDevice = this.deviceService.isDesktop();
  //     if(isMobile){ return 'mobile'; }
  //     if(isTablet){ return 'tablet'; }
  //     if(isDesktopDevice){ return 'desktop'; }

  //     return 'desktop';
  // }

  
  getStringLength(str:string){

    try{
      return str.length;
    }
    catch(e){
      return 0;
    }

    
  }


  getObjectLength(obj:any){
      var size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    
  }


  
}
