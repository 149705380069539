import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgAddToCalendarService } from '@trademe/ng-add-to-calendar';
import { OwlCarousel } from 'ngx-owl-carousel';
import { HelperService } from 'src/app/services/helper/helper.service';
import { InitAppService } from 'src/app/services/init-app.service';
import { ListenService } from 'src/app/services/listen/listen.service';
import { ReadService } from 'src/app/services/read/read.service';
import { TeamService } from 'src/app/services/team.service';
import { ApiService } from 'src/app/shared/api.service';
import { TokenService } from 'src/app/shared/token.service';
import { WatchService } from '../../services/watch.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';
declare const $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  @ViewChild('owlElement') owlElement?: OwlCarousel;
  @ViewChild('owlListen') owlListen?: OwlCarousel;
  @ViewChild('owlRead') owlRead?: OwlCarousel;
  categories:any = [];
  featured_artists:any = [];
  featured_watch:any = [];
  featured_read:any = [];
  featured_listen:any = [];
  events:any = []; 
  popup_item:any= {category:{}};
  google_calender:any = '';
  i_calender:any = '';
  yahoo_calender:any = '';
  country:any = [];
  playlist:any = [];
  isLoggedIn:boolean = false;
  category_img_url = this.apiService.storage+'/project/images/thumbnail/category/';
  artist_img_url = this.apiService.storage+'/project/images/thumbnail/artist/';
  playlist_img_url = this.apiService.storage+'/images/thumbnail/playlist';
  fragment:any;
  page_ready =false;
  banners = ['/assets/banners/sing.webp','/assets/banners/watch.webp','/assets/banners/read.webp','/assets/banners/overseas-indians.webp'];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay:true,
    dots: false,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText:['<','>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  constructor(private _addToCalendarService: NgAddToCalendarService,private translate:TranslateService,
    private _sanitizer: DomSanitizer,private route:ActivatedRoute, public helper:HelperService,private initApp:InitAppService,private apiService:ApiService,private teamService:TeamService,private tokenService:TokenService,public readService:ReadService,public listenService:ListenService,public watchService:WatchService) {
     
   }

  ngOnInit(): void {
    
    if(this.tokenService.isLoggedIn()){
      this.isLoggedIn = true;
     }
     
    this.getCategories();
   
    this.getFeaturedArtists();
    this.getPlaylist();

   

    this.teamService.getCountries().then(data=>{
      this.country = data;
      
   });

    this.readService.getFeatured(4).then(data=>{
      this.featured_read = data;
   });

    this.listenService.getFeatured(4).then(data=>{
      this.featured_listen = data;
   });
   this.loadEvents();
   let lang = localStorage.getItem('lang');
   if(lang == null){
    lang = this.translate.getDefaultLang();
   }
      this.banners = ['/assets/banners/'+lang+'/sing.webp',
      '/assets/banners/'+lang+'/watch.webp',
      '/assets/banners/'+lang+'/read.webp',
      '/assets/banners/'+lang+'/overseas-indians.webp'];
  }

  loadEvents(){
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June","July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.apiService.getData('/v_event').subscribe(res=>{
      // this.next_page_url= res.next_page_url;
      this.events = res.data.slice(0,2);
      let t = this;
      this.events.forEach(function (value:any, i:number) {
        t.events[i]['calender'] = t.getCalender(value);
        let s = monthNames[new Date(value.start_date).getMonth()]+' '+new Date(value.start_date).getDate();
        let e =  monthNames[new Date(value.end_date).getMonth()]+' '+new Date(value.end_date).getDate();
        t.events[i]['date_line'] = s +' - '+ e;
        if(s == e){
          t.events[i]['date_line'] = s;
        }
        t.events[i]['time_line'] = t.tConvert(value.start_time)+'-'+t.tConvert(value.end_time);
      });
    },
    error=>{

    })
  }
  tConvert (time:any) {
    // Check correct time format and split into components
    time =[];
    try{
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
      time[4] = '';
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    }
    catch(e){

    }
    return time.join (''); // return adjusted time or original string
  }

  getCalender(event:any){
   
  let newEvent = {
      // Event title
      title: event.name,
      // Event start date
      start: new Date('June 15, 2013 19:00'),
      // Event duration (IN MINUTES)
      duration: 120,
      // If an end time is set, this will take precedence over duration (optional)
      end: new Date('June 15, 2013 23:00'),
      // Event Address (optional)
      address: event.location,
      // Event Description (optional)
      description: event.description
  };
  
 this.google_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.google, newEvent));
 this.i_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.iCalendar, newEvent));
 this.yahoo_calender = this._sanitizer.bypassSecurityTrustUrl(this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.yahoo, newEvent));
  }

  popUp(item:any){
    this.popup_item = item;
  }

  return_cover(){
    let data = this.popup_item;
      try{
        return this.apiService.storage+"/project/images/v_event/"+data.id+"/"+data.cover.path;
      }
      catch(e){
        return null;
      } 
    }
  
return_thumb(data:any){
  try{
    return this.apiService.storage+"/project/images/thumbnail/category/"+data.category.id+"/"+data.category.thumb.path;  
  }
  catch(e){
    return null;
  }
 }

  ngAfterViewInit(){
    
    this.watchService.getFeatured(4).then(data=>{
      this.page_ready = true;
      this.featured_watch = data;
      this.initApp.initWatchSlider();
   });

   this.initApp.initApp();
   try {
      this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
      document.querySelector('#' + this.fragment)?.scrollIntoView();
    }
    catch(e){}
    // setTimeout(function(){
    //   $("#vid").attr("src","assets/img/banner/home.mp4");
    //   $("video")[0].load();
    // },2000);
  }
  
  getFlag(flag:any){
    return this.teamService.getFlag(flag);
  }

  getFeaturedArtists(){
    this.apiService.getFeaturedArtists('Homepage').subscribe(
      result => {
        this.featured_artists = result.data;
        this.featured_artists = this.apiService.chunk(this.featured_artists,4); 
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  switchListenFav(item:any){
 
    this.apiService.switchFav('listen',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchReadFav(item:any){
  
    this.apiService.switchFav('read',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  switchWatchFav(item:any){
    this.apiService.switchFav('watch',item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  getCategories(){
    this.apiService.getData('/category').subscribe(
      result => {
        this.categories = result.data.data;
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }

  
  getPlaylist(){
    this.apiService.getData('/recommended_playlist').subscribe(
      result => {
        this.playlist = result;
        // this.artists_pagination[1] = this.artists.data.links[this.artists.data.links.length-1];
      },
      error => {
      },
      () => {
      }
    )
  }


  getCatImg(category:any){
    try{
      return this.category_img_url + category.thumb.project_id+'/'+category.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  

  getArtistImg(artist:any){
    try{
      return this.artist_img_url + artist.profile_image.project_id+'/'+artist.profile_image.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

}
