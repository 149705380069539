import { LoginProvider, SocialUser } from "angularx-social-login";

 abstract class BaseLoginProvider implements LoginProvider {

    constructor() {
    }
    abstract initialize(): Promise<void>;
     getLoginStatus(loginStatusOptions?: any): Promise<SocialUser> {
         throw new Error("Method not implemented.");
     }

    // abstract initialize(): Promise<SocialUser>;

    abstract signIn(): Promise<SocialUser>;

    abstract signOut(): Promise<any>;

    loadScript(id: string, src: string, onload: any, inner_text_content = ''): void {
        if (document.getElementById(id)) {
            return;
        }

        let signInJS = document.createElement("script");
        signInJS.async = false;
        signInJS.src = src;
        signInJS.onload = onload;

        if (inner_text_content)
            signInJS.text = inner_text_content;

        document.head.appendChild(signInJS);
    }
}

export { BaseLoginProvider };
