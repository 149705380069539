import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  otp_sent:boolean = false;

  passwordForm = this.fb.group({
    email: [],
    password: []
  });

  
  setPasswordForm = this.fb.group({
    password: [],
    otp:[]

  });



  error:boolean= false;
  welcome_user:boolean = false;
  message:any = '';
  resetted:boolean = false;

  constructor(private fb:FormBuilder,private apiService:ApiService) { }

  ngOnInit(): void {
  }

  onSubmit(){
this.apiService.postData('/reset-password',this.passwordForm.value).subscribe((res:any)=>{
    this.otp_sent = true;
},(error:any)=>{
  console.log(error);
})

  }

  setPassword(){
   this.apiService.postData('/set-password',{email:this.passwordForm.value.email,password:this.setPasswordForm.value.password,otp:this.setPasswordForm.value.otp}).subscribe((res:any)=>{
    if(res.success){
      this.resetted = true;
    }
    this.message = res.message;
},(error:any)=>{
  console.log(error);
})
  }
}
