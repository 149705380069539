import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SigninComponent } from './components/signin/signin.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { SignupTwoComponent } from './components/signup-two/signup-two.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import {TranslateModule} from '@ngx-translate/core';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { ArtistsComponent } from './pages/artists/artists.component';
import { HomeComponent } from './pages/home/home.component';
import { SocialLoginModule, SocialAuthServiceConfig, SocialAuthService } from 'angularx-social-login';
import { CarouselModule } from 'ngx-owl-carousel-o';

import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { WatchComponent } from './pages/watch/watch.component';
import { ListenComponent } from './pages/listen/listen.component';
import { ReadComponent } from './pages/read/read.component';
import { WatchDetailComponent } from './pages/watch-detail/watch-detail.component';
import { ArtistPageComponent } from './pages/artist-page/artist-page.component';
// import { LinkedInLoginProvider } from './services/linkedIn-login-provider';
import { OwlModule } from 'ngx-owl-carousel';
import { ListenInfoComponent } from './pages/listen-info/listen-info.component';
import { ReadInfoComponent } from './pages/read-info/read-info.component';
import { AttentComponent } from './pages/attent/attent.component';
import { ReliveComponent } from './pages/relive/relive.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { PressComponent } from './pages/press/press.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { PrivacyPolicyComponent } from './static/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './static/pages/terms/terms.component';
import { PartnerCountryComponent } from './pages/partner-country/partner-country.component';
import { RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NominationComponent } from './pages/nomination/nomination.component';
import { FreshTalentComponent } from './pages/fresh-talent/fresh-talent.component';
import { EngageComponent } from './pages/engage/engage.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { PlaylistComponent } from './pages/playlist/playlist.component';
import { FanMessagesComponent } from './components/fan-messages/fan-messages.component';
import { WatchReadListenComponent } from './components/watch-read-listen/watch-read-listen.component';
import { WatchReadListenFreshComponent } from './components/watch-read-listen-fresh/watch-read-listen.component';
import { FaqComponent } from './static/pages/faq/faq.component';
import { CategoryComponent } from './pages/category/category.component';
import { ThankYouComponent } from './static/pages/thank-you/thank-you.component';
import { GtagModule } from 'angular-gtag';
import { ExhibitionSliderComponent } from './pages/exhibition-slider/exhibition-slider.component';
import { PaintingComponent } from './pages/painting/painting.component';
import { PaintingDetailComponent } from './pages/painting-detail/painting-detail.component';
import { PaintingCategoryComponent } from './pages/painting-category/painting-category.component';
import { PaintingItemComponent } from './pages/painting-item/painting-item.component';
// import { LightboxModule } from 'ngx-lightbox';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent, 
    HeaderComponent,
    FooterComponent,
    SigninComponent,
    UserProfileComponent,
    SignupTwoComponent,
    WatchReadListenFreshComponent,
    ArtistsComponent,
    HomeComponent,
    RegisterComponent,
    WatchComponent,
    ListenComponent,
    ReadComponent,
    WatchDetailComponent,
    ArtistPageComponent,
    ListenInfoComponent,
    ReadInfoComponent,
    AttentComponent,
    ReliveComponent,
    AwardsComponent,
    PressComponent,
    ForgetPasswordComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    PartnerCountryComponent,
    AboutUsComponent,
    NominationComponent,
    FreshTalentComponent,
    EngageComponent,
    ComingSoonComponent,
    PlaylistComponent,
    FanMessagesComponent,
    WatchReadListenComponent,
    FaqComponent,
    CategoryComponent,
    ThankYouComponent,
    ExhibitionSliderComponent,
    PaintingComponent,
    PaintingDetailComponent,
    PaintingCategoryComponent,
    PaintingItemComponent
  ],
  imports: [
    OwlModule,
    FormsModule,
    GtagModule.forRoot({ trackingId: 'GTM-TCQQ4X5', trackPageviews: true }),
    BrowserModule,
    NgAddToCalendarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    RouterModule,
    HttpClientModule
  ],
  providers: [
    OwlModule,AuthGuardService,SigninComponent,HeaderComponent,SocialAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
        //   {
        //     id: LinkedInLoginProvider.PROVIDER_ID,
        //     provider: new LinkedInLoginProvider("783hlt6bpp8qbe")
        // },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('298177863609-ad22mjn4sr5tfqoon2i0jnggtjpodgjq.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ]
      } as SocialAuthServiceConfig,
      
    },
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

