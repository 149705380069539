<section id="bg_design_2" class="container-fluid slide2 white_slide">
    <div class="container">
            <div class="row"> 
                    <div class="col-md-12">
                            <h1 class="text-center white">{{"FRESH_TALENT"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                            <ul class="nav nav-tabs mb-4" id="comman_tab" role="tablist">
                                    <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" aria-selected="false">{{'WATCH'|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" href="#listen" role="tab" aria-controls="listen" aria-selected="false">{{'LISTEN'|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#read" role="tab" aria-controls="read" aria-selected="false">{{'READ'|translate}}</a> </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                    <!-- watch slide start-->
                                    <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                        <owl-carousel #owlElement [carouselClasses]="['owl-them','watch_gallery', 'slidng']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_watch">
                                                <div class="container" *ngFor="let one of featured_watch"> 
                                                        <div class="row justify-content-center">
                                                                <!-- item start -->
                                                                <div *ngFor="let item of one"  id="icon_play" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                        <figure><img [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" [src]="watchService.getImg(item)" class="img_br">
                                                                                <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                        </figure>
                                                                        <h5 class="text-center" [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }">{{(this.helper.getStringLength(item.title)>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                        <p class="text-center" [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }"> {{(item.VideoInformation.length>80)? (item.VideoInformation | slice:0:80)+'..':(item.VideoInformation)}} </p>
                                                                        <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                                <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'PLAY'| translate}}</a>
                                                                            <div class="rating social" *ngIf="isLoggedIn">
                                                                                            <label (click)="switchWatchFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                            </div>
                                                            </div>

                                                                </div>
                                                                <!-- item end -->
                                                        </div>
                                                </div>
                                            </owl-carousel>
                                            <div class="col-md-12 text-center mt-4"> <a [routerLink]="['watch']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                    </div>
                                    <!-- watch slide end -->
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">
                                                                                                  
                                        <owl-carousel #owlListen [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_listen">
                                                <div class="container" *ngFor="let one of featured_listen">
                                                                <div class="row justify-content-center">
                                                                                <div id="icon_play" *ngFor="let item of one"   class="show_hand bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                                                  <figure><img  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" [src]="listenService.getImg(item)" class="img_br">
                                                                                                  <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                                                  </figure>
                                                                                  <h5  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}} </h5>  
                                                                                  <p  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class="text-center">{{item.information|slice: 0: 80}}</p>
                                                                                  <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                  <a [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{'LISTEN'| translate}}</a>
                                                                                                  <div class="rating social" *ngIf="isLoggedIn">
                                                                                                    <label (click)="switchListenFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                    </div>
                                                                                  </div>
                                                                  </div>
                                                   <!-- item end -->
                                                                </div>
                                                </div>
                                                </owl-carousel>

                                                <div class="col-md-12 text-center mt-4"> <a [routerLink]="['listen']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                    </div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">

                                        <owl-carousel #owlRead [carouselClasses]="['owl-theme','watch_gallery', 'sliding']" [options]="{items: 1, dots: true, nav: true}" [items]="featured_read">
                                                <div class="container" *ngFor="let one of featured_read">
                                                                <div class="row justify-content-center">
                                                                                <!-- item start -->
                                                                                <div id="icon_play" *ngFor="let item of one" class="show_hand bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                                                <figure> <img  [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" [src]="readService.getImg(item)" class="img_br"> </figure>
                                                                                                <h5  [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class="text-center">{{(item.title.length>36)? (item.title | slice:0:36)+'..':(item.title)}}</h5>
                                                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                                                         <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{'READ'| translate}}</a>
                                                                                                         <div class="rating social" *ngIf="isLoggedIn">
                                                                                                                <label (click)="switchReadFav(item)" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                                                </div>
                                                                                                </div>
                                                                                </div>
                                                                                <!-- item end -->
                                                                </div>
                                                </div>
                                        </owl-carousel>
                                        <div class="col-md-12 text-center mt-4"> <a [routerLink]="['read']" class="btn_lg_3">{{"VIEW_ALL"|translate}} </a> </div>
                                    </div>
                            </div>
                    </div>
            </div>
    </div>
</section>