<section id="bg_design" class="container-fluid slide8 comman_slide fan-messages-main">
    <div class="bg_number"> 
        <div class="container">
       <div class="row justify-content-center">
         <div class="col-md-12">
           <h1 class="text-center blue">{{"FanMessages"|translate}}</h1>
           <div class="divder"> <img src="assets/img/icon/line.svg"> </div>
           <!-- <div class="fan owl-carousel owl-theme owl-loaded owl-drag text-center"> -->
            <div class="fan owl-carousel owl-theme owl-loaded owl-drag text-center">
             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
               <h5>{{"Honorable Vice President of India"|translate}}</h5>
               <div class="text_box text-left">मुझे विश्वास है कि 'विश्व रंग २०२०', विश्व को रंगमयी बनाने के साथ-साथ संपूर्ण विश्व में हिंदी भाषा व अन्य भारतीय भाषाओं को बढ़ावा देने का कार्य भी करेगा। </div>
               <div class="border-bottom mt-4 mb-4"></div>
               <p>November, 2020</p>
             </div>
             <!-- item end -->

             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Defence Minister"|translate}}</h5>
              <div class="text_box text-left">यह प्रशंसनीय है कि विश्वरंग इस बार डिजिटल मंचो पर हो रहा है जोकि कोरोना संकट के दौर में एक विवेक सम्मत निर्णय है।</div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p>November, 2020</p>
            </div>
            <!-- item end -->

             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Shri Prakash Javadekar"|translate}}</h5>
              <div class="text_box text-left"> रबींद्रनाथ टैगोर विश्वविद्यालय, भोपाल के इस प्रयास की मैं सराहना करता हूँ और आशा करता हूँ कि वह अपने उद्द्येश्य में सफल होंगे।
              </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->  

             

             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Lindmila Khorhlova (Russia)"|translate}} </h5>
              <div class="text_box text-left">
                <iframe width="100%" height="225" src="https://www.youtube.com/embed/sMS9CDXFL08" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->  
            
             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Lebo Mashile (Africa)"|translate}}</h5>
              <div class="text_box text-left">
                <iframe width="100%" height="225" src="https://www.youtube.com/embed/qwjYGdKnKO4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->  
            
             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Samida David (Romania)"|translate}}</h5>
              <div class="text_box text-left"> 

                <iframe width="100%" height="225" src="https://www.youtube.com/embed/vcQOnGcYBlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->  

             <!-- item start -->
             <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Ganady Shlomper (Israel)"|translate}}</h5>
              <div class="text_box text-left"> <iframe width="100%" height="225" src="https://www.youtube.com/embed/9wSQltbudJk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->     
            
            <!-- item start -->
            <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
              <h5>{{"Leeladhar Mandloi (Delhi)"|translate}}</h5>
              <div class="text_box text-left">
                <iframe width="100%" height="225" src="https://www.youtube.com/embed/CBTDn264nNU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="border-bottom mt-4 mb-4"></div>
              <p></p>
            </div>
            <!-- item end -->  

                <!-- item start -->
                <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
                  <h5>{{"Prabhu Joshi (India)"|translate}}</h5>
                  <div class="text_box text-left">
                    <iframe width="100%" height="225" src="https://www.youtube.com/embed/3Nr73maVDlU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="border-bottom mt-4 mb-4"></div>
                  <p></p>
                </div>
                <!-- item end --> 


                
                <!-- item start -->
                <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
                  <h5>{{"Devilal Patidar (India)"|translate}}</h5>
                  <div class="text_box text-left">
                    <iframe width="100%" height="225" src="https://www.youtube.com/embed/K0tQsfN6t4k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="border-bottom mt-4 mb-4"></div>
                  <p></p>
                </div>
                <!-- item end --> 
                
                
                <!-- item start -->
                <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
                  <h5>{{"Priti Tamot (India)"|translate}}</h5>
                  <div class="text_box text-left">
                    <iframe width="100%" height="225" src="https://www.youtube.com/embed/Eh-UOJ8PKCo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="border-bottom mt-4 mb-4"></div>
                  <p></p>
                </div>
                <!-- item end --> 
                
                <!-- item start -->
                <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
                  <h5>{{"Veena Singh (India)"|translate}}</h5>
                  <div class="text_box text-left">
                    <iframe width="100%" height="225" src="https://www.youtube.com/embed/cH0CnKY8q30" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="border-bottom mt-4 mb-4"></div>
                  <p></p>
                </div>
                <!-- item end --> 
                
                <!-- item start -->
                <div class="bg-white lg_br padding_2 box_shadow margin_1 fan_item">
                  <h5>{{"Neeraj Ahirvar (India)"|translate}}</h5>
                  <div class="text_box text-left">
                    <iframe width="100%" height="225" src="https://www.youtube.com/embed/jSFGQZx5L6w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="border-bottom mt-4 mb-4"></div>
                  <p></p>
                </div>
                <!-- item end --> 
            </div>
           <!-- </div> -->
                </div>
               </div>
             </div>
           </div>
   </section>