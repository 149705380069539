<nav aria-label="breadcrumb" id="breadcrumb">
    <div class="col-md-12">
            <div class="row">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']"> &lt; {{'BacktoHome'|translate}}</a></li>
                        <li class="breadcrumb-item">{{'Youarein'|translate}}:</li>
                    </ol>
                    <li class="breadcrumb-item active" aria-current="page">&gt; {{"User_Profile"|translate}}</li>
            </div>
    </div>
</nav>
<section id="bg_design" class=" pt-5 pb-5">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-7 d-flex m-auto align-items-center">
                            <div class="col-md-6"> 
                                <label style="top: -10px;
                                position: absolute;
                                right: 25px;" class="image-upload-container btn btn-bwm">
                                        <span>
                                                <i class="fa-2x fa fa-pen-square"></i>
                                        </span>
                                        <input style="display: none;"
                                               #imageInput
                                               type="file"
                                               accept="image/*"
                                               (change)="processFile(imageInput)">
                                      </label>
                                      
                               <img  *ngIf="user.image == null" src="assets/user.jpg">
                               <img *ngIf="user.image !== null" style="width: 200px;border-radius: 50%;margin: 1px;" [src]="getDP(user.image)">
                        </div>
                            <div class="col-md-6 text-left">
                                    <h1 class="blue mb-0"> {{user.first_name}} {{user.last_name}}</h1>
                                    <a href="#" class="blue">{{user.email}}</a>
                                    <p class="blue mb-4">{{user.phone}}</p>
                                    <a href="#" class="btn_lg_2" (click)="Logout()"> Log Out </a> </div>
                    </div>
            </div>
    </div>
</section>
<!-- Listen1 start -->
<section id="bg_design_2" class="container-fluid slide2 white_slide">
    <div class="container">
            <div class="row text-center">
                    <div class="col-md-12">
                            <h1 class="text-center white">{{"Favourites"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_fc.svg"> </div>
                            <ul class="nav nav-tabs mb-4" id="comman_tab" role="tablist">
                                    <li class="nav-item" role="presentation"> <a class="nav-link white active" id="watch-tab" data-toggle="tab" href="#watch" role="tab" aria-controls="watch" (click)="getFavContent('watch')" aria-selected="false">{{"WATCH"|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="listen-tab" data-toggle="tab" href="#listen" role="tab" aria-controls="listen"  (click)="getFavContent('listen')" aria-selected="false">{{"LISTEN"|translate}}</a> </li>
                                    <li class="nav-item" role="presentation"> <a class="nav-link white" id="read-tab" data-toggle="tab" href="#read" role="tab" aria-controls="read"  (click)="getFavContent('read')" aria-selected="false">{{"READ"|translate}}</a> </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                            <div class="container">
                                                    <div class="row justify-content-center">
                                                            
                                                            <!-- item start -->
                                                            <div id="icon_play" *ngFor="let item of fav_watch_list" class="bg-white lg_br padding_1 box_shadow margin_1 watch_item">
                                                                <figure><img [src]="getWatchImg(item)" class="img_br">
                                                                        <div class="play"> <img src="assets/img/icon/play.svg"> </div>
                                                                </figure>
                                                                <h5> {{item.title}}</h5>
                                                                <p>{{item.VideoInformation}}</p>
                                                                <div class="col-auto d-flex justify-content-center align-items-center"> 
                                                                        <a [routerLink]="['/watch-content']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"Play"|translate}}</a>
                                                                    <div class="rating social">
                                                                                    <label (click)="switchFav(item,'watch')" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                    </div>
                                                                    </div>
                                                            </div>
                                                            <!-- item end -->
                                                        
                                                    </div>
                                            </div>
                                            <!-- watch slide start-->
                                            <!-- <div class="tab-pane fade show active" id="watch" role="tabpanel" aria-labelledby="watch-tab">
                                                    <div class="col-md-12 mt-4 text-center"><a href="#" class="btn_lg_3">View All </a></div>
                                            </div> -->
                                            <!-- watch slide end -->
                                    </div>
                                    <div class="tab-pane fade" id="listen" role="tabpanel" aria-labelledby="listen-tab">
                                        <div class="container">
                                                <div class="row justify-content-center">
                                                    <div id="icon_play" *ngFor="let item of fav_listen_list"  class="bg-white lg_br padding_1 box_shadow margin_1 listen_item">
                                                            <figure><img [src]="getListenImg(item)" class="img_br">
                                                                            <div class="play"> <img src="assets/img/icon/listen2.svg"> </div>
                                                            </figure>
                                                            <h5>{{item.title}} </h5>
                                                            <p>{{item.information|slice: 0: 30}}</p>
                                                            <div class="col-auto d-flex justify-content-center align-items-center">
                                                                            <a  [routerLink]="['/listen/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1 ">{{"LISTEN"|translate}}</a>
                                                                            <div class="rating social">
                                                                              <label (click)="switchFav(item,'listen')" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                              </div>
                                                            </div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="read" role="tabpanel" aria-labelledby="read-tab">
                                        <div class="container">
                                                <div class="row justify-content-center">
                                                        <div id="icon_play" *ngFor="let item of fav_read_list" class="bg-white lg_br padding_1 box_shadow margin_1 read_item">
                                                                <figure>
                                                                         <img [src]="getReadImg(item)" class="img_br">
                                                                </figure>
                                                                <h5>{{item.title}}</h5>
                                                                <div class="col-auto d-flex justify-content-center align-items-center">
                                                                         <a [routerLink]="['/read/info']" [queryParams]="{ q: item.slug }" class=" btn_sm_1">{{"READ"|translate}}</a>
                                                                         <div class="rating social">
                                                                                <label (click)="switchFav(item,'read')" for="star2">{{ item.like == null ? "☆" : "★" }}</label>
                                                                </div>
                                                                </div>
                                                </div>
                                                </div>
                                        </div>
                                    </div>
                            </div>
                    </div>
            </div>
    </div>
</section>
<!--Listen1 End -->

<section id="bg_design" class="make">
    <div class="container">
            <div class="row">
                    <div class="col-md-12 text-center">
                            <h1 class="text-center blue"><span class="dark_grey"></span> {{"Your_Interest"|translate}}</h1>
                            <div class="divder"> <img src="assets/img/icon/line_categories.svg"> </div>
                            <h2 class="blue mb-4">{{"Choose_from_a_wide_selection_of_categories_hand_picked_for_you"|translate}} </h2>
                    </div>
                    <div class="col-md-11 m-auto text-center"> 
                        <div class="items-collection justify-content-center">
                        <div class="items col-md-3 col-4" *ngFor="let interest of user.interests">
                                <div class="info-block block-info clearfix">
                                    <div data-toggle="buttons" class="btn-group bizmoduleselect">
                                        <label class="btn btn-default active">
                                            <div class="itemcontent">
                                                 <p class="p__">{{interest.name}}</p>
                                            </div> 
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>

                        <button [routerLink]="['/modify-interest']" style="background-color: #fff;
                        outline: none;
                        border: 0;
                        min-height: 2.6em;
                        border-radius: 50em;
                        z-index: +99999;
                        position: relative;
                        padding: 0 1em;
                        box-shadow: 1px 0px 6px 6px rgb(0 0 0 / 19%);
                        color: #006490;
                        font-weight: 800;" class="box_shadow">{{"Modify_Your_Interest"|translate}}</button>
                            </div>
            </div>
    </div>
</section>

<a id="back-to-top" href="#" class="btn btn-light btn-lg back-to-top" role="button"><i class="fa fa-angle-up"></i></a>


<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title blue" id="exampleModalLabel">Announcement 1</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Test 1
  </div>
 </div>
</div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title blue" id="exampleModalLabel">Announcement 3</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Test 2
  </div>
 </div>
</div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title blue" id="exampleModalLabel">Announcement 3</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Test 3
  </div>
 </div>
</div>
</div>
