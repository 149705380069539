import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { LoginService } from 'src/app/services/event/login.service';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TokenService } from 'src/app/shared/token.service';
import { environment } from 'src/environments/environment';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  selectedFile?: ImageSnippet;

  user:any = [];
  fav_content_list:any = [];
  fav_watch_list:any = [];
  fav_listen_list:any = [];
  fav_read_list:any = [];

  constructor(private apiService:ApiService,private socialAuthService:SocialAuthService, private auth:AuthService,private login:LoginService,private tokenService:TokenService,private router:Router) { }

  ngOnInit(): void {
    this.getFavContent('watch');
    this.auth.profileUser().subscribe(
      result => {
        this.user = result;
        localStorage.setItem('user_id',result.id);
      if(result.status== false){   
         this.tokenService.removeToken();
      }else{
        if(Object.keys(result).length){
          if(result.interests == null){
             this.router.navigateByUrl('/register-step-2');
          }}}},
      error=>{})
  }

  getFavContent(type:string){
    this.apiService.getData('/fav_content/'+type).subscribe(
      result => {
        if(type == 'watch'){  this.fav_watch_list = result; }
        
        if(type == 'listen'){  this.fav_listen_list = result; }
        
        if(type == 'read'){  this.fav_read_list = result; }
        
       
      },
      error => {
      },
      () => {
      }
    )
  }

  getWatchImg(watch:any){
    try{
      return this.apiService.storage+'/project/images/watch/' + watch.thumb.project_id+'/'+watch.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  getDP(url:string){
    try{if(url.startsWith("http")){
      return url;
}
else{
 return this.apiService.storage+"/"+url;
}}
catch(e){}
return this.apiService.storage+"/"+url;
  }

  loadProfileData(){
    this.auth.profileUser().subscribe(
      result => {
        this.user = result;
        localStorage.setItem('user_id',result.id);
      if(result.status== false){   
         this.tokenService.removeToken();
      }else{
        if(Object.keys(result).length){
          if(result.interests == null){
             this.router.navigateByUrl('/register-step-2');
          }}}},
      error=>{})
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.apiService.uploadImage('/upload-profile-photo',this.selectedFile.file).subscribe(
        (res:any) => {
          location.reload();
          this.loadProfileData();        
        },
        (err:any) => {
        
        })
    });

    reader.readAsDataURL(file);
  }

  getReadImg(read:any){
    try{
      return this.apiService.storage+'/project/images/read/' + read.thumb.project_id+'/'+read.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

 getListenImg(listen:any){
    try{
      return this.apiService.storage+'/project/images/listen/' + listen.thumb.project_id+'/'+listen.thumb.path;
    }
    catch(e){
        return this.apiService.placeholder;
    }
  }

  switchFav(item:any,type:string){
    
    this.apiService.switchFav(type,item.id).subscribe(
      result => {
        item.like = result;
      },
      error => {
      },
      () => {
      }
    )
  }

  Logout(){
    this.tokenService.removeToken();
    this.login.afterLogin.emit("LoggedOut");
    this.socialAuthService.signOut();
    this.router.navigate(['/']);
  }

}
