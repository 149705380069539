import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/shared/token.service';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-partner-country',
  templateUrl: './partner-country.component.html',
  styleUrls: ['./partner-country.component.css']
})
export class PartnerCountryComponent implements OnInit {

  country = 0;
  team:any = [];
  main_member:any = {};
  country_name = "";
  default_member:number = 0;
  api_Data:any = [];
  watch_read_listen_view_type: string = 'team';
  logged_in:boolean = false;
  

  constructor(private activatedRoute:ActivatedRoute,private tokenService:TokenService,private teamService:TeamService,private router:Router){
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.country_name = params['q'];
      this.country = params['k'];
      this.default_member = params['default_member'];
      }); 
   }

  ngOnInit():void{
    if(this.tokenService.isLoggedIn()){
      this.logged_in = true;
    }
    if(this.country == undefined){
      this.router.navigate(['/about-us']);
    }

    this.teamService.getCountry(this.country).then((data:any)=>{
      this.api_Data = data;
      this.team = data.team;
      this.main_member = data.director;
      this.team.forEach((element:any) => {
        if(element.id == this.default_member){
          this.main_member = element;
        }
      });
    })

    
   }

   profile_pic(member:any){
    return this.teamService.getTeamDP(member);
   }

   flag(member:any){
    return this.teamService.getFlag(member);
   }

   change_member(member:any){
    this.main_member = member;
    window.scrollTo(0,100);
   }

}